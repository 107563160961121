import { paasUrl } from "#http_requests/axios";
import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
export const checkUpdates = (param)=>{
    let { appID } = param;
    return makeAxios({
        libConfig: {
            url: `${hostServer}/api/v1/${paasUrl}/apps/${appID}/available_upgrades/`,
            method: "GET"
        }
    });
};
export const rollbackVersion = (param)=>{
    let { appID, upgradeInfo } = param;
    return makeAxios({
        libConfig: {
            url: `${hostServer}/api/v1/${paasUrl}/apps/${appID}/rollback_version/`,
            method: "POST",
            data: upgradeInfo
        }
    });
};
export const upgradeVersion = (param)=>{
    let { appID, upgradeInfo } = param;
    return makeAxios({
        libConfig: {
            url: `${hostServer}/api/v1/${paasUrl}/apps/${appID}/upgrade_version/`,
            method: "POST",
            data: upgradeInfo
        }
    });
};
