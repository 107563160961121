import tolgee from "#i18n/config";
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInSeconds } from "date-fns";
import { persianNumber } from "./stringFormat";
export const passedTimeSinceNow = (time)=>{
    const diffInSec = differenceInSeconds(new Date(), new Date(time));
    const diffInMin = differenceInMinutes(new Date(), new Date(time));
    const diffInHour = differenceInHours(new Date(), new Date(time));
    const diffInDay = differenceInDays(new Date(), new Date(time));
    const diffInMonth = differenceInMonths(new Date(), new Date(time));
    if (diffInMin === 0) {
        return tolgee.t({
            ns: "common",
            key: "label.seconds_ago",
            params: {
                secondsAgo: persianNumber(diffInSec)
            }
        });
    } else if (diffInHour === 0) {
        return tolgee.t({
            ns: "common",
            key: "label.minutes_ago",
            params: {
                minutesAgo: persianNumber(diffInMin)
            }
        });
    } else if (diffInDay === 0) {
        return tolgee.t({
            ns: "common",
            key: "label.hours_ago",
            params: {
                hoursAgo: persianNumber(diffInHour)
            }
        });
    } else if (diffInMonth === 0) {
        return tolgee.t({
            ns: "common",
            key: "label.days_ago",
            params: {
                daysAgo: persianNumber(diffInDay)
            }
        });
    } else {
        return tolgee.t({
            ns: "common",
            key: "label.months_ago",
            params: {
                monthsAgo: persianNumber(diffInMonth)
            }
        });
    }
};
