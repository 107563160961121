import queryString from "query-string";
import { isValueHollow } from "./validator";
import cookie from "js-cookie";
// Returns Token and TokenType from localStorage or query string
export const getTokenType = function() {
    let ssrCtx = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    //?Use middleware on serverside and js-cookie in csr
    let tokenType = "Token";
    let user;
    let queries;
    let apiKey;
    const { req, query } = ssrCtx ?? {};
    try {
        if (req === null || req === void 0 ? void 0 : req.cookies) {
            var _req_cookies, _req_cookies1;
            user = JSON.parse(((_req_cookies = req.cookies) === null || _req_cookies === void 0 ? void 0 : _req_cookies["user"]) || null);
            apiKey = JSON.parse(((_req_cookies1 = req.cookies) === null || _req_cookies1 === void 0 ? void 0 : _req_cookies1["api-imp-key"]) || null);
            queries = query;
        } else if (typeof window !== "undefined") {
            user = JSON.parse(cookie.get("user") || null);
            apiKey = JSON.parse(cookie.get("api-imp-key") || null);
            queries = queryString.parse(window.location.search);
        }
    } catch (error) {
        console.error(error);
    }
    if (apiKey) {
        tokenType = "Api-key";
    } else if (user) {
        tokenType = user && user.tokenType ? user.tokenType : "Token";
    } else if (queries) {
        tokenType = queries && queries.tokenType ? queries.tokenType : "Token";
    }
    return tokenType;
};
export const getToken = function() {
    let checkUrl = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true, ssrCtx = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, userOnly = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    //?Use js-cookie in csr
    let token = null;
    let user;
    let queries;
    let impToken;
    let apiToken;
    let type = "user";
    const { req, query } = ssrCtx ?? {};
    try {
        if (req === null || req === void 0 ? void 0 : req.cookies) {
            var _req_cookies, _req_cookies1, _req_cookies2;
            impToken = JSON.parse(((_req_cookies = req.cookies) === null || _req_cookies === void 0 ? void 0 : _req_cookies["ham-imp-token"]) || null);
            apiToken = JSON.parse(((_req_cookies1 = req.cookies) === null || _req_cookies1 === void 0 ? void 0 : _req_cookies1["api-imp-key"]) || null);
            user = JSON.parse(((_req_cookies2 = req.cookies) === null || _req_cookies2 === void 0 ? void 0 : _req_cookies2["user"]) || null);
            queries = query;
        } else if (typeof window !== "undefined") {
            impToken = JSON.parse(cookie.get("ham-imp-token") || null);
            apiToken = JSON.parse(cookie.get("api-imp-key") || null);
            user = JSON.parse(cookie.get("user") || null);
            queries = queryString.parse(window.location.search);
        }
        if (!isValueHollow(impToken) && !userOnly) {
            token = impToken.token ? impToken.token : null;
            type = "impersonate";
        } else if (!isValueHollow(apiToken) && !userOnly) {
            token = apiToken.token ? apiToken.token : null;
            type = "impersonate";
        } else if (!isValueHollow(user)) {
            token = user.token ? user.token : null;
        } else if (!isValueHollow(queries) && checkUrl) {
            token = queries.token ? queries.token : null;
        }
    } catch (error) {
        console.error(error);
    }
    return {
        token,
        type
    };
};
// export const getImpToken = () => {
//   let token = null;
//   const impApiKey = JSON.parse(localStorage.getItem("api-imp-key"));
//   const impToken = JSON.parse(localStorage.getItem("ham-imp-token"));
//   const queries = queryString.parse(window.location.search);
//   if (!isValueHollow(imp)) token = imp.token ? imp.token : null;
//   else if (!isValueHollow(queries))
//     token = queries.token ? queries.token : null;
//   return token;
// };
export const getCookieValue = (name)=>{
    var _document_cookie_match;
    return ((_document_cookie_match = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")) === null || _document_cookie_match === void 0 ? void 0 : _document_cookie_match.pop()) || "";
};
