import { moveSentinelData, setSelectedImageOptionInConfig, setTemporaryFieldsInConfig } from "#consts/customConfigFormat";
import { APP_CATEGORY_TEMPLATE, APP_TYPE_POSTGRES, APP_TYPE_REDIS, APP_TYPE_REDIS_DBAAS, APP_TYPE_STOLON, getAppTypeIcon, paas_API_APP_BUILDER_DOCKER, paas_API_APP_CPU_LIMIT, paas_API_APP_DISKS, paas_API_APP_RAM_LIMIT, paas_API_PLAN_CATEGORY_REDIS_NEW, paas_API_PLAN_CATEGORY_STOLON } from "#consts/paas";
import Brands from "#i18n/BrandAssets";
import { appInfo_imageTag_noEdit, appInfo_MainSVCExternalAddress, appInfo_MainSVCInternalAddress, appInfo_postgres_aio_command, appInfo_postgres_aio_password, appInfo_postgres_new_dbname, appInfo_redis_new_password, appInfo_StolonPassword, appInfo_StolonUsername, appInfo_stolon_service_name, appInfo_stolon_standby_service_name, appInfo_SVCExternalAddress, appInfo_SVCExternalIP } from "./editApp/appInfoCards/appInfoCardConfig";
import { step_finalizeAppDbaas, step_simpleOptions } from "../paas/createApp/createSteps/createStepConfigs";
const dbaasAppCreateSteps = [
    step_simpleOptions,
    step_finalizeAppDbaas
];
export const getRedisDbaas = ()=>({
        displayName: "Redis",
        displayIcon: getAppTypeIcon(APP_TYPE_REDIS),
        keyName: APP_TYPE_REDIS_DBAAS,
        builder: paas_API_APP_BUILDER_DOCKER,
        type: APP_TYPE_REDIS_DBAAS,
        docUrl: Brands.docs.redis_app,
        canSetDockerFile: false,
        canSetEnvs: false,
        canSetDisk: false,
        haveHostAddress: false,
        canSetReplicas: false,
        canSetResource: true,
        hasSimpleOptions: true,
        createSteps: dbaasAppCreateSteps,
        DBaaSEnabled: true,
        imageList: [
            "redis"
        ],
        hidden: true,
        plansProps: {
            category: paas_API_PLAN_CATEGORY_REDIS_NEW,
            addDynamicPlan: true
        },
        resourceProps: {
            haveMultipleResource: true,
            persistenceInConfig: true,
            resourceLimits: {
                [paas_API_APP_CPU_LIMIT]: {
                    images: {
                        postgresql: {
                            def: 250
                        }
                    },
                    def: 250
                },
                [paas_API_APP_RAM_LIMIT]: {
                    images: {
                        postgresql: {
                            def: 500
                        }
                    },
                    def: 500
                },
                [paas_API_APP_DISKS]: {
                    def: 0,
                    min: 0,
                    allowZero: true,
                    allowDelete: true
                }
            }
        },
        appCategory: APP_CATEGORY_TEMPLATE,
        editTabs: [
            "general",
            "resource_usage",
            "resource_management",
            "logs",
            "terminal",
            "permissions",
            "config",
            "log_aggregation",
            "backups"
        ],
        appInfoFields: [
            appInfo_MainSVCInternalAddress,
            appInfo_MainSVCExternalAddress,
            appInfo_redis_new_password,
            appInfo_imageTag_noEdit
        ],
        formatFinalData: [
            setTemporaryFieldsInConfig,
            setSelectedImageOptionInConfig,
            moveSentinelData
        ]
    });
export const getStolonAioAppConfig = ()=>({
        displayName: "PostgreSQL",
        displayIcon: getAppTypeIcon(APP_TYPE_POSTGRES),
        docUrl: Brands.docs.postgres_app,
        keyName: APP_TYPE_STOLON,
        builder: paas_API_APP_BUILDER_DOCKER,
        type: APP_TYPE_STOLON,
        canSetDockerFile: false,
        canSetEnvs: false,
        canSetDisk: false,
        canSetReplicas: false,
        hasSimpleOptions: true,
        DBaaSEnabled: true,
        createSteps: dbaasAppCreateSteps,
        imageList: [
            "keeper"
        ],
        haveHostAddress: false,
        canSetHost: false,
        resourceProps: {
            haveMultipleResource: true,
            persistenceInConfig: true,
            resourceLimits: {
                [paas_API_APP_CPU_LIMIT]: {
                    images: {
                        keeper: {
                            def: 250
                        }
                    },
                    def: 250
                },
                [paas_API_APP_RAM_LIMIT]: {
                    images: {
                        keeper: {
                            def: 500
                        }
                    },
                    def: 500
                },
                [paas_API_APP_DISKS]: {
                    def: 1
                }
            }
        },
        plansProps: {
            category: paas_API_PLAN_CATEGORY_STOLON,
            addDynamicPlan: true
        },
        appCategory: APP_CATEGORY_TEMPLATE,
        charts: {
            generalResources: [
                {
                    title: "CPU Usage",
                    type: "cpu_usage"
                },
                {
                    title: "CPU Throttling",
                    type: "cpu_throttling"
                },
                {
                    title: "RAM Usage",
                    type: "ram_usage"
                },
                {
                    title: "Disk Usage",
                    type: "disk_usage"
                },
                {
                    title: "Network Receive",
                    type: "network_receive"
                },
                {
                    title: "Network Send",
                    type: "network_send"
                }
            ],
            dbaasResources: [
                {
                    title: "Active Connections",
                    type: "database_number_of_active_connections"
                },
                {
                    title: "Replication Lag",
                    type: "database_replication_lag"
                },
                {
                    title: "Throughput",
                    type: "database_throughput"
                },
                {
                    title: "Cache Hit Rate",
                    type: "database_cache_hit_rate"
                }
            ]
        },
        editTabs: [
            "general",
            "resources",
            "resource_management",
            "logs",
            "terminal",
            "permissions",
            "config",
            "log_aggregation",
            "backups",
            "data_source",
            "performance_analyzer",
            "resource_usage_app",
            "resource_usage_dbaas"
        ],
        appInfoFields: [
            appInfo_stolon_service_name,
            appInfo_stolon_standby_service_name,
            appInfo_SVCExternalAddress,
            appInfo_SVCExternalIP,
            appInfo_StolonUsername,
            appInfo_StolonPassword,
            appInfo_postgres_new_dbname,
            appInfo_postgres_aio_password,
            appInfo_postgres_aio_command
        ],
        formatFinalData: [
            setTemporaryFieldsInConfig,
            setSelectedImageOptionInConfig
        ]
    });
