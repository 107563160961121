import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const createNamespace = (param)=>{
    let { namespace, clusterId } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/namespaces/`, {
            name: namespace,
            cluster_id: clusterId
        }, config()));
};
export const deleteNamespace = (param)=>{
    let { nsID } = param;
    return makeAxios({
        libConfig: {
            url: `${hostServer}/api/v1/${paasUrl}/namespaces/${nsID}/`,
            method: "DELETE"
        }
    });
};
