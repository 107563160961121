import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const getAlerts = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/viper/alertmanager/alerts/`, config()));
};
export const getAlertRoutes = (appId)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/viper/alert_routes/${appId ? "?source_app=" + appId : ""}`, config()));
};
export const getAlertRules = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/viper/alert_rules/`, config()));
};
export const addAlertRoute = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/viper/alert_routes/`, data, config()));
};
export const deleteAlertRoute = (id)=>{
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/viper/alert_routes/${id}/`, config()));
};
export const getSingleAppAlerts = (id)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/apps/${id}/alerts/`, config()), {
        setNotif: false
    });
};
export const getNotices = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/viper/notices`, config()));
};
