import { createStoreWithMiddlewares } from "../creator";
const useErrorDetailModalStore = createStoreWithMiddlewares("errorDetailModal", (set)=>({
        errorDetailModal: {
            status: false,
            content: ""
        },
        toggleErrorModal: (status, content)=>set(()=>({
                    errorDetailModal: {
                        status,
                        content
                    }
                }))
    }));
export default useErrorDetailModalStore;
