import useFetchInProgressStore from "../fetchInProg";
import * as R from "ramda";
import cookie from "js-cookie";
import { userReqs } from "#http_requests/index.reqs";
import { selectLanguage } from "#root/i18n/langSelector";
import * as Sentry from "@sentry/react";
import { isValueHollow } from "#helpers/validator";
import moment from "moment";
import tolgee from "#root/i18n/config";
import { redirectLoginUser } from "#helpers/router";
import { startGetRoles } from "./permissions";
import { englishNumber } from "#helpers/stringFormat";
import { sendRequest } from "#http_requests/reqProxy/reqProxy";
import { getToken } from "#helpers/getTokenCredentials";
import useAntStore from "../ant";
import { getBrand } from "#root/i18n/BrandMethods";
import { appRouter } from "#/src/Router";
export const startLogin = async (props, storeSetter)=>{
    const { user = null, pass = null, token = null, redirect = false, isImpersonate = false, tokenType = "Token", orgName = null, captcha = null, callback } = props;
    const json = {
        identity: user,
        password: pass,
        captcha,
        client_time: Date.now()
    };
    const ref = JSON.parse(cookie.get("ref") || null);
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        const header = [];
        let key = null;
        // check token existence for impersonate and if null for oAuth
        if (token) {
            header.headers = {
                Authorization: `${tokenType} ${token}`
            };
            key = token;
        } else {
            const res = await userReqs.login({
                json
            });
            header.headers = {
                Authorization: `Token ${res.data.key}`
            };
            key = res.data.key;
        }
        if (!isImpersonate) {
            cookie.set("user", JSON.stringify({
                token: key,
                tokenType: tokenType
            }));
        }
        const { data } = await userReqs.profile({
            header: header
        });
        selectLanguage(data);
        if (process.env.NODE_ENV && process.env.NODE_ENV !== "development" && getBrand() != "hectora") {
            Sentry.configureScope((scope)=>{
                scope.setTag("user email", data.email);
            });
            if ((data === null || data === void 0 ? void 0 : data.is_verified) && window && window.$crisp && window.$crisp.is && !isImpersonate) {
                window.$crisp.push([
                    "set",
                    "user:email",
                    [
                        data.email
                    ]
                ]);
                if ((cookie.get("crispHelpNotifPoped") === null || cookie.get("crispHelpNotifPoped") === undefined || isValueHollow(cookie.get("crispHelpNotifPoped"))) && (!(data === null || data === void 0 ? void 0 : data.creation_time) || moment().diff(data === null || data === void 0 ? void 0 : data.creation_time, "days") < 10)) {
                    setTimeout(()=>{
                        if (typeof window !== "undefined" && !window.$crisp.is("chat:opened")) {
                            window.$crisp.push([
                                "do",
                                "message:show",
                                [
                                    "text",
                                    tolgee.t({
                                        ns: "common",
                                        key: "message.crisp_help_hint"
                                    })
                                ]
                            ]);
                        }
                        cookie.set("crispHelpNotifPoped", "true");
                    }, 180000);
                }
            }
        }
        const cookieOrg = JSON.parse(cookie.get("ham-selected-org") || null);
        let selectedOrganization = null;
        if (cookieOrg) {
            selectedOrganization = R.find(R.propEq("id", cookieOrg.id), data.organizations ?? {
                name: ""
            });
        }
        //Setting selected org from args
        if (orgName) {
            const foundOrgName = R.find(R.propEq("name", orgName), data.organizations);
            if (foundOrgName) {
                cookie.set("ham-selected-org", JSON.stringify({
                    id: foundOrgName.id
                }));
                selectedOrganization = foundOrgName;
            }
        }
        if (!R.isEmpty(data.organizations)) {
            if (!(selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.id)) {
                selectedOrganization = data.organizations[0];
            }
            if (selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.id) {
                cookie.set("ham-selected-org", JSON.stringify({
                    id: selectedOrganization.id
                }));
            }
        }
        storeSetter({
            ...data,
            uid: key,
            selectedOrganization
        });
        callback === null || callback === void 0 ? void 0 : callback();
        if (ref && data.is_verified && new Date().getTime() <= ref.expire && (ref === null || ref === void 0 ? void 0 : ref.refPath) !== "/logout") {
            cookie.remove("ref");
            appRouter.navigate(ref.refPath);
        } else if (redirect) {
            redirectLoginUser(data, selectedOrganization);
        } else {
            cookie.remove("ref");
        }
    } catch (e) {
        var _e_response, _e_response1;
        console.log(e);
        if ((e === null || e === void 0 ? void 0 : (_e_response = e.response) === null || _e_response === void 0 ? void 0 : _e_response.status) >= 400 && (e === null || e === void 0 ? void 0 : (_e_response1 = e.response) === null || _e_response1 === void 0 ? void 0 : _e_response1.status) < 500) {
            if (isImpersonate) {
                cookie.remove("ham-imp-token");
                cookie.remove("api-imp-key");
            } else {
                cookie.remove("user");
            }
        }
        appRouter.navigate("/login");
    } finally{
        useFetchInProgressStore.getState().changeFetchInProg(false);
    }
};
export const requestToken = async (mobile)=>{
    const { addMessage } = useAntStore.getState();
    const json = {
        mobile
    };
    try {
        await userReqs.requestToken({
            json: json
        });
        addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.verification_sms_sent",
                params: {
                    mobile_number: mobile
                }
            })
        });
        return true;
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
        return false;
    }
};
export const startUpdateOrgMembers = async (members, orgId, successMessage, storeSetter)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        const json = members;
        await userReqs.updateOrgMembers({
            json: json,
            orgId: orgId
        });
        addMessage({
            type: "success",
            content: successMessage || tolgee.t({
                ns: "common",
                key: "message.changes_been_saved"
            })
        });
        await startGetRoles(storeSetter);
        await startGettingProfile(storeSetter);
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    } finally{
        useFetchInProgressStore.getState().changeFetchInProg(false);
    }
};
export const startVerifyPhoneNumber = async (mobile, token, storeSetter)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    const json = {
        mobile: englishNumber(mobile),
        token: englishNumber(token)
    };
    try {
        const { data } = await userReqs.verifyPhoneNumber({
            json
        });
        addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.phone_number_verified"
            })
        });
        startGettingProfile(storeSetter);
        return data;
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    } finally{
        useFetchInProgressStore.getState().changeFetchInProg(false);
    }
};
export const changePassword = async (current_password, new_password, storeSetter, storeGetter)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    let result = false;
    const json = {
        current_password,
        new_password
    };
    let resultData;
    try {
        const { data } = await userReqs.changePassword({
            json: json
        });
        const { new_token } = data;
        cookie.set("user", JSON.stringify({
            token: new_token,
            tokenType: "Token"
        }));
        startLogin({
            ...storeGetter(),
            uid: new_token
        }, storeSetter);
        addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.password_changed_success"
            })
        });
        result = true;
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
    return result ? Promise.resolve(resultData) : Promise.reject();
};
export const startUpdateProfile = async function(json) {
    let sendMessage = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, optimistic = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false, storeSetter = arguments.length > 3 ? arguments[3] : void 0;
    const { addMessage } = useAntStore.getState();
    try {
        optimistic && storeSetter(json);
        await sendRequest({
            reqFunc: userReqs.updateProfile,
            payload: json
        });
        !optimistic && startGettingProfile(storeSetter);
        sendMessage && addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.profile_update_success"
            })
        });
    } catch (e) {
        sendMessage && addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
        return Promise.reject(e);
    }
};
export const startGettingProfile = async (storeSetter)=>{
    try {
        useFetchInProgressStore.getState().changeFetchInProg(true);
        const { data } = await userReqs.getProfile();
        const selectedOrg = JSON.parse(cookie.get("ham-selected-org") || null);
        let selectedOrganization = null;
        if (!R.isEmpty(data.organizations) && selectedOrg) {
            selectedOrganization = R.find(R.propEq("id", selectedOrg.id), data.organizations ?? {
                name: ""
            });
            if (!selectedOrganization) {
                selectedOrganization = data.organizations[0];
            }
            storeSetter({
                ...data,
                selectedOrganization
            });
        } else {
            storeSetter({
                ...data
            });
        }
    } catch (e) {
        console.log(e);
    } finally{
        useFetchInProgressStore.getState().changeFetchInProg(false);
    }
};
export const setSelectedOrganization = (selectedOrganization, storeSetter)=>{
    storeSetter({
        selectedOrganization
    });
};
export const startResendEmail = async ()=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        await userReqs.resendEmail();
        addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.email_sent_again"
            })
        });
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
};
export const startVerifyEmail = async (email, token)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        await userReqs.verifyEmail({
            email: email,
            token: token
        });
        addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.email_been_verified"
            })
        });
        useFetchInProgressStore.getState().changeFetchInProg(false);
        return true;
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
    return false;
};
export const startLogout = async function() {
    let redirect = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true, sendRequest = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false, storeSetter = arguments.length > 2 ? arguments[2] : void 0;
    try {
        var _getToken;
        if (((_getToken = getToken(true, null, false)) === null || _getToken === void 0 ? void 0 : _getToken.type) !== "impersonate") {
            sendRequest && await userReqs.logout();
            redirect && appRouter.navigate("/login");
            cookie.remove("user");
            storeSetter({
                uid: null,
                selectedOrganization: null
            }, true);
        } else {
            cookie.remove("ham-imp-token");
            cookie.remove("api-imp-key");
            storeSetter({
                uid: null,
                selectedOrganization: null
            }, true);
            window.location.reload();
        }
    } catch (error) {
        console.log(error);
    }
};
export const startForgetPass = async (json)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        await userReqs.forgetPass({
            json
        });
        addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.recovery_email_sent"
            })
        });
        appRouter.navigate("/login");
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.no_user_found"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
};
export const startResetPass = async (token, new_password)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    const json = {
        token,
        new_password
    };
    try {
        const res = await userReqs.resetPass({
            json: json
        });
        if (res.data && res.data.result) {
            addMessage({
                type: "success",
                content: tolgee.t({
                    ns: "common",
                    key: "message.password_changed_success"
                })
            });
            appRouter.navigate("/login");
        } else {
            addMessage({
                type: "error",
                content: tolgee.t({
                    ns: "common",
                    key: "message.something_went_wrong"
                })
            });
        }
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
};
export const startRegister = async (param, storeGetter)=>{
    let { email = "", password = "", mobile = "", hasReadTos = false, captcha, successCallback } = param;
    useFetchInProgressStore.getState().changeFetchInProg(true);
    const invitedUser = JSON.parse(cookie.get("invitedUser") || null);
    if (invitedUser) {
        if ((invitedUser === null || invitedUser === void 0 ? void 0 : invitedUser.email) && (invitedUser === null || invitedUser === void 0 ? void 0 : invitedUser.email) !== email) {
            cookie.remove("invitedUser");
        }
    }
    const json = {
        email: email,
        password: password,
        mobile: mobile,
        has_read_tos: hasReadTos,
        captcha: captcha === null || captcha === void 0 ? void 0 : captcha[0]
    };
    try {
        var _storeGetter;
        await userReqs.register({
            json: json
        });
        successCallback === null || successCallback === void 0 ? void 0 : successCallback();
        (_storeGetter = storeGetter()) === null || _storeGetter === void 0 ? void 0 : _storeGetter.startLogin({
            captcha: captcha === null || captcha === void 0 ? void 0 : captcha[1],
            user: email,
            pass: password,
            redirect: true
        });
    } catch (error) {
        useFetchInProgressStore.getState().changeFetchInProg(false);
    }
};
