import en_brand_hectora from "#i18n/static/brand-hectora/en.json";
import en_brand_hamravesh from "#i18n/static/brand-hamravesh/en.json";
import fa_brand_hamravesh from "#i18n/static/brand-hamravesh/fa.json";
import fa_common from "#i18n/static/common/fa.json";
import fa_auth from "#i18n/static/auth/fa.json";
import fa_paas from "#i18n/static/paas/fa.json";
import fa_backingham from "#i18n/static/backingham/fa.json";
import fa_financial from "#i18n/static/financial/fa.json";
import fa_monitoring from "#i18n/static/monitoring/fa.json";
import fa_log from "#i18n/static/log/fa.json";
import fa_sentry from "#i18n/static/sentry/fa.json";
import fa_ticket from "#i18n/static/ticket/fa.json";
import fa_registry from "#i18n/static/registry/fa.json";
import fa_load_balancer from "#i18n/static/load-balancer/fa.json";
import fa_managed_services from "#i18n/static/managed-services/fa.json";
import fa_storage from "#i18n/static/storage/fa.json";
import fa_vcluster from "#i18n/static/vcluster/fa.json";
import fa_dbaas from "#i18n/static/dbaas/fa.json";
import fa_hints from "#i18n/static/hints/fa.json";
import fa_gym from "#i18n/static/gym/fa.json";
import en_common from "#i18n/static/common/en.json";
import en_auth from "#i18n/static/auth/en.json";
import en_paas from "#i18n/static/paas/en.json";
import en_backingham from "#i18n/static/backingham/en.json";
import en_financial from "#i18n/static/financial/en.json";
import en_monitoring from "#i18n/static/monitoring/en.json";
import en_log from "#i18n/static/log/en.json";
import en_sentry from "#i18n/static/sentry/en.json";
import en_ticket from "#i18n/static/ticket/en.json";
import en_registry from "#i18n/static/registry/en.json";
import en_load_balancer from "#i18n/static/load-balancer/en.json";
import en_managed_services from "#i18n/static/managed-services/en.json";
import en_storage from "#i18n/static/storage/en.json";
import en_vcluster from "#i18n/static/vcluster/en.json";
import en_dbaas from "#i18n/static/dbaas/en.json";
import en_hints from "#i18n/static/hints/en.json";
import en_gym from "#i18n/static/gym/en.json";
export default function getStatics() {
    const availableLanguages = process.env.NEXT_PUBLIC_LANGS.split(",");
    let staticData = {};
    if (availableLanguages === null || availableLanguages === void 0 ? void 0 : availableLanguages.includes("fa")) {
        staticData = {
            ...staticData,
            "fa:common": fa_common,
            "fa:auth": fa_auth,
            "fa:paas": fa_paas,
            "fa:backingham": fa_backingham,
            "fa:financial": fa_financial,
            "fa:monitoring": fa_monitoring,
            "fa:log": fa_log,
            "fa:sentry": fa_sentry,
            "fa:ticket": fa_ticket,
            "fa:registry": fa_registry,
            "fa:load-balancer": fa_load_balancer,
            "fa:managed-services": fa_managed_services,
            "fa:storage": fa_storage,
            "fa:vcluster": fa_vcluster,
            "fa:dbaas": fa_dbaas,
            "fa:hints": fa_hints,
            "fa:gym": fa_gym
        };
    }
    if (availableLanguages === null || availableLanguages === void 0 ? void 0 : availableLanguages.includes("en")) {
        staticData = {
            ...staticData,
            "en:common": en_common,
            "en:auth": en_auth,
            "en:paas": en_paas,
            "en:backingham": en_backingham,
            "en:financial": en_financial,
            "en:monitoring": en_monitoring,
            "en:log": en_log,
            "en:sentry": en_sentry,
            "en:ticket": en_ticket,
            "en:registry": en_registry,
            "en:load-balancer": en_load_balancer,
            "en:managed-services": en_managed_services,
            "en:storage": en_storage,
            "en:vcluster": en_vcluster,
            "en:dbaas": en_dbaas,
            "en:hints": en_hints,
            "en:gym": en_gym
        };
    }
    if (process.env.NEXT_PUBLIC_BRAND_NAME === "hectora") {
        staticData[`en:brand-hectora`] = en_brand_hectora;
    } else {
        staticData[`fa:brand-hamravesh`] = fa_brand_hamravesh;
        staticData[`en:brand-hamravesh`] = en_brand_hamravesh;
    }
    return staticData;
}
