import { getPaasUrl } from "#i18n/BrandMethods";
const paasName = getPaasUrl();
export const PAAS_MAIN = "/" + paasName;
export const PAAS_CREATE = `/${paasName}/create`;
export const PAAS_DETAILS = `/${paasName}/app`;
export const DBAAS_MAIN = `/database`;
export const DBAAS_DETAILS = `/database/app`;
export const DBAAS_CREATE = `/database/create`;
export const TICKETING = "/ticketing";
export const FINANCIAL = "/financial";
export const KUBERNETES = "/k8s-management";
export const LOADBALANCER = "/load-balancer";
