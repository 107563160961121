// import * as R from "ramda";
import { isCreUser } from "#commonComps/roleChecker";
import * as R from "ramda";
export const DBaaSPermissions = (props)=>{
    var _props_selectedOrganization, _props_selectedOrganization1, _props_userRoles, _props_selectedOrganization2;
    const showDatabase = (_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : _props_selectedOrganization.show_database;
    const userOrgRules = (_props_userRoles = props.userRoles) === null || _props_userRoles === void 0 ? void 0 : _props_userRoles[(_props_selectedOrganization1 = props.selectedOrganization) === null || _props_selectedOrganization1 === void 0 ? void 0 : _props_selectedOrganization1.name];
    const isSpecificOrg = !!R.find(R.equals((_props_selectedOrganization2 = props.selectedOrganization) === null || _props_selectedOrganization2 === void 0 ? void 0 : _props_selectedOrganization2.name), [
        "hamravesh",
        "nobitex",
        "chapterone"
    ]);
    const permissions = {
        view: isSpecificOrg || isCreUser(userOrgRules, props.selectedOrganization) || showDatabase,
        canChangePassword: isSpecificOrg,
        showNodeSelector: isCreUser(userOrgRules, props.selectedOrganization) || process.env.NEXT_PUBLIC_APP_ENV === "staging",
        showStorageClass: isCreUser(userOrgRules, props.selectedOrganization) || process.env.NEXT_PUBLIC_APP_ENV === "staging"
    };
    return permissions;
};
