import { getFeature } from "#root/i18n/BrandSelector";
import { isAclPowerUser } from "../components//common/roleChecker";
export const logAggregationPermissions = (props, config)=>{
    var _props_selectedOrganization, _props_selectedOrganization1, _props_selectedOrganization2;
    const { userAppPermissions = [] } = config || {};
    const basicPermission = ((_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : _props_selectedOrganization.show_hamartia) && isAclPowerUser(props.userRoles, props.selectedOrganization);
    const permissions = {
        view: basicPermission,
        tabView: (_props_selectedOrganization1 = props.selectedOrganization) === null || _props_selectedOrganization1 === void 0 ? void 0 : _props_selectedOrganization1.show_hamartia,
        basicView: (_props_selectedOrganization2 = props.selectedOrganization) === null || _props_selectedOrganization2 === void 0 ? void 0 : _props_selectedOrganization2.show_hamartia,
        viewDocs: getFeature("docs"),
        // a users who doesn't have monitoring service permission
        // can edit an app settings in paas tab with change permissions
        paasEdit: userAppPermissions && !!userAppPermissions.find((perm)=>perm === "change")
    };
    return permissions;
};
