import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const checkOrgName = (param)=>{
    let { orgName } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/users/check_org_name/?org_name=${orgName}`));
};
export const onboardUser = ()=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/onboard/`, null, config()));
};
export const onboardOrg = (param)=>{
    let { organizationName } = param;
    const json = {
        organization_name: organizationName
    };
    let noOrgConfig = {
        ...config()
    };
    delete noOrgConfig.headers["x-organization"];
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/onboard/`, json, noOrgConfig));
};
export const updateOrgMembers = (param)=>{
    let { json, orgId } = param;
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/organizations/${orgId}/members`, json, config()));
};
export const setACL = (param)=>{
    let { json, orgId } = param;
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/organizations/${orgId}/edit`, json, config()));
};
export const leaveOrg = (orgID)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/organizations/${orgID}/leave`, null, config()));
};
