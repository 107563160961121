import { isAclPowerUser } from "#root/components//common/roleChecker";
import * as R from "ramda";
export const paasPermissions = (props)=>{
    var _props_selectedOrganization_organization_feature_flags_limited_apps_filter, _props_selectedOrganization_organization_feature_flags_limited_apps, _props_selectedOrganization_organization_feature_flags, _props_selectedOrganization, _props_selectedOrganization1, _props_selectedOrganization2, _props_selectedOrganization3;
    const permissions = {
        viewWordpress: ((_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : (_props_selectedOrganization_organization_feature_flags = _props_selectedOrganization.organization_feature_flags) === null || _props_selectedOrganization_organization_feature_flags === void 0 ? void 0 : (_props_selectedOrganization_organization_feature_flags_limited_apps = _props_selectedOrganization_organization_feature_flags.limited_apps) === null || _props_selectedOrganization_organization_feature_flags_limited_apps === void 0 ? void 0 : (_props_selectedOrganization_organization_feature_flags_limited_apps_filter = _props_selectedOrganization_organization_feature_flags_limited_apps.filter((e)=>e == "wordpress")) === null || _props_selectedOrganization_organization_feature_flags_limited_apps_filter === void 0 ? void 0 : _props_selectedOrganization_organization_feature_flags_limited_apps_filter.length) == 0,
        canCreateApp: !((_props_selectedOrganization1 = props.selectedOrganization) === null || _props_selectedOrganization1 === void 0 ? void 0 : _props_selectedOrganization1.is_acl_enabled) || R.pathOr(false, [
            "create_permissions",
            "create_app"
        ], props === null || props === void 0 ? void 0 : props.userPermissions),
        canGetKubeConfig: isAclPowerUser(props.userRoles, props.selectedOrganization),
        viewAlerts: isAclPowerUser(props.userRoles, props.selectedOrganization),
        viewSecurityAlerts: isAclPowerUser(props.userRoles, props.selectedOrganization),
        canCreateNamespace: isAclPowerUser(props.userRoles, props.selectedOrganization),
        deleteNamespace: isAclPowerUser(props.userRoles, props.selectedOrganization),
        showUpgradeImageTab: ((_props_selectedOrganization2 = props.selectedOrganization) === null || _props_selectedOrganization2 === void 0 ? void 0 : _props_selectedOrganization2.name) === "hamravesh" || ((_props_selectedOrganization3 = props.selectedOrganization) === null || _props_selectedOrganization3 === void 0 ? void 0 : _props_selectedOrganization3.name) === "sandbox"
    };
    return permissions;
};
