import tolgee from "#i18n/config";
import * as R from "ramda";
import { isValueHollow } from "./validator";
import { dateFormatter } from "./formatters";
export const mbgbString = function(mb) {
    let gbUnitStr = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : " GB", mbUnitStr = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : " MB";
    mb = mb ? mb : 0;
    if (mb >= 1000) {
        mb = mb / 1000;
        mb = mb.toString().includes(".") ? Number.parseFloat(mb).toFixed(1) : mb;
        mb = mb + gbUnitStr;
    } else {
        mb = mb.toFixed(0) + mbUnitStr;
    }
    return mb;
};
export const mbgbtbString = function(mb) {
    let gbUnitStr = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : " GB", mbUnitStr = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : " MB", tbUnitStr = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : " TB";
    mb = mb ? mb : 0;
    if (mb < 1000) {
        return mb.toFixed(0) + mbUnitStr;
    } else if (mb < 1000000) {
        mb = mb / 1000;
        mb = mb.toFixed(1).toString().includes(".0") ? mb.toFixed(0) : mb.toFixed(1);
        return mb + gbUnitStr;
    } else {
        mb = mb / 1000 / 1000;
        mb = mb.toFixed(2).toString().includes(".00") ? mb.toFixed(0) : mb.toFixed(2);
        return mb + tbUnitStr;
    }
};
export const bytekbmbgbtbString = function(b) {
    let kbUnitStr = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : " KB", gbUnitStr = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : " GB", mbUnitStr = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : " MB", tbUnitStr = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : " TB";
    b = b ? b : 0;
    if (b < 10 ** 6) {
        b = b / 1000;
        return b.toFixed(0) + kbUnitStr;
    } else if (b < 10 ** 9) {
        b = b / 10 ** 6;
        return b.toFixed(0) + mbUnitStr;
    } else if (b < 10 ** 12) {
        b = b / 10 ** 9;
        b = b.toFixed(1).toString().includes(".0") ? b.toFixed(0) : b.toFixed(1);
        return b + gbUnitStr;
    } else {
        b = b / 10 ** 12;
        b = b.toFixed(2).toString().includes(".00") ? b.toFixed(0) : b.toFixed(2);
        return b + tbUnitStr;
    }
};
export const miliToCore = (m, coreUnitStr)=>{
    m = m / 1000;
    if (!R.isNil(coreUnitStr)) {
        return m + coreUnitStr;
    } else {
        return m + (m > 1000 ? " Cores" : " Core");
    }
};
export const byteToGi = (number)=>{
    if (R.isNil(number)) {
        return 0;
    } else {
        return Math.floor(number / (1024 * 1024 * 1024));
    }
};
export const strToMb = (mb, mbUnitStr)=>{
    mb = mb.replace(mbUnitStr, "");
    return isValueHollow(mb) ? "" : parseFloat(mb);
};
export const strToMili = (m, miliUnitStr)=>{
    m = m.replace(miliUnitStr, "");
    return isValueHollow(m) ? "" : parseFloat(m);
};
export const strToGi = (gi, giUnitStr)=>{
    gi = gi.replace(giUnitStr, "");
    return isValueHollow(gi) ? "" : parseFloat(gi);
};
export const addUnitStr = (number, unitStr)=>{
    if (isValueHollow(number)) {
        return 0 + unitStr;
    }
    //Remove any old string units.
    number = isNaN(number.toString()) ? removeUnitStr(number).toString() : number.toString();
    return number + unitStr;
};
export const removeUnitStr = (number)=>{
    if (number === undefined || number === null) {
        return 0;
    }
    number = number.toString();
    return parseInt(number.replace(/\D/g, ""));
};
export const unitNameToPersian = (unitName)=>{
    unitName = isValueHollow(unitName) || unitName === 0 ? "" : unitName === null || unitName === void 0 ? void 0 : unitName.toLowerCase();
    switch(unitName.toLowerCase()){
        case "gigabyte":
            return tolgee.t({
                ns: "common",
                key: "label.gigabyte"
            });
        case "gib":
            return tolgee.t({
                ns: "common",
                key: "label.gigabyte"
            });
        case "gb":
            return tolgee.t({
                ns: "common",
                key: "label.gigabyte"
            });
        case "g":
            return tolgee.t({
                ns: "common",
                key: "label.giga"
            });
        case "megabyte":
            return tolgee.t({
                ns: "common",
                key: "label.megabyte"
            });
        case "m":
            return tolgee.t({
                ns: "common",
                key: "label.mega"
            });
        case "mb":
            return tolgee.t({
                ns: "common",
                key: "label.megabyte"
            });
        case "core":
            return tolgee.t({
                ns: "common",
                key: "label.core"
            });
        default:
            return unitName;
    }
};
export const convertTimestampToDate = function(timestamp) {
    let dateFormat = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "YYYY/MM/DD - HH:mm";
    const date = new Date(+timestamp);
    return dateFormatter(date).format(dateFormat);
};
export const getNumber = (string)=>{
    if (R.isNil(string)) {
        return 0;
    }
    return parseInt(string.toString().replace(/[^\d*?\d*$]/g, "")) || 0;
};
export const getStringNumber = (string)=>{
    //Useful for number inputs that allows empty string
    if (isValueHollow(string)) {
        return null;
    }
    const num = parseInt(string.toString().replace(/[^\d*?\d*$]/g, ""));
    return isNaN(num) ? "" : num;
};
export const getFloat = function() {
    let string = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "0";
    const tempValue = parseInt(string) + 0;
    return parseInt(tempValue.toString().replace(/[^\d.*?\d*$]/g, "")) || 0;
};
