import { userReqs } from "#http_requests/index.reqs";
import tolgee from "#root/i18n/config";
import useFetchInProgressStore from "../fetchInProg";
import cookie from "js-cookie";
import useAntStore from "../ant";
export const startGetPermissions = async (storeSetter)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        const { data } = await userReqs.getPermissions();
        storeSetter({
            permissions: data
        });
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
};
export const startGetRoles = async (storeSetter)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        const selectedOrg = JSON.parse(cookie.get("ham-selected-org") || null);
        const { data } = await userReqs.getUserRoles({
            organizationId: selectedOrg.id
        });
        storeSetter({
            roles: data
        });
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
};
export const startGetUserPermissions = async (userId, storeSetter)=>{
    const { addMessage } = useAntStore.getState();
    try {
        const { data } = await userReqs.getUserPermissions({
            userId: userId
        });
        storeSetter({
            userPermissions: data
        });
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
};
export const startUpdateAppPermissions = async (activeId, json, defultPermissions)=>{
    const { addMessage } = useAntStore.getState();
    const checkAppPermissions = (app, def)=>{
        return app.permissions.length === def.permissions.length && app.permissions.every((v, i)=>v === def.permissions[i]);
    };
    for(const key in json.apps){
        if (Object.hasOwnProperty.call(json.apps, key) && checkAppPermissions(json.apps[key], defultPermissions[key])) {
            delete json.apps[key];
        }
    }
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        await userReqs.updateAppPermissions({
            activeId: activeId,
            json: json
        });
        addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.changes_been_saved"
            })
        });
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
};
export const startSetACL = async (is_acl_enabled, orgId, storeSetter, storeGetter)=>{
    const { addMessage } = useAntStore.getState();
    useFetchInProgressStore.getState().changeFetchInProg(true);
    try {
        var _storeGetter;
        const json = {
            is_acl_enabled,
            description: "test"
        };
        await userReqs.setACL({
            json: json,
            orgId: orgId
        });
        const orgs = (_storeGetter = storeGetter()) === null || _storeGetter === void 0 ? void 0 : _storeGetter.organizations.slice();
        const newOrgs = orgs.map((el)=>{
            if (el.id === orgId) {
                return {
                    ...el,
                    is_acl_enabled: is_acl_enabled
                };
            }
            return el;
        });
        const selectedOrganization = newOrgs.find((el)=>el.id === orgId);
        storeSetter({
            organizations: newOrgs,
            selectedOrganization
        });
        addMessage({
            type: "success",
            content: tolgee.t({
                ns: "common",
                key: "message.changes_been_saved"
            })
        });
    } catch (e) {
        addMessage({
            type: "error",
            content: tolgee.t({
                ns: "common",
                key: "message.something_went_wrong"
            })
        });
    }
    useFetchInProgressStore.getState().changeFetchInProg(false);
};
