import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const checkCustomSubdomain = (param)=>{
    let { subdomian } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/check_subdomain/`, {
            subdomain: subdomian
        }, config()));
};
/**
 * Check hosts DNS
 *
 * @param {Array} hosts
 * @return {Promise}
 */ export const checkHostsDNS = (hosts)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/check_dns_records/`, {
            hosts
        }, config()));
};
export const getCertificates = (appID)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/apps/${appID}/certificate_status/`, config()));
};
