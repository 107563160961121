import { getPaasUrl } from "#i18n/BrandMethods";
import { hasRole, user_API_ORGANIZATION_ROLES_ACCOUNTANT, user_API_ORGANIZATION_ROLES_BILLING_ADMIN } from "#root/consts/auth";
import { FINANCIAL } from "#consts/routes";
import { getToken } from "./getTokenCredentials";
import { appRouter } from "../Router";
export const redirectLoginUser = (user, selectedOrganization)=>{
    var _getToken;
    if ((_getToken = getToken(false, null, false)) === null || _getToken === void 0 ? void 0 : _getToken.token) {
        if (user && selectedOrganization && (hasRole([
            user_API_ORGANIZATION_ROLES_ACCOUNTANT
        ], user === null || user === void 0 ? void 0 : user.organization_roles, selectedOrganization, true) || hasRole([
            user_API_ORGANIZATION_ROLES_BILLING_ADMIN
        ], user === null || user === void 0 ? void 0 : user.organization_roles, selectedOrganization, true))) {
            appRouter.navigate(`${FINANCIAL}/invoices/current`);
        } else {
            appRouter.navigate(`/${getPaasUrl()}`);
        }
    }
};
