const base = "/podolica";
const componentsTab = (id)=>`${base}/${id}/components`;
const maintenancesTab = (id)=>`${base}/${id}/maintenances`;
const infoTab = (id)=>`${base}/${id}/info`;
export const ROUTE_podolica = {
    componentsTab,
    maintenancesTab,
    infoTab,
    base
};
