import { createStoreWithMiddlewares } from "../creator";
const useAntStore = createStoreWithMiddlewares("ant", (set)=>({
        messages: [],
        notifications: [],
        addMessage: (message)=>set((param)=>{
                let { messages } = param;
                return {
                    messages: [
                        ...messages,
                        message
                    ]
                };
            }),
        removeMessages: ()=>set(()=>({
                    messages: []
                })),
        addNotification: (notification)=>set((param)=>{
                let { notifications } = param;
                return {
                    notifications: [
                        ...notifications,
                        notification
                    ]
                };
            }),
        removeNotifications: ()=>set(()=>({
                    notifications: []
                }))
    }));
export default useAntStore;
