import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "./axios";
//OBJECT STORAGE
export const getObjectStorages = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/services/?kind=minio`, config()));
};
//POSTGRESQL
export const getPostgreSQLs = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/services/?kind=postgres`, config()));
};
//REDIS
export const getRedises = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/services/?kind=redis`, config()));
};
//MYSQL
export const getMysqls = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/services/?kind=mysql`, config()));
};
//MONGO
export const getMongos = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/services/?kind=mongo`, config()));
};
