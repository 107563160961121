import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const profile = (param)=>{
    let { header } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/users/profile`, header));
};
export const login = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/login`, json), {
        sendIssue: false,
        setNotif: true
    });
};
export const register = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/register_user/`, json), {
        sendIssue: false,
        setNotif: true
    });
};
export const logout = ()=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/logout`, null, config()));
};
export const forgetPass = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/forget_password/`, json));
};
export const resetPass = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/reset_password/`, json));
};
export const checkUsername = (param)=>{
    let { username } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/users/check_username/?username=${username}`));
};
export const checkEmail = (param)=>{
    let { email } = param;
    // unused
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/users/check_email/?email=${email}`));
};
export const verifyEmail = (param)=>{
    let { token, email } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/verify_email/?token=${token}${email ? `&email=${email}` : ""}`));
};
export const verifyInvitation = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/organizations/join_by_token`, json));
};
export const resendEmail = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/users/resend_verification_email/`, config()));
};
export const requestToken = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/send_verification_sms/`, json, config()));
};
export const verifyPhoneNumber = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/verify_mobile/`, json, config()));
};
export const changePassword = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/users/change_password/`, json, config()));
};
export const updateProfile = (data)=>{
    return makeAxios({
        libConfig: {
            method: "PUT",
            url: `${hostServer}/api/v1/users/profile`,
            data: data
        }
    });
};
export const getProfile = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/users/profile`, config()));
};
export const ssoLogin = (param)=>{
    let { providerName } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/oauth/authorization_request/?oauth_provider=${providerName}`, {
            maxRedirects: 0
        }), {
        sendIssue: true,
        setNotif: true
    });
};
export const ssoAuth = (param)=>{
    let { authParams } = param;
    const bodyFormData = new FormData();
    Object.keys(authParams).forEach((paramKey)=>{
        bodyFormData.append(paramKey, authParams[paramKey]);
    });
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/oauth/auth/`, bodyFormData), {
        sendIssue: true,
        setNotif: true
    });
};
