import * as R from "ramda";
import { isAclPowerUser } from "#commonComps/roleChecker";
export const storagePermissions = (props)=>{
    var _props_selectedOrganization;
    const isSpecificOrg = !!R.find(R.equals((_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : _props_selectedOrganization.name), [
        "hamravesh",
        "mohammadhoseyny",
        "paziresh24-cloud",
        "sanjagh"
    ]);
    const permissions = {
        view: isSpecificOrg && isAclPowerUser(props.userRoles, props.selectedOrganization)
    };
    return permissions;
};
