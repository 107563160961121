import { hostServer } from "#root/i18n/BrandMethods";
import { paasUrl, config, sendRequest, axoisReq as axios } from "../axios";
export const getCertificates = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/certificates/`, config()));
};
export const getCertificatesByCluster = (param)=>{
    let { clusterID } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/certificates/?cluster_id=${clusterID}`, config()));
};
export const getNodesByCluster = (param)=>{
    let { clusterID } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/nodes/?cluster_id=${clusterID}`, config()));
};
export const joinNode = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/nodes/join/`, data, config()));
};
export const addNodePool = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/nodepools/`, data, config()));
};
export const getNodePools = (clusterID)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/nodepools/?cluster_id=${clusterID}`, config()));
};
export const getAllNodePools = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/nodepools/`, config()));
};
export const createAndJoinNode = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/nodes/create_and_join/`, data, config()));
};
export const increaseDiskSize = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/nodes/increase_disk_size/`, data, config()));
};
export const createCluster = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/clusters/`, data, config()));
};
