import dayjs from "dayjs";
import jalaliday from "jalaliday";
import { noteOnce } from "rc-util/lib/warning";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { default as faLocale } from "./locale";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(jalaliday);
dayjs.locale(faLocale, undefined, false);
dayjs.extend((o, c)=>{
    // todo support Wo (ISO week)
    const proto = c.prototype;
    const oldFormat = proto.format;
    proto.format = function f(formatStr) {
        const str = (formatStr || "").replace("Wo", "wo");
        return oldFormat.bind(this)(str);
    };
});
const localeMap = {
    en_GB: "en-gb",
    en_US: "en",
    zh_CN: "zh-cn",
    zh_TW: "zh-tw",
    fa_IR: "fa"
};
const parseLocale = (locale)=>{
    const mapLocale = localeMap[locale];
    return mapLocale || locale.split("_")[0];
};
const parseNoMatchNotice = ()=>{
    /* istanbul ignore next */ noteOnce(false, "Not match any format. Please help to fire a issue about this.");
};
const generateJalaliConfig = {
    // get
    getNow: ()=>dayjs(),
    getFixedDate: (string)=>dayjs(string, "YYYY-MM-DD"),
    getEndDate: (date)=>date.endOf("month"),
    getWeekDay: (date)=>{
        if (!(date === null || date === void 0 ? void 0 : date.weekday())) {
            date = dayjs();
        }
        const clone = date.locale("en");
        return clone.weekday() + clone.localeData().firstDayOfWeek();
    },
    getYear: (date)=>date.year(),
    getMonth: (date)=>date.month(),
    getDate: (date)=>date.date(),
    getHour: (date)=>date.hour(),
    getMinute: (date)=>date.minute(),
    getSecond: (date)=>date.second(),
    getMillisecond: (date)=>date.millisecond(),
    // set
    addYear: (date, diff)=>date.add(diff, "year"),
    addMonth: (date, diff)=>date.add(diff, "month"),
    addDate: (date, diff)=>date.add(diff, "day"),
    setYear: (date, year)=>date.year(year),
    setMonth: (date, month)=>date.month(month),
    setDate: (date, num)=>date.date(num),
    setHour: (date, hour)=>date.hour(hour),
    setMinute: (date, minute)=>date.minute(minute),
    setSecond: (date, second)=>date.second(second),
    setMillisecond: (date, millisecond)=>date.millisecond(millisecond),
    // Compare
    isAfter: (date1, date2)=>date1.isAfter(date2),
    isValidate: (date)=>date.isValid(),
    locale: {
        getWeekFirstDate: (locale, date)=>date.locale(parseLocale(locale)).weekday(0),
        getWeekFirstDay: (locale)=>dayjs().locale(parseLocale(locale)).localeData().firstDayOfWeek(),
        getWeek: (locale, date)=>date.locale(parseLocale(locale)).week(),
        getShortWeekDays: (locale)=>dayjs().locale(parseLocale(locale)).localeData().weekdaysMin(),
        getShortMonths: (locale)=>dayjs().locale(parseLocale(locale)).localeData().monthsShort(),
        format: (locale, date, format)=>date.locale(parseLocale(locale)).format(format),
        parse: (locale, text, formats)=>{
            console.log(locale, text);
            const localeStr = parseLocale(locale);
            for(let i = 0; i < formats.length; i += 1){
                const format = formats[i];
                const formatText = text;
                if (format.includes("wo") || format.includes("Wo")) {
                    // parse Wo
                    const year = formatText.split("-")[0];
                    const weekStr = formatText.split("-")[1];
                    const firstWeek = dayjs(year, "YYYY").startOf("year").locale(localeStr);
                    for(let j = 0; j <= 52; j += 1){
                        const nextWeek = firstWeek.add(j, "week");
                        if (nextWeek.format("Wo") === weekStr) {
                            return nextWeek;
                        }
                    }
                    parseNoMatchNotice();
                    return null;
                }
                const date = dayjs(formatText, format).locale(localeStr);
                if (date.isValid()) {
                    return date;
                }
            }
            parseNoMatchNotice();
            return null;
        }
    }
};
export default generateJalaliConfig;
