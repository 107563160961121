import CountryCodes from "../components/user/CountryCodes.json";
import * as R from "ramda";
import Brands from "../i18n/BrandAssets";
import { isValueHollow } from "#helpers/validator";
import { parsePhoneNumber } from "libphonenumber-js";
import { getCountryCode } from "#root/i18n/BrandSelector";
export const user_API_ORGANIZATIONS = "organizations";
export const user_API_ORGANIZATION_ROLES = "organization_roles";
export const user_API_ORGANIZATION_ROLES_OWNER = "owner";
export const user_API_ORGANIZATION_ROLES_ADMIN = "admin";
export const user_API_ORGANIZATION_ROLES_CRE = "cre";
export const user_API_ORGANIZATION_ROLES_BILLING_ADMIN = "billing_admin";
export const user_API_ORGANIZATION_ROLES_ACCOUNTANT = "accountant";
export const user_API_ORGANIZATION_ROLES_DEVELOPER = "developer";
export const getUserRoles = (roles, activeId)=>{
    var _roles_filter_, _roles_filter;
    return roles ? (_roles_filter = roles.filter((role)=>(role === null || role === void 0 ? void 0 : role.id) === activeId)) === null || _roles_filter === void 0 ? void 0 : (_roles_filter_ = _roles_filter[0]) === null || _roles_filter_ === void 0 ? void 0 : _roles_filter_.roles : [];
};
export const IDENTITY_CONFIG = {
    authority: Brands.docs.api_staging_openid,
    client_id: "242748",
    redirect_uri: Brands.docs.client_uri,
    scope: "profile email",
    response_type: "code"
};
export const addCountryCode = (phoneNumber, code)=>{
    if (phoneNumber) {
        const foundCode = R.find(R.propEq("code", code), CountryCodes);
        if (foundCode) {
            phoneNumber = `${foundCode.dial_code}${phoneNumber}`;
        }
    }
    return phoneNumber;
};
export const hasRole = function(reqRoles, userRoles, selectedOrganization) {
    let isStrict = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
    //isStrict: Exact reqRoles should be equal.
    const userOrgRules = userRoles === null || userRoles === void 0 ? void 0 : userRoles[selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.name];
    if (selectedOrganization && !isValueHollow(userOrgRules)) {
        const commonRoles = userOrgRules.filter((n)=>{
            return reqRoles.indexOf(n) !== -1;
        });
        return isStrict ? commonRoles.length == 1 && userOrgRules.length == reqRoles.length : commonRoles.length != 0;
    }
    return null;
};
export const getMobileCountry = (mobile)=>{
    try {
        var _parsePhoneNumber;
        return (_parsePhoneNumber = parsePhoneNumber(mobile)) === null || _parsePhoneNumber === void 0 ? void 0 : _parsePhoneNumber.country;
    } catch (error) {
        console.error(error);
    }
    return getCountryCode();
};
export const getInitMobile = (mobile)=>{
    var _parsePhoneNumber;
    if (isValueHollow(mobile)) {
        return mobile;
    }
    return (_parsePhoneNumber = parsePhoneNumber(`+${mobile}`)) === null || _parsePhoneNumber === void 0 ? void 0 : _parsePhoneNumber.nationalNumber;
};
export const getNumberWithZero = function(phoneNumber) {
    let countryCode = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "IR";
    if (phoneNumber && countryCode == "IR") {
        const hasZero = R.equals(phoneNumber[0], "0");
        return hasZero ? phoneNumber : "0" + phoneNumber;
    }
    return phoneNumber;
};
