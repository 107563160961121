import { paas_API_APP_CUSTOM_CONFIG, paas_API_APP_CUSTOM_CONFIG_HOSTS, paas_API_APP_CUSTOM_CONFIG_INGRESS, paas_API_APP_ENABLE_SSL, paas_API_APP_EXTERNAL_HOSTS, paas_API_APP_NAMESPACE, paas_API_APP_REDIRECT_SSL } from "./paas";
import * as R from "ramda";
import merge from "deepmerge";
export const getTemplateAppHostsConfig = (data)=>{
    var _Object_keys;
    const newData = data;
    const localExternalHosts = [];
    const externalHosts = R.pathOr(null, [
        paas_API_APP_CUSTOM_CONFIG,
        paas_API_APP_CUSTOM_CONFIG_INGRESS,
        paas_API_APP_CUSTOM_CONFIG_HOSTS
    ], data);
    const redirectSSL = R.pathOr(false, [
        paas_API_APP_CUSTOM_CONFIG,
        paas_API_APP_CUSTOM_CONFIG_INGRESS,
        paas_API_APP_REDIRECT_SSL
    ], data);
    const enableSSL = R.pathOr(true, [
        paas_API_APP_CUSTOM_CONFIG,
        paas_API_APP_CUSTOM_CONFIG_INGRESS,
        paas_API_APP_ENABLE_SSL
    ], data);
    if (externalHosts && ((_Object_keys = Object.keys(externalHosts)) === null || _Object_keys === void 0 ? void 0 : _Object_keys.length)) {
        Object.keys(externalHosts).forEach((host)=>{
            localExternalHosts.push(externalHosts[host].host);
        });
        newData[paas_API_APP_EXTERNAL_HOSTS] = localExternalHosts;
    }
    newData[paas_API_APP_REDIRECT_SSL] = redirectSSL;
    newData[paas_API_APP_ENABLE_SSL] = enableSSL;
    return newData;
};
export const setTemplateAppHostsConfig = (data)=>{
    return {
        ...data,
        [paas_API_APP_CUSTOM_CONFIG]: addSSLToConfig(addHostToConfig(R.pathOr({}, [
            paas_API_APP_CUSTOM_CONFIG
        ], data), R.pathOr(null, [
            paas_API_APP_EXTERNAL_HOSTS
        ], data)), R.pickAll([
            paas_API_APP_REDIRECT_SSL,
            paas_API_APP_ENABLE_SSL
        ], data))
    };
};
export const setTemporaryFieldsInConfig = (data)=>{
    var _newAppData_temporaryFields;
    //!Use this function just for form input fields, not custom components
    const newAppData = {
        ...data
    };
    let toCustomConfig = newAppData === null || newAppData === void 0 ? void 0 : (_newAppData_temporaryFields = newAppData.temporaryFields) === null || _newAppData_temporaryFields === void 0 ? void 0 : _newAppData_temporaryFields.toCustomConfig;
    if (toCustomConfig) {
        toCustomConfig = R.reject(R.isEmpty())(toCustomConfig);
        newAppData[paas_API_APP_CUSTOM_CONFIG] = merge(newAppData[paas_API_APP_CUSTOM_CONFIG], toCustomConfig);
    }
    return newAppData;
};
export const moveSentinelData = (data)=>{
    const newAppData = R.clone(data);
    let customConfig = newAppData === null || newAppData === void 0 ? void 0 : newAppData[paas_API_APP_CUSTOM_CONFIG];
    if (customConfig === null || customConfig === void 0 ? void 0 : customConfig.managed) {
        customConfig = {
            ...customConfig,
            sentinel: {
                enabled: true
            }
        };
    } else {
        customConfig = {
            ...customConfig,
            sentinel: {
                enabled: false
            }
        };
    }
    customConfig === null || customConfig === void 0 ? true : delete customConfig.managed;
    customConfig === null || customConfig === void 0 ? true : delete customConfig.role;
    customConfig === null || customConfig === void 0 ? true : delete customConfig.dataSource;
    newAppData[paas_API_APP_CUSTOM_CONFIG] = customConfig;
    return newAppData;
};
export const setSelectedImageOptionInConfig = (data)=>{
    const newAppData = R.clone(data);
    const toCustomConfig = R.clone(newAppData === null || newAppData === void 0 ? void 0 : newAppData.selectedImageOption);
    toCustomConfig === null || toCustomConfig === void 0 ? true : delete toCustomConfig.display_name;
    if (toCustomConfig) {
        newAppData === null || newAppData === void 0 ? true : delete newAppData.selectedImageOption;
        newAppData[paas_API_APP_CUSTOM_CONFIG] = merge(newAppData[paas_API_APP_CUSTOM_CONFIG], toCustomConfig);
    }
    return newAppData;
};
export const removeNamespace = (data)=>{
    const newAppData = {
        ...data
    };
    delete newAppData[paas_API_APP_NAMESPACE];
    return newAppData;
};
export const addHostToConfig = (config, hosts)=>{
    const customConfigData = config;
    const ingressData = R.pathOr({}, [
        paas_API_APP_CUSTOM_CONFIG_INGRESS
    ], customConfigData);
    const externalHosts = R.pathOr({}, [
        paas_API_APP_CUSTOM_CONFIG_INGRESS,
        paas_API_APP_CUSTOM_CONFIG_HOSTS
    ], customConfigData);
    const newHosts = {};
    if (hosts === null || hosts === void 0 ? void 0 : hosts.length) {
        hosts.forEach((item, index)=>{
            newHosts[`host${index + 1}`] = {
                host: item,
                path: "/"
            };
        });
        return {
            ...customConfigData,
            [paas_API_APP_CUSTOM_CONFIG_INGRESS]: {
                ...ingressData,
                [paas_API_APP_CUSTOM_CONFIG_HOSTS]: {
                    ...externalHosts,
                    ...newHosts
                }
            }
        };
    } else {
        return customConfigData;
    }
};
export const addHostsToCustomConfigIngress = (config)=>{
    let customConfigData = config[paas_API_APP_CUSTOM_CONFIG];
    const hosts = config[paas_API_APP_EXTERNAL_HOSTS];
    const ingressData = R.pathOr({}, [
        paas_API_APP_CUSTOM_CONFIG_INGRESS
    ], customConfigData);
    const newHosts = {};
    if ((hosts === null || hosts === void 0 ? void 0 : hosts.length) >= 0) {
        hosts.forEach((item, index)=>{
            newHosts[`host${index + 1}`] = {
                host: item,
                path: "/"
            };
        });
        customConfigData = {
            ...customConfigData,
            [paas_API_APP_CUSTOM_CONFIG_INGRESS]: {
                ...ingressData,
                [paas_API_APP_CUSTOM_CONFIG_HOSTS]: newHosts
            }
        };
        return {
            ...config,
            [paas_API_APP_CUSTOM_CONFIG]: customConfigData
        };
    } else {
        return config;
    }
};
export const addSSLToConfig = (config, sslOptions)=>{
    let customConfigData = config;
    const ingressData = R.pathOr({}, [
        paas_API_APP_CUSTOM_CONFIG_INGRESS
    ], customConfigData);
    const redirectSSL = R.pathOr(false, [
        paas_API_APP_REDIRECT_SSL
    ], sslOptions);
    const enableSSL = R.pathOr(true, [
        paas_API_APP_ENABLE_SSL
    ], sslOptions);
    customConfigData = {
        ...customConfigData,
        [paas_API_APP_CUSTOM_CONFIG_INGRESS]: {
            ...ingressData
        }
    };
    customConfigData[paas_API_APP_CUSTOM_CONFIG_INGRESS][paas_API_APP_REDIRECT_SSL] = redirectSSL;
    customConfigData[paas_API_APP_CUSTOM_CONFIG_INGRESS][paas_API_APP_ENABLE_SSL] = enableSSL;
    return customConfigData;
};
