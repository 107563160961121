import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getRegistries = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/registry/`, config()));
};
export const getRegistryRepos = (param)=>{
    let { id, offset = 0 } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/registry/${id}/list_repositories/?offset=${offset}&limit=10`, config()));
};
export const getRepoDigests = (param)=>{
    let { registryID, repoName } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/registry/${registryID}/list_repository_digests/`, {
            repository_name: repoName
        }, config()));
};
export const deleteDigest = (param)=>{
    let { registryID, repoName, digests } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/registry/${registryID}/delete_digests/`, {
            repository_name: repoName,
            digests_sha: digests
        }, config()));
};
export const deleteImage = (param)=>{
    let { registryID, repoName } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/registry/${registryID}/delete_repository/`, {
            repository_name: repoName
        }, config()));
};
export const getRegistryDiskUsage = (param)=>{
    let { registryID } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/registry/${registryID}/storage_usage`, config()));
};
export const deleteTags = (param)=>{
    let { registryID, repoName, tags, digestSha } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/registry/${registryID}/delete_tags/`, {
            repository_name: repoName,
            tags: tags,
            digest_sha: digestSha
        }, config()));
};
export const addRegistry = (param)=>{
    let { fields } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/registry/`, fields, config()));
};
export const deleteRegistry = (param)=>{
    let { registry } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/registry/${registry}/`, config()));
};
