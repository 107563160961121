import { getPodolicaApiURL } from "#i18n/BrandMethods";
import { makeAxios } from "#http_requests/reqProxy/libFactory";
export const getClusters = ()=>{
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${getPodolicaApiURL()}/v1/clusters/`
        }
    });
};
export const getCluster = (param)=>{
    let { id } = param;
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${getPodolicaApiURL()}/v1/clusters/${id}/info/`
        }
    });
};
export const getMaintenances = (param)=>{
    let { clusters } = param;
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${getPodolicaApiURL()}/v1/maintenances/${clusters ? `?clusters=${clusters}` : ""}`
        }
    });
};
export const getComponents = (param)=>{
    let { id } = param;
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${getPodolicaApiURL()}/v1/clusters/${id}/components/`
        }
    });
};
