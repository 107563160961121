import * as R from "ramda";
import { isAclPowerUser } from "../components//common/roleChecker";
export const backinghamPermissions = (props)=>{
    var _props_selectedOrganization, _props_selectedOrganization1, _props_selectedOrganization2;
    const permissions = {
        view: isAclPowerUser(props.userRoles, props.selectedOrganization),
        showAppClone: !((_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : _props_selectedOrganization.is_acl_enabled) || R.pathOr(false, [
            "create_permissions",
            "create_app"
        ], props === null || props === void 0 ? void 0 : props.userPermissions),
        showRestoreBackupHistory: true,
        showEditBackup: ((_props_selectedOrganization1 = props.selectedOrganization) === null || _props_selectedOrganization1 === void 0 ? void 0 : _props_selectedOrganization1.show_backup_features) ?? false,
        showCreateBackup: ((_props_selectedOrganization2 = props.selectedOrganization) === null || _props_selectedOrganization2 === void 0 ? void 0 : _props_selectedOrganization2.show_backup_features) ?? false
    };
    return permissions;
};
