import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const getLokiLogs = (param)=>{
    let { appId, start_time, end_time, stream_name } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/logs/loki/`, {
            app_id: appId,
            start: start_time,
            end: end_time,
            stream_name: stream_name
        }, config()));
};
export const getLogAggregationDataSource = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/hamartia/datasources/`, config()));
};
export const getLogAggregationPlans = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/hamartia/plans/`, config()));
};
export const activeLogAggregation = ()=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/hamartia/datasources/`, {}, config()));
};
export const deleteLogAggregation = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/hamartia/datasources/${id}/`, config()));
};
export const getPipelines = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/hamartia/datasources/${id}/pipelines/`, config()));
};
export const createPipeline = (param)=>{
    let { id, data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/hamartia/datasources/${id}/pipelines/`, data, config()));
};
export const deletePipeline = (param)=>{
    let { id, pipeline_id } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/hamartia/datasources/${id}/pipelines/?pipeline_id=${pipeline_id}`, config()));
};
export const getVaultSecret = (param)=>{
    let { datasourceID } = param;
    return makeAxios({
        libConfig: {
            method: "POST",
            url: `${hostServer}/api/v1/hamartia/datasources/${datasourceID}/read_vault_secret/`,
            data: {
                secret_fields: [
                    "vault_password"
                ]
            }
        }
    });
};
