import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const tickets = (param)=>{
    let { limit, offset, filters } = param;
    return sendRequest(()=>axios.get(`${hostServer}/helpdesk/rest-tickets/?limit=${limit}&offset=${offset}${filters}`, config()));
};
export const ticket = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/helpdesk/rest-tickets/${id}/`, config()));
};
export const createTicket = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/helpdesk/rest-tickets/`, json, config()));
};
export const followUp = (param)=>{
    let { json, ticketId } = param;
    return sendRequest(()=>axios.put(`${hostServer}/helpdesk/rest-tickets/${ticketId}/`, json, config()));
};
export const captureHelperLog = (param)=>{
    let { title, text, metadata, clicked_document } = param;
    return sendRequest(()=>axios.post(`${hostServer}/helpdesk/helper-log`, {
            title,
            text,
            metadata,
            clicked_document
        }, config()), {
        sendIssue: true,
        setNotif: false
    });
};
