import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
/**
 * get paas app calculated final price.
 *
 * @param {Object} resource.cpu cpu in milicore: 100m
 * @param {Object} resource.ram ram in MebiByte: 128Mi
 * @param {Object} resource.disk disk in gigabyte: 10
 * @param {Object} resource.plan plan id
 * @param {Object} resource.cluster cluster id
 * @param {Object} resource.replicas replica count
 * @return {Promise}
 */ export const getFinalPrice = (param)=>{
    let { resource } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/plans/calculator/`, resource, config()));
};
/**
 * get paas app calculated dynamic plan price.
 *
 * @param {Object} resource
 * @param {Object} resource.cpu cpu in milicore: 100m
 * @param {Object} resource.ram ram in MebiByte: 128Mi
 * @return {Promise}
 */ export const getPlans = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/plans/`, config()));
};
export const getDiskPlans = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/plans/disk/`, config()));
};
