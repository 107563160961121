import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
/**
 * get paas app calculated dynamic plan price.
 *
 * @param {Object} data
 * @param {Object} data.app_id
 * @param {Object} data.server_id
 * @return {Promise}
 */ export const getTemplateApps = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/template/`, config()));
};
export const getPluginActivationCode = (param)=>{
    let { data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/license/plugin/`, data, config()));
};
export const getJiraActivationCode = (param)=>{
    let { data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/license/jira/`, data, config()));
};
export const getJiraSMActivationCode = (param)=>{
    let { data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/license/jirasm/`, data, config()));
};
export const getConfluenceActivationCode = (param)=>{
    let { data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/license/confluence/`, data, config()));
};
