import { isValueHollow } from "#helpers/validator";
import { user_API_ORGANIZATION_ROLES_ADMIN, user_API_ORGANIZATION_ROLES_BILLING_ADMIN } from "#root/consts/auth";
import { user_API_ORGANIZATION_ROLES_CRE } from "#root/consts/auth";
import { hasRole } from "#root/consts/auth";
import { user_API_ORGANIZATION_ROLES_ACCOUNTANT } from "#root/consts/auth";
import { user_API_ORGANIZATION_ROLES_OWNER } from "#root/consts/auth";
export const financialPermissions = (props)=>{
    const permissions = {
        view: hasRole([
            user_API_ORGANIZATION_ROLES_ADMIN,
            user_API_ORGANIZATION_ROLES_CRE,
            user_API_ORGANIZATION_ROLES_OWNER,
            user_API_ORGANIZATION_ROLES_ACCOUNTANT,
            user_API_ORGANIZATION_ROLES_BILLING_ADMIN
        ], props.userRoles, props.selectedOrganization),
        showBalance: props.selectedOrganization && !isValueHollow(props.selectedOrganization.balance)
    };
    return permissions;
};
