import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const deployContexts = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/deploy_contexts/`, config()));
};
export const getDeploycontext = (ctxId)=>{
    // Unused
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/deploy_contexts/${ctxId}/`, config()));
};
export const createDeployContext = (appConfig)=>sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/deploy_contexts/`, appConfig, config()));
export const updateDeployContext = (param)=>{
    let { ctxId, appConfig } = param;
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/${paasUrl}/deploy_contexts/${ctxId}/`, appConfig, config()));
};
export const deleteDeployContext = (param)=>{
    let { ctxId } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/${paasUrl}/deploy_contexts/${ctxId}/`, config()));
};
