import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const getTrivyList = (param)=>{
    let { id, limit = 10, offset = 0 } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/security_scans/?app_id=${id}&limit=${limit}&offset=${offset}`, config()));
};
export const getSingleTrivy = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/security_scans/${id}/`, config()));
};
export const startScanTrivy = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/security_scans/scan_image/?app_id=${id}`, config()));
};
export const getSummaryTrivyList = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/security_scans/summary/`, config()), {
        sendIssue: true,
        setNotif: false
    });
};
export const getSingleAppTrivySummary = (param)=>{
    let { appId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/security_scans/summary/?app_id=${appId}`, config()), {
        sendIssue: true,
        setNotif: false
    });
};
