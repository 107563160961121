import { getBrand } from "#i18n/BrandMethods";
import { isAclPowerUser } from "../components//common/roleChecker";
import { getFeature } from "../i18n/BrandSelector";
export const monitoringPermissions = (props, config)=>{
    var _props_selectedOrganization, _props_selectedOrganization1, _props_selectedOrganization2;
    const { userAppPermissions } = config || {};
    const basicPermission = ((_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : _props_selectedOrganization.show_dazzle) && isAclPowerUser(props.userRoles, props.selectedOrganization);
    const permissions = {
        view: basicPermission,
        tabView: (_props_selectedOrganization1 = props.selectedOrganization) === null || _props_selectedOrganization1 === void 0 ? void 0 : _props_selectedOrganization1.show_dazzle,
        basicView: (_props_selectedOrganization2 = props.selectedOrganization) === null || _props_selectedOrganization2 === void 0 ? void 0 : _props_selectedOrganization2.show_dazzle,
        viewDocs: getFeature("docs"),
        showPlans: getBrand() == "hamravesh",
        canCreateMonitoring: isAclPowerUser(props.userRoles, props.selectedOrganization),
        // a users who doesn't have monitoring service permission
        // can edit an app settings in paas tab with change permissions
        paasEdit: userAppPermissions && !!userAppPermissions.find((perm)=>perm === "change")
    };
    return permissions;
};
