import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const checkGithubAppInstallation = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/github/installed/`, config()));
};
export const getGithubRepos = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/github/repos/`, config()));
};
export const getGithubBranches = (param)=>{
    let { fullName } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/github/branches/`, {
            full_name: fullName
        }, config()));
};
export const checkGitlabAppInstallation = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/gitlab/installed/`, config()));
};
export const getGitlabRepos = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/gitlab/repos/`, config()));
};
export const getGitlabBranches = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/gitlab/branches/`, {
            id: id
        }, config()));
};
