import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getRegistryGCStrategies = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/registry-gc-strategies/`, config()));
};
export const getSingleRegistryGCStrategy = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/registry-gc-strategies/${id}`, config()));
};
export const createRegistryGCStrategy = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/registry-gc-strategies/`, data, config()));
};
export const updateRegistryGCStrategy = (id, data)=>{
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/registry-gc-strategies/${id}`, data, config()));
};
export const deleteRegistryGCStrategy = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/registry-gc-strategies/${id}`, config()));
};
