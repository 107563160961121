import * as R from "ramda";
export const vclusterPermissions = (props)=>{
    var _props_selectedOrganization;
    const isSpecificOrg = !!R.find(R.equals((_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : _props_selectedOrganization.name), [
        "hamravesh"
    ]);
    const permissions = {
        view: isSpecificOrg
    };
    return permissions;
};
