import { getBrandNS } from "#i18n/BrandMethods";
import tolgee from "#i18n/config";
import moment from "moment";
import persianDate from "persian-date";
export const numberFormatter = function(num) {
    let config = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    return new Intl.NumberFormat(tolgee.t({
        ns: getBrandNS(),
        key: "currency_type",
        noWrap: true
    }), config).format(num);
};
export const dateFormatter = function(date) {
    let locale = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : tolgee.getLanguage();
    return tolgee.t({
        ns: getBrandNS(),
        key: "date_type",
        noWrap: true
    }) === "gregorian" ? moment(date).locale(locale) : new persianDate(new Date(date)).toLocale(locale);
};
export const getDateFormat = function() {
    let format = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "D MMMM YYYY - HH:mm";
    return format;
};
