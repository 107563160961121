export const variables = {
    colorPrimary: "#4c3faf",
    colorTextBase: "#d6d5da",
    colorBgLayout: "#121119",
    colorText: "#d6d5da",
    controlItemBgHover: "#948ccf",
    colorInfo: "#4777d5",
    colorInfoDark: "#152440",
    colorSuccess: "#36cf8d",
    colorWarning: "#ffbc42",
    colorError: "#ff4d4f",
    colorErrorDark: "#1c0c0c",
    colorTextHeading: "#eaeaed",
    colorIconHover: "#eaeaed",
    colorTextDisabled: "#838291",
    controlItemBgActiveDisabled: "#5a586c",
    colorLink: "#948ccf",
    colorLinkHover: "#c9c5e7",
    colorLinkActive: "#7065bf",
    controlItemBgActiveHover: "#2e2669",
    // boxShodow: unset !important,
    colorBorder: "#2c2940",
    colorTextSecondary: "#adabb5",
    colorPrimaryBorder: "transparent",
    borderRadiusLG: 8,
    borderRadiusSM: 4,
    colorTextPlaceholder: "#464359",
    colorBgElevated: "#1d1c2b",
    colorBgEditor: "#0a090e",
    colorBgContainer: "#191724",
    colorBgContainerSecondary: "#1d1c2b",
    // Buttons
    buttonControlHeight: 38,
    buttonControlHeightSM: 28,
    buttonControlHeightLG: 50,
    // buttonBoxShadow: unset !important,
    buttonColorBgElevated: "#272539",
    // Select
    selectColorBorder: "#2c2940",
    selectColorBgHover: "#272539",
    // Input
    inputColorBgContainer: "#14121c",
    inputControlHeight: 38,
    inputControlHeightSM: 28,
    inputControlHeightLG: 45,
    // Tooltip
    tooltipColorBgDefault: "#312e47",
    // Select
    colorBgRevert: "#14121c",
    // Colors
    green: "#36cf8d",
    green100: "#4ad498",
    green300: "#72ddaf",
    green500: "#9be7c6",
    green700: "#c3f1dd",
    green900: "#ebfaf4",
    green0100: "#05150e",
    green0300: "#103e2a",
    green0500: "#1b6847",
    green0700: "#269163",
    green0900: "#31ba7f"
};
