import { hasRole, user_API_ORGANIZATION_ROLES_ACCOUNTANT, user_API_ORGANIZATION_ROLES_ADMIN, user_API_ORGANIZATION_ROLES_BILLING_ADMIN, user_API_ORGANIZATION_ROLES_CRE, user_API_ORGANIZATION_ROLES_DEVELOPER, user_API_ORGANIZATION_ROLES_OWNER } from "#root/consts/auth";
import cookies from "js-cookie";
import { getFeature } from "#root/i18n/BrandSelector";
const impersonateToken = JSON.parse(cookies.get("ham-imp-token") || null);
const apiKey = JSON.parse(cookies.get("api-imp-key") || null);
export const generalPermissions = (props)=>{
    const permissions = {
        viewSingleLogo: hasRole([
            user_API_ORGANIZATION_ROLES_ACCOUNTANT,
            user_API_ORGANIZATION_ROLES_BILLING_ADMIN
        ], props.userRoles, props.selectedOrganization) && !hasRole([
            user_API_ORGANIZATION_ROLES_OWNER,
            user_API_ORGANIZATION_ROLES_ADMIN,
            user_API_ORGANIZATION_ROLES_DEVELOPER,
            user_API_ORGANIZATION_ROLES_CRE
        ], props.userRoles, props.selectedOrganization) || !props.isVerified,
        viewSidebar: hasRole([
            user_API_ORGANIZATION_ROLES_OWNER,
            user_API_ORGANIZATION_ROLES_ADMIN,
            user_API_ORGANIZATION_ROLES_DEVELOPER,
            user_API_ORGANIZATION_ROLES_CRE
        ], props.userRoles, props.selectedOrganization) || props.organizations && props.organizations.length <= 0,
        impersonate: impersonateToken || apiKey,
        changeLanguage: getFeature("changeLanguage")
    };
    return permissions;
};
