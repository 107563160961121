import { paasReqs } from "#http_requests/index.reqs";
import { PAAS_DETAILS } from "#consts/routes";
import * as R from "ramda";
import { CREATE_APP_TAB_CUSTOM_CONFIG } from "#consts/paas";
import { getAppHosts } from "../paas/helper/getters";
import { appRouter } from "#/src/Router";
export const stringToHslColor = function(str) {
    let saturation = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 30, light = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 30;
    let hash = 0;
    for(let i = 0; i < str.length; i++){
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = hash % 360;
    return "hsl(" + h + ", " + saturation + "%, " + light + "%)";
};
export const groupAppsByNamespace = (apps)=>{
    const groupedApps = {};
    if (!(apps === null || apps === void 0 ? void 0 : apps.length)) {
        return null;
    }
    apps.forEach((app)=>{
        var _app_namespace;
        if (groupedApps[app === null || app === void 0 ? void 0 : (_app_namespace = app.namespace) === null || _app_namespace === void 0 ? void 0 : _app_namespace.id]) {
            var _app_namespace1;
            groupedApps[app === null || app === void 0 ? void 0 : (_app_namespace1 = app.namespace) === null || _app_namespace1 === void 0 ? void 0 : _app_namespace1.id].push(app);
        } else {
            var _app_namespace2;
            groupedApps[app === null || app === void 0 ? void 0 : (_app_namespace2 = app.namespace) === null || _app_namespace2 === void 0 ? void 0 : _app_namespace2.id] = [
                app
            ];
        }
    });
    return groupedApps;
};
export const filterAppsByNamespace = (apps, nsID)=>{
    if (!apps || (apps === null || apps === void 0 ? void 0 : apps.length) <= 0 || !nsID) {
        return [];
    }
    return apps.filter((app)=>{
        var _app_namespace;
        return ((_app_namespace = app.namespace) === null || _app_namespace === void 0 ? void 0 : _app_namespace.id) === nsID;
    });
};
export const filterAppsByCluster = (apps, clusterID)=>{
    if (!apps || (apps === null || apps === void 0 ? void 0 : apps.length) <= 0 || !clusterID) {
        return [];
    }
    return apps.filter((app)=>{
        var _app_cluster;
        return (app === null || app === void 0 ? void 0 : (_app_cluster = app.cluster) === null || _app_cluster === void 0 ? void 0 : _app_cluster.id) === clusterID;
    });
};
export const filterAppsByAppType = (apps, creationMethods)=>{
    if (!apps || (apps === null || apps === void 0 ? void 0 : apps.length) <= 0 || !creationMethods) {
        return [];
    }
    return apps.filter((app)=>R.find(R.equals(app === null || app === void 0 ? void 0 : app.creation_method), creationMethods));
};
export const filterAppsByMonitored = (apps)=>{
    if (!apps || (apps === null || apps === void 0 ? void 0 : apps.length) <= 0) {
        return [];
    }
    return apps.filter((app)=>{
        var _app_CREATE_APP_TAB_CUSTOM_CONFIG_serviceMonitor, _app_CREATE_APP_TAB_CUSTOM_CONFIG;
        return app === null || app === void 0 ? void 0 : (_app_CREATE_APP_TAB_CUSTOM_CONFIG = app[CREATE_APP_TAB_CUSTOM_CONFIG]) === null || _app_CREATE_APP_TAB_CUSTOM_CONFIG === void 0 ? void 0 : (_app_CREATE_APP_TAB_CUSTOM_CONFIG_serviceMonitor = _app_CREATE_APP_TAB_CUSTOM_CONFIG.serviceMonitor) === null || _app_CREATE_APP_TAB_CUSTOM_CONFIG_serviceMonitor === void 0 ? void 0 : _app_CREATE_APP_TAB_CUSTOM_CONFIG_serviceMonitor.enabled;
    });
};
export const filterAppsByName = (apps, name1)=>{
    if (name1 === null || !(apps === null || apps === void 0 ? void 0 : apps.length)) {
        return apps;
    }
    return apps.filter((app)=>app.name.includes(name1));
};
export const filterAppsByHostAddress = (apps, address)=>{
    if (name === null || !(apps === null || apps === void 0 ? void 0 : apps.length)) {
        return apps;
    }
    return apps.filter((app)=>getAppHosts(app).find((host)=>host.includes(address) ?? false));
};
export const fetchAppList = async ()=>{
    try {
        var _res_data;
        const res = await paasReqs.statelessApps();
        return Promise.resolve(res === null || res === void 0 ? void 0 : (_res_data = res.data) === null || _res_data === void 0 ? void 0 : _res_data.results);
    } catch (error) {
        return Promise.reject("error");
    }
};
export const openIncreaseTransaction = ()=>{
    const btnUserBalance = document.getElementById("btn-userBalance");
    if (btnUserBalance) {
        btnUserBalance.click();
    }
};
export const getFilteredApps = (apps, filterData)=>{
    let finalAppList = apps;
    const appNameRes = (filterData === null || filterData === void 0 ? void 0 : filterData.appHostAddress) ? filterAppsByHostAddress(finalAppList, filterData === null || filterData === void 0 ? void 0 : filterData.appHostAddress) : finalAppList;
    const appHostRes = (filterData === null || filterData === void 0 ? void 0 : filterData.appName) ? filterAppsByName(apps, filterData === null || filterData === void 0 ? void 0 : filterData.appName) : finalAppList;
    //Merging app name and host results to get both results
    finalAppList = R.uniqBy((obj)=>obj.id, [
        ...appNameRes ?? [],
        ...appHostRes ?? []
    ]);
    finalAppList = (filterData === null || filterData === void 0 ? void 0 : filterData.selectedNamespace) ? filterAppsByNamespace(finalAppList, filterData === null || filterData === void 0 ? void 0 : filterData.selectedNamespace) : finalAppList;
    finalAppList = (filterData === null || filterData === void 0 ? void 0 : filterData.selectedCluster) ? filterAppsByCluster(finalAppList, filterData === null || filterData === void 0 ? void 0 : filterData.selectedCluster) : finalAppList;
    finalAppList = (filterData === null || filterData === void 0 ? void 0 : filterData.appType) ? filterAppsByAppType(finalAppList, filterData === null || filterData === void 0 ? void 0 : filterData.appType) : finalAppList;
    finalAppList = (filterData === null || filterData === void 0 ? void 0 : filterData.onlyMonitoredApps) ? filterAppsByMonitored(finalAppList) : finalAppList;
    return finalAppList;
};
export const redirectToAppDetails = function(appID) {
    let tab = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "", detailsBaseURL = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : PAAS_DETAILS;
    return appRouter.navigate(`${detailsBaseURL}/${appID}${tab ? "/" + tab : ""}`);
};
export const isAppDeployable = (appData)=>{
    var _appData_state;
    return (appData === null || appData === void 0 ? void 0 : appData.is_deployable) && (appData === null || appData === void 0 ? void 0 : (_appData_state = appData.state) === null || _appData_state === void 0 ? void 0 : _appData_state.state_type) !== "disabled";
};
export const sortAppsByName = (apps)=>{
    if (!apps || (apps === null || apps === void 0 ? void 0 : apps.length) <= 0) {
        return [];
    }
    return apps.sort(function(a, b) {
        if ((a === null || a === void 0 ? void 0 : a.name) < (b === null || b === void 0 ? void 0 : b.name)) {
            return -1;
        }
        if ((a === null || a === void 0 ? void 0 : a.name) > (b === null || b === void 0 ? void 0 : b.name)) {
            return 1;
        }
        return 0;
    });
};
export const toggleNamespaceDropdown = (config)=>{
    const { namespacesID, collapsedNamespaces = [], namespace, status, toggleAll = false } = config;
    let collapsedNSes = [
        ...collapsedNamespaces
    ];
    if (toggleAll) {
        collapsedNSes = !status ? namespacesID === null || namespacesID === void 0 ? void 0 : namespacesID.map((nsID)=>nsID.toString()) : [];
    } else {
        const foundNS = R.findIndex(R.equals(namespace === null || namespace === void 0 ? void 0 : namespace.id.toString()), collapsedNSes);
        if (foundNS >= 0 && status) {
            collapsedNSes = collapsedNSes.filter((nsID)=>nsID !== (namespace === null || namespace === void 0 ? void 0 : namespace.id.toString()));
        } else {
            collapsedNSes.push(namespace === null || namespace === void 0 ? void 0 : namespace.id.toString());
        }
    }
    return collapsedNSes;
};
