import { createStoreWithMiddlewares } from "../creator";
const useSidebarStore = createStoreWithMiddlewares("sidebar", (set)=>({
        props: {
            defaultSelectedKeys: []
        },
        items: [],
        header: null,
        visible: true,
        pageName: null,
        hasSidebar: null,
        setSidebarConfig: (pageName, hasSidebar)=>set({
                pageName,
                hasSidebar
            }),
        startSettingSidebar: (props, header, items)=>set({
                props,
                header,
                items
            }),
        updateSidebarSettings: (props)=>set({
                props
            }),
        hideSidebar: ()=>set({
                items: [],
                props: {},
                header: null,
                visible: false
            })
    }));
export default useSidebarStore;
