import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getClusterPlans = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/clusterplans/`, config()));
};
export const pay = (param)=>{
    let { json } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/billing/payment/request`, json, config()));
};
export const saveInvoiceSettings = (param)=>{
    let { fields } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/billing/invoices/invoice_settings/`, fields, config()));
};
export const reportSelfTransaction = (param)=>{
    let { fields } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/billing/financial_transactions/self_reporting_transaction/`, fields, config()));
};
export const applyGift = (param)=>{
    let { fields } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/billing/giftcodes/use/`, fields, config()));
};
export const downloadTransaction = (param)=>{
    let { transactionId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/billing/financial_transactions/${transactionId}/receipt/`, {
            ...config(),
            responseType: "arraybuffer"
        }));
};
export const getCurrentInvoice = function() {
    let { productType = "" } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/billing/invoices/current_invoice/`, {
            ...config(),
            params: {
                product_type: productType
            }
        }));
};
export const getInvoices = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/billing/invoices/`, config()));
};
export const getInvoiceDetails = function() {
    let { invoiceId, productType = "" } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/billing/invoices/${invoiceId}/`, {
            ...config(),
            params: {
                product_type: productType
            }
        }));
};
export const getInvoiceResourceHistory = (param)=>{
    let { appName, cluster, namespace, resource, owner_kind, start_time, end_time } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/billing/billbillak/history/`, {
            app_name: appName,
            cluster,
            namespace,
            resource,
            owner_kind,
            start_time,
            end_time
        }, config()), {
        sendIssue: true,
        setNotif: false
    });
};
export const getTransactions = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/billing/financial_transactions/`, config()));
};
export const getMonthlyCredit = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/billing/monthly_credits/active/`, config()), {
        setNotif: false
    });
};
export const getFormalInvoices = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/billing/official_invoices/`, config()), {
        setNotif: false
    });
};
export const exportInvoice = (invoiceID)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/billing/invoices/${invoiceID}/export/`, {
            ...config(),
            responseType: "arraybuffer"
        }));
};
export const exportInvoiceSpreadsheet = (param)=>{
    let { start_date, end_date } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/billing/invoices/export_report/`, {
            start_date,
            end_date
        }, {
            ...config(),
            responseType: "arraybuffer"
        }));
};
