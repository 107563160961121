import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const getPluginsList = (param)=>{
    let { appId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/plugins/`, config()));
};
export const enablePlugin = (param)=>{
    let { appId, plugin_type } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/plugins/`, {
            plugin_type: plugin_type
        }, config()));
};
export const disablePlugin = (param)=>{
    let { appId, plugin_type } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/plugins/`, {
            params: {
                plugin_type: plugin_type
            },
            ...config()
        }));
};
