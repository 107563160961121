import { isAclPowerUser } from "../components//common/roleChecker";
import { getFeature } from "../i18n/BrandSelector";
export const containerRegistryPermissions = (props)=>{
    var _props_selectedOrganization, _props_selectedOrganization1;
    const permissions = {
        view: isAclPowerUser(props.userRoles, props.selectedOrganization) && (props === null || props === void 0 ? void 0 : (_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : _props_selectedOrganization.show_containerregistry) && getFeature("containerRegistryDisplay"),
        viewGC: (_props_selectedOrganization1 = props.selectedOrganization) === null || _props_selectedOrganization1 === void 0 ? void 0 : _props_selectedOrganization1.show_containerregistry_gc
    };
    return permissions;
};
