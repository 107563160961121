import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
export const changeDatabasePassword = (param)=>{
    let { appID, username, password } = param;
    return makeAxios({
        libConfig: {
            method: "PUT",
            url: `${hostServer}/api/v1/darkube/database/user/${appID}`,
            data: {
                username,
                password
            }
        }
    });
};
export const promoteToMaster = (param)=>{
    let { appID } = param;
    return makeAxios({
        libConfig: {
            method: "PUT",
            url: `${hostServer}/api/v1/darkube/promote-to-master/${appID}`
        }
    });
};
export const getReportDates = (param)=>{
    let { appID, year, month } = param;
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${hostServer}/api/v1/darkube/pgbadger/report/list/${appID}/?year=${year}&month=${month}`
        }
    });
};
export const getReport = (param)=>{
    let { appID, address } = param;
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${hostServer}/api/v1/darkube/pgbadger/report/${appID}/?report_address=${address}`
        }
    });
};
export const getReportSummary = (param)=>{
    let { appID, address } = param;
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${hostServer}/api/v1/darkube/pgbadger/summary/${appID}/?report_address=${address}`
        }
    });
};
export const checkCompatibility = (param)=>{
    let { host, port, uName, uPass, tag } = param;
    return makeAxios({
        libConfig: {
            method: "POST",
            url: `${hostServer}/api/v1/darkube/postgres_standby/check`,
            data: {
                src_host: host,
                src_username: uName,
                src_password: uPass,
                src_port: port,
                dst_tag: tag
            }
        }
    });
};
