import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getNotifications = (param)=>{
    let { limit = 50, offset = 0 } = param;
    return makeAxios({
        libConfig: {
            url: `${hostServer}/api/v1/notifications/all_list/?limit=${limit}&offset=${offset}`,
            method: "GET"
        }
    });
};
export const markAllAsRead = ()=>{
    return makeAxios({
        libConfig: {
            url: `${hostServer}/api/v1/notifications/mark-all-as-read/`,
            method: "GET"
        }
    });
};
export const updateAnnouncement = (param)=>{
    let { announcementID, data } = param;
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/notifications/announcements/${announcementID}/`, {
            ...data
        }, config()));
};
export const getAnnouncement = (param)=>{
    let { announcementID } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/notifications/announcements/${announcementID}/`, config()));
};
