import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, sentryUrl } from "../axios";
/**
 * Create Sentry.
 *
 * @param {Object} data
 * @param {Object} data.sentry_org sentry name
 * @param {Object} data.plan_id sentry plan id
 * @return {Promise}
 */ export const createSentry = (param)=>{
    let { data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${sentryUrl}/sentrys/`, data, config()));
};
export const deleteSentry = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/${sentryUrl}/sentrys/${id}/`, config()));
};
export const getSentryItem = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${sentryUrl}/sentrys/${id}`, config()));
};
/**
 * Create Sentry.
 *
 * @param {Object} data
 * @param {Object} data.sentry_id sentry id
 * @param {Object} data.plan_id sentry plan id
 * @return {Promise}
 */ export const updateSentry = (param)=>{
    let { id, data } = param;
    return sendRequest(()=>axios.patch(`${hostServer}/api/v1/${sentryUrl}/sentrys/${id}/`, data, config()));
};
export const getSentrys = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${sentryUrl}/sentrys/`, config()));
};
