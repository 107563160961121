import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getApiKeys = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/apikeys/`, config()));
};
export const addApiKey = (param)=>{
    let { name } = param;
    const json = {
        name
    };
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/apikeys/`, json, config()));
};
export const deleteApiKey = (param)=>{
    let { apiId } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/apikeys/${apiId}/`, config()));
};
