import { user_API_ORGANIZATION_ROLES_CRE, hasRole, user_API_ORGANIZATION_ROLES_ADMIN, user_API_ORGANIZATION_ROLES_OWNER } from "#root/consts/auth";
import * as R from "ramda";
export const kubernetesPermissions = (props)=>{
    var _props_selectedOrganization, _props_selectedOrganization1, _props_selectedOrganization2;
    const isSpecificOrg = !!R.find(R.equals((_props_selectedOrganization = props.selectedOrganization) === null || _props_selectedOrganization === void 0 ? void 0 : _props_selectedOrganization.name), [
        "hamravesh"
    ]);
    const permissions = {
        view: isSpecificOrg || hasRole([
            user_API_ORGANIZATION_ROLES_CRE
        ], props.userRoles, props.selectedOrganization),
        viewNodes: false,
        // isSpecificOrg ||
        // hasRole([user_API_ORGANIZATION_ROLES_CRE], props.userRoles, props.selectedOrganization),
        showNodepool: true,
        showAddNode: ((_props_selectedOrganization1 = props.selectedOrganization) === null || _props_selectedOrganization1 === void 0 ? void 0 : _props_selectedOrganization1.name) === "hamravesh" || ((_props_selectedOrganization2 = props.selectedOrganization) === null || _props_selectedOrganization2 === void 0 ? void 0 : _props_selectedOrganization2.name) === "mci" || hasRole([
            user_API_ORGANIZATION_ROLES_CRE
        ], props.userRoles, props.selectedOrganization),
        createAndJoinNode: isSpecificOrg || hasRole([
            user_API_ORGANIZATION_ROLES_CRE,
            user_API_ORGANIZATION_ROLES_ADMIN,
            user_API_ORGANIZATION_ROLES_OWNER
        ], props.userRoles, props.selectedOrganization),
        viewPVC: isSpecificOrg || hasRole([
            user_API_ORGANIZATION_ROLES_CRE,
            user_API_ORGANIZATION_ROLES_ADMIN,
            user_API_ORGANIZATION_ROLES_OWNER
        ], props.userRoles, props.selectedOrganization),
        createCluster: hasRole([
            user_API_ORGANIZATION_ROLES_CRE
        ], props.userRoles, props.selectedOrganization) && isSpecificOrg
    };
    return permissions;
};
