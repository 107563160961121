import { createStoreWithMiddlewares } from "../creator";
import { userReqs } from "#http_requests/index.reqs";
import { sendRequest } from "#http_requests/reqProxy/reqProxy";
const useNotificationsStore = createStoreWithMiddlewares("notification", (set)=>({
        unreadCount: null,
        setUnreadCount: (unreadCount)=>set(()=>({
                    unreadCount
                })),
        markAllAsRead: async ()=>{
            try {
                await sendRequest({
                    reqFunc: userReqs.markAllAsRead
                });
                set({
                    unreadCount: 0
                });
            } catch (error) {
                console.error(error);
            }
        }
    }));
export default useNotificationsStore;
