import tolgee from "#i18n/config";
import * as R from "ramda";
import { isValueHollow } from "../helpers/validator";
import icGit from "../assets/images/ic-git-colored.svg";
import icStatic from "../assets/images/ic-static-lblue.svg";
import icDocker from "../assets/images/ic-docker-colored.svg";
import icMinio from "../assets/images/ic-minio-colored.svg";
import isPostgres from "../assets/images/ic-postgres-colored.svg";
import icRedis from "../assets/images/ic-redis-colored.svg";
import icMongodb from "../assets/images/ic-mongodb-colored.svg";
import icNginx from "../assets/images/ic-nginx.svg";
import icMysql from "../assets/images/ic-mysql-colored.svg";
import icMssql from "../assets/images/ic-mssql-colored.svg";
import icMariadb from "../assets/images/ic-mariadb.svg";
import icClojure from "../assets/images/ic-clojure.svg";
import icJava from "../assets/images/ic-java.svg";
import icScala from "../assets/images/ic-scala.svg";
import icPython from "../assets/images/ic-python.svg";
import icNodejs from "../assets/images/ic-nodejs.svg";
import icGo from "../assets/images/ic-go.svg";
import icRuby from "../assets/images/ic-ruby.svg";
import icPHP from "../assets/images/ic-php.svg";
import icGitlab from "../assets/images/ic-gitlab-lblue.svg";
import icPyroscope from "../assets/images/ic-pyroscope.svg";
import icGithub from "../assets/images/ic-github-lblue.svg";
import icWordpress from "../assets/images/ic-wordpress.svg";
import icGrafana from "../assets/images/ic-grafana.svg";
import icJupyter from "../assets/images/ic-jupyter.svg";
import icPrometheus from "../assets/images/ic-prometheus.svg";
import icNextcloud from "../assets/images/ic-nextcloud.svg";
import icKibana from "../assets/images/ic-kibana.svg";
import icElasticSearch from "../assets/images/ic-elasticsearch.svg";
import icRocketchat from "../assets/images/ic-rocketchat.svg";
import icJira from "../assets/images/ic-jira.svg";
import icJiraSM from "../assets/images/ic-jira-sm.svg";
import icRabbitMq from "../assets/images/ic-rabbit.svg";
import icMetabase from "../assets/images/ic-metabase.svg";
import icArtifactory from "../assets/images/ic-artifactory.svg";
import icGitlabRunner from "../assets/images/ic-gitlab-runner.svg";
import icDjango from "../assets/images/app-icons/django.svg";
import icReact from "../assets/images/app-icons/react.svg";
import icFileUpload from "../assets/images/upload.svg";
import icConfluence from "../assets/images/app-icons/confluence.svg";
import icDockerCompose from "../assets/images/app-icons/docker-compose.png";
import icDotnetCore from "../assets/images/app-icons/dotnet-core.svg";
import icKeycloak from "../assets/images/app-icons/keycloak.svg";
import icKafka from "../assets/images/app-icons/kafka.svg";
import slugify from "slugify";
//APP ITEM KEYS
export const paas_API_APP_ID = "id";
export const paas_API_APP_NAME = "name";
export const paas_API_APP_NAMESPACE = "namespace";
export const paas_API_APP_JUPYTER_TOKEN = "jupyterToken";
export const paas_API_APP_NAMESPACE_ALLOW_CREATE_POD = "allow_create_pod";
export const paas_API_APP_ORGANIZATION = "organization";
export const paas_API_APP_CREATOR = "creator";
export const paas_API_APP_GIT_REPO_URL = "git_repo_url";
export const paas_API_APP_GIT_BRANCH_NAME = "git_branch_name";
export const paas_API_APP_GIT_BUILD_ARGS = "git_build_args";
export const paas_API_APP_RAM_LIMIT = "ram_limit";
export const paas_API_APP_CPU_LIMIT = "cpu_request";
export const paas_API_APP_DISK_REQUEST = "disk_request";
export const paas_API_APP_REDIRECT_SSL = "redirect_SSL";
export const paas_API_APP_ENABLE_SSL = "enable_SSL";
export const paas_API_APP_AUTODEPLOY_ON_GIT_PUSH = "autodeploy_on_git_push";
export const paas_API_APP_REPLICAS = "replicas";
export const paas_API_APP_IMAGE_REPO = "image_repo";
export const paas_API_APP_IMAGE_TAG = "image_tag";
export const paas_API_APP_ENVS = "envs";
export const paas_API_APP_EXTERNAL_HOSTS = "external_hosts";
export const paas_API_APP_SERVICE_TYPE = "service_type";
export const paas_API_APP_METRIC_PATH = "path";
export const paas_API_APP_METRIC_PORT = "port";
export const paas_API_APP_NEW_SERVICE_TYPE = "serviceType";
export const paas_API_APP_RABBIT_MQ_PERSISTENCE = "rabbitmqPersistence";
export const paas_API_APP_HAMARTIA_PATH = "path";
export const paas_API_APP_SERVICE_TYPE_CLUSTER_IP = "ClusterIP";
export const paas_API_APP_SERVICE_TYPE_LOAD_BALANCER = "LoadBalancer";
export const paas_API_APP_CLUSTER = "cluster";
export const paas_API_APP_CLUSTER_LOCATION_DATA_CENTER = "location_datacenter";
export const paas_API_APP_CLUSTER_CUSTOM_BASE_DOMAIN = "apps_custom_base_domain";
export const paas_API_APP_CLUSTER_IS_ON_PREMISE = "is_on_premise";
export const paas_API_APP_PLAN = "plan";
export const paas_API_APP_COMMAND = "command";
export const paas_API_APP_ARGS = "args";
export const paas_API_APP_READINESS_PROBE_PATH = "readiness_probe_path";
export const paas_API_APP_LIVENESS_PROBE_PATH = "liveness_probe_path"; // not used anywhere
export const paas_API_APP_DEPLOY_CONTEXT = "deploy_context";
export const paas_API_APP_CREATION_METHOD = "creation_method";
export const paas_API_APP_BUILD_TYPE = "build_method";
export const paas_API_APP_BUILD_OPTIONS = "build_options";
export const paas_API_BUILD_APP_TYPE = "app_type";
export const paas_API_APP_CUSTOM_SUBDOMAIN_ADDR = "custom_subdomain_addr";
export const paas_API_APP_CUSTOM_SUBDOMAIN_ADDRESS = "custom_domain_address";
export const paas_API_APP_CUSTOM_CONFIG = "custom_config";
export const paas_API_APP_CUSTOM_CONFIG_HPA = "hpa";
export const paas_API_APP_CUSTOM_CONFIG_HPA_ENABLED = "enabled";
export const paas_API_APP_CUSTOM_CONFIG_HPA_MIN_REPLICAS = "minReplicas";
export const paas_API_APP_CUSTOM_CONFIG_HPA_MAX_REPLICAS = "maxReplicas";
export const paas_API_APP_CUSTOM_CONFIG_HPA_TARGET_CPU_UTILIZATION_PERCENTAGE = "targetCPUUtilizationPercentage";
export const paas_API_APP_CUSTOM_CONFIG_HPA_TARGET_RAM_UTILIZATION_PERCENTAGE = "targetMemoryUtilizationPercentage";
export const paas_API_APP_CONFIG = "config";
export const paas_API_APP_CONFIG_FILES = "files";
export const paas_API_APP_IS_HPA_ENABLED = "is_hpa_enbaled";
export const paas_API_APP_IS_ENABLED = "is_enabled";
export const paas_API_APP_IS_RESTRICTED = "is_restricted";
export const paas_API_APP_LATEST_BUILD = "latest_build";
export const paas_API_APP_TRIGGER_GOAL = "trigger_goal";
export const paas_API_APP_CREATION_TIME = "creation_time";
export const paas_API_APP_UPDATED_AT = "updated_at";
export const paas_API_APP_IS_MIGRATING = "is_migrating";
export const paas_API_APP_HELM_RELEASE_NAME = "helm_release_name";
export const paas_API_APP_DESCRIPTION = "description";
export const paas_API_APP_GIT_BUILD_WORKDIR = "git_build_workdir";
export const paas_API_APP_AUTO_GENERATE_GITLABCI = "autogenerate_gitlabci";
export const paas_API_APP_BUILD_WEBHOOK_SECRET = "build_webhook_secret";
export const paas_API_APP_EPHEMERAL_STORAGE_LIMIT = "ephemeral_storage_limit";
export const paas_API_APP_ENABLE_HTTPV2 = "enable_httpv2";
export const paas_API_APP_SERVICE_DNS_RECORD_HAS_BEEN_SET = "service_dns_record_has_been_set";
export const paas_API_APP_PROBES_HTTP_HEADERS = "probes_http_headers";
export const paas_API_APP_IS_DISK_BACKUP_ENABLED = "is_disk_backup_enabled";
export const paas_API_APP_HAS_CRE = "has_cre";
export const paas_API_APP_DATABASE_PROPERTIES = "database_properties";
export const paas_API_APP_TRIGGER_GOAL_UPDATE = "migrate_enabled_app";
export const paas_API_APP_TRIGGER_GOAL_ENABLE = "migrate_and_enable_app";
export const paas_API_APP_HAMARTIA_CONFIG = "log_aggregation_config";
export const paas_API_APP_HAMARTIA_STDOUT = "stdout";
export const paas_API_APP_HAMARTIA_FILE_PATH = "file_paths";
export const paas_API_APP_HAMARTIA_LABELS = "labels";
// plugins
export const paas_API_APP_FILE_BROWSER_PLUGIN_KEY = "file_browser";
export const paas_API_APP_CLOUD_BEAVER_PLUGIN_KEY = "cloud_beaver";
export const paas_API_APP_REDIS_INSIGHT_PLUGIN_KEY = "redis_insight";
export const paas_API_APP_CUSTOM_CONFIG_INGRESS = "ingress";
export const paas_API_APP_CUSTOM_CONFIG_HOSTS = "hosts";
export const paas_API_APP_INGRESS_CNAME_ADDRESS = "ingress_cname_address";
export const paas_API_APP_INGRESS_HOSTNAME_ADDRESS = "ingress_hostname_address";
export const paas_API_APP_TRIGGER_DEPLOY_TOKEN = "trigger_deploy_token";
export const paas_API_APP_DISKS = "disk";
export const paas_API_APP_INGRESS_BASIC_AUTH_CONFIG = "ingress_basic_auth_config";
export const paas_API_APP_TEMPLATE_FIELDS = "template_fields";
export const paas_API_APP_VERSION = "version";
export const paas_API_APP_SVC = "svc";
export const paas_API_APP_IS_DEPLOYABLE = "is_deployable";
export const paas_API_APP_BUILD_DOCKERFILE = "git_build_dockerfile";
export const paas_API_APP_BUILD_CONTEXT = "git_build_context";
export const paas_API_APP_GIT_PROVIDER_TYPE = "git_provider_type";
export const paas_API_APP_CHANGE_PERMISSION = "change";
export const paas_API_APP_DELETE_PERMISSION = "delete";
export const paas_API_APP_CREATE_PERMISSION = "create";
export const paas_API_APP_VIEW_PERMISSION = "view";
export const paas_API_APP_TRIVY_VULNERABILITYID = "VulnerabilityID";
export const paas_API_APP_TRIVY_PRIMARYURL = "PrimaryURL";
export const paas_API_APP_TRIVY_PKGNAME = "PkgName";
export const paas_API_APP_TRIVY_INSTALLEDVERSION = "InstalledVersion";
export const paas_API_APP_TRIVY_FIXEDVERSION = "FixedVersion";
export const paas_API_APP_TRIVY_TITLE = "Title";
export const paas_API_APP_TRIVY_DESCRIPTION = "Description";
export const paas_API_APP_TRIVY_SEVERITY = "Severity";
export const paas_API_APP_TRIVY_CLASS = "Class";
export const paas_API_APP_TRIVY_TARGET = "Target";
export const paas_API_APP_TRIVY_TYPE = "Type";
export const paas_API_APP_BUILDER = "builder";
export const paas_API_APP_BUILDER_HEROKU = "heroku_buildpacks";
export const paas_API_APP_BUILDER_DOCKER = "dockerfile";
export const paas_API_APP_INTERNAL_CONNECTION = "INTERNAL_CONNECTION_STRING";
export const paas_API_APP_EXTERNAL_CONNECTION = "EXTERNAL_CONNECTION_STRING";
export const paas_API_APP_TEMPLATE_USERNAME = "username";
export const paas_API_APP_TEMPLATE_PASSOWRD = "password";
export const paas_API_APP_CUSTOM_CONFIG_PERSISTENCE_DISK = "persistence";
//TEMPLATE APP: GRAFANA KEYS
export const paas_API_APP_GRAFANA_USERNAME = "username";
export const paas_API_APP_GRAFANA_PASSOWRD = "password";
//TEMPLATE APP: NEXTCLOUD KEYS
export const paas_API_APP_NEXT_CLOUD_USERNAME = "nextcloudUsername";
export const paas_API_APP_NEXT_CLOUD_PASSOWRD = "nextcloudPassword";
//TEMPLATE APP: MINIO KEYS
export const paas_API_APP_TEMPLATE_DISK_SIZE = "disk";
export const paas_API_APP_MINIO_ACCESS_KEY = "MINIO_ACCESS_KEY";
export const paas_API_APP_MINIO_SECRET_KEY = "MINIO_SECRET_KEY";
//TEMPLATE APP: JIRA,CONFLUENCE AND ALL ATLASSIAN APPS KEYS
export const paas_API_APP_ATLASSIAN_SERVER_ID = "server_id";
export const paas_API_APP_ATLASSIAN_APP_ID = "app_id";
export const paas_API_APP_ATLASSIAN_PLUGIN_KEY = "plugin_key";
//TEMPLATE APP: REDIS KEYS
export const paas_API_APP_REDIS_INTERNAL_CONNECTION = "INTERNAL_CONNECTION_STRING";
export const paas_API_APP_REDIS_EXTERNAL_CONNECTION = "EXTERNAL_CONNECTION_STRING";
export const paas_API_APP_REDIS_PASSWORD = "REDIS_PASSWORD";
export const paas_API_APP_REDIS_AIO_PASSWORD = "redisPassword";
export const paas_API_APP_REDIS_DISK_SIZE = "disk";
//TEMPLATE APP: GITLAB RUNNER KEYS
export const paas_API_APP_GITLAB_RUNNER_REGISTRATION_TOKEN = "runnerRegistrationToken";
export const paas_API_APP_GITLAB_RUNNER_URL = "gitlabUrl";
export const paas_API_APP_GITLAB_RUNNER_TAGS = "tags";
export const paas_API_APP_GITLAB_RUNNER_RUNNERS = "runners";
//TEMPLATE APP: KIBANA KEYS
export const paas_API_APP_KIBANA_ELASTIC_HOST = "elasticsearchHosts";
export const paas_API_APP_KIBANA_ELASTIC_USERNAME = "elasticsearchUsername";
export const paas_API_APP_KIBANA_ELASTIC_PASSWORD = "elasticsearchPassword";
//TEMPLATE APP: POSTGRES KEYS
export const paas_API_APP_POSTGRES_CMD = "PSQL_COMMAND";
export const paas_API_APP_POSTGRES_DB_NAME = "db_name";
export const paas_API_APP_POSTGRES_USERNAME = "username";
export const paas_API_APP_POSTGRESQL_PASSWORD = "POSTGRESQL_PASSWORD";
export const paas_API_APP_POSTGRESQL_DB_NAME = "POSTGRESQL_DATABASE";
export const paas_API_APP_POSTGRESQL_NEW_DB_NAME = "initDatabaseName";
export const paas_API_APP_POSTGRESQL_USERNAME = "POSTGRESQL_USERNAME";
export const paas_API_APP_POSTGRES_DISK_SIZE = "disk";
export const paas_API_APP_POSTGRES_INTERNAL_CONNECTION = "INTERNAL_CONNECTION_STRING";
export const paas_API_APP_POSTGRES_EXTERNAL_CONNECTION = "EXTERNAL_CONNECTION_STRING";
export const paas_API_APP_POSTGRESQL_AIO_PASSWORD = "postgresqlPassword";
export const paas_API_APP_TEMPLATE_STOLON_SERVICE_NAME = "stolonServiceName";
export const paas_API_APP_TEMPLATE_STOLON_STANDBY_SERVICE_NAME = "stolonStandbyServiceName";
export const paas_API_APP_TEMPLATE_STOLON_PASSWORD = "superuserPassword";
export const paas_API_APP_TEMPLATE_STOLON_USERNAME = "superuserUsername";
//TEMPLATE APP: MY SQL
export const paas_API_APP_MY_SQL_USERNAME = "mysqlUser";
export const paas_API_APP_MY_SQL_PASSWORD = "mysqlPassword";
export const paas_API_APP_MY_SQL_ROOT_PASSWORD = "mysqlRootPassword";
export const paas_API_APP_MY_SQL_DB_NAME = "mysqlDatabase";
export const paas_API_APP_MY_SQL_DB_DISK_SIZE = "disk";
export const paas_API_APP_MY_SQL_NAME = "username";
export const paas_API_APP_MINIO_ROOT_USERNAME = "MINIO_ROOT_USER";
export const paas_API_APP_MINIO_ROOT_PASSWORD = "MINIO_ROOT_PASSWORD";
export const paas_API_APP_MINIO_CONSOLE_URL = "minioConsoleRootURL";
export const paas_API_APP_MINIO_ROOT_URL = "minioRootURL";
//TEMPLATE APP: MS SQL
export const paas_API_APP_MS_SQL_SA_PASSWORD = "saPassword";
//TEMPLATE APP: Rabbit Mq
export const paas_API_APP_RABBIT_MQ_USERNAME = "rabbitmqUsername";
export const paas_API_APP_RABBIT_MQ_PASSWORD = "rabbitmqPassword";
export const paas_API_APP_RABBIT_MQ_ROOT_PASSWORD = "rabbitmqRootPassword";
export const paas_API_APP_RABBIT_MQ_DISK_SIZE = "disk";
export const paas_API_APP_RABBIT_MQ_NAME = "username";
export const paas_API_APP_RABBIT_MQ_STATS_DOMAIN = "statsDomain";
//TEMPLATE APP: Maria db
export const paas_API_APP_MARIA_USERNAME = "mariadbUser";
export const paas_API_APP_MARIA_PASSWORD = "mariadbPassword";
export const paas_API_APP_MARIA_ROOT_PASSWORD = "mariadbRootPassword";
export const paas_API_APP_MARIA_DB_NAME = "mariadbDatabase";
export const paas_API_APP_MARIA_DB_DISK_SIZE = "disk";
export const paas_API_APP_MARIA_NAME = "username";
//TEMPLATE APP: Mongo db
export const paas_API_APP_MONGO_USERNAME = "mongodbUser";
export const paas_API_APP_MONGO_PASSWORD = "mongodbPassword";
export const paas_API_APP_MONGO_ROOT_PASSWORD = "mongodbRootPassword";
export const paas_API_APP_MONGO_DB_NAME = "mongodbDatabase";
export const paas_API_APP_MONGO_DB_DISK_SIZE = "disk";
export const paas_API_APP_MONGO_NAME = "username";
export const paas_API_APP_MONGO_CMD = "MONGO_COMMAND";
//TEMPLATE APP: Mongo db
export const paas_API_APP_ELASTIC_USERNAME = "elasticsearchUsername";
export const paas_API_APP_ELASTIC_PASSWORD = "elasticsearchPassword";
//TEMPLATE APP: Prometheus
export const paas_API_APP_PROMETHEUS_USERNAME = "prometheusUsername";
export const paas_API_APP_PROMETHEUS_PASSWORD = "prometheusPassword";
//APP INGRESS AUTH
export const paas_API_INGRESS_AUTH_USERNAME = "username";
export const paas_API_INGRESS_AUTH_PASSWORD = "password";
//APP PLAN KEYS
export const paas_API_PLAN_CODE_NAME = "code_name";
export const paas_API_PLAN_COST_TYPE = "cost_type";
export const paas_API_PLAN_COST = "cost";
export const paas_API_PLAN_DETAIL = "detail";
export const paas_API_PLAN_NAME = "name";
export const paas_API_PLAN_RAM_LIMIT = "ram_limit";
export const paas_API_PLAN_CPU_LIMIT = "cpu_request";
export const paas_API_PLAN_ID = "id";
export const paas_API_PLAN_HOURLY_COST = "hourly_cost";
export const paas_API_PLAN_TYPE = "plan_type";
export const paas_API_PLAN_DESCRIPTION = "description";
export const paas_API_PLAN_CATEGORY_APP = "app";
export const paas_API_PLAN_CATEGORY_STATIC = "static";
export const paas_API_PLAN_CATEGORY_WORDPRESS = "wordpress";
export const paas_API_PLAN_CATEGORY_GRAFANA = "grafana";
export const paas_API_PLAN_CATEGORY_PROMETHEUS = "prometheus";
export const paas_API_PLAN_CATEGORY_NEXT_CLOUD = "nextcloud";
export const paas_API_PLAN_CATEGORY_KIBANA = "kibana";
export const paas_API_PLAN_CATEGORY_ELASTIC_SEARCH = "elasticsearch";
export const paas_API_PLAN_CATEGORY_ROCKETCHAT = "rocketchat";
export const paas_API_PLAN_CATEGORY_JIRA = "jira";
export const paas_API_PLAN_CATEGORY_JIRASM = "jirasm";
export const paas_API_PLAN_CATEGORY_RABBIT_MQ = "rabbitmq";
export const paas_API_PLAN_CATEGORY_CONFLUENCE = "confluence";
export const paas_API_PLAN_CATEGORY_METABASE = "metabase";
export const paas_API_PLAN_CATEGORY_JUPYTER = "jupyter";
export const paas_API_PLAN_CATEGORY_ARTIFACTORY = "artifactory";
export const paas_API_PLAN_CATEGORY_GITLAB_RUNNER = "gitlab_runner";
export const paas_API_PLAN_CATEGORY_DISK = "disk";
export const paas_API_PLAN_CATEGORY_MY_SQL = "mysql";
export const paas_API_PLAN_CATEGORY_PYROSCOPE = "pyroscope";
export const paas_API_PLAN_CATEGORY_NGINX = "nginx";
export const paas_API_PLAN_CATEGORY_POSTGRES_SQL_AIO = "postgresqlnew";
export const paas_API_PLAN_CATEGORY_STOLON = "stolon";
export const paas_API_PLAN_CATEGORY_MARIA_DB = "mariadb";
export const paas_API_PLAN_CATEGORY_MONGO_DB = "mongodb";
export const paas_API_PLAN_CATEGORY_MS_SQL = "mssql";
export const paas_API_PLAN_CATEGORY_MINIO = "minio";
export const paas_API_PLAN_CATEGORY_MINIO_NEW = "minionew";
export const paas_API_PLAN_CATEGORY_REDIS_NEW = "redisnew";
export const paas_API_PLAN_CATEGORY_KEYCLOAK = "keycloak";
export const paas_API_PLAN_CATEGORY_KAFKA = "kafka";
//APP DISK / PV
export const paas_API_DISK_ID = "id";
export const paas_API_DISK_NAME = "display_name";
export const paas_API_DISK_PATH = "mount_path";
export const paas_API_DISK_SIZE_GI = "size_in_Gi";
export const paas_API_DISK_PARTITIONS = "partitions";
export const paas_API_DISK_STORAGE_CLASS_NAME = "storage_class_name";
export const paas_API_DISK_FSGROUP = "fsgroup";
//APP PORTS / SVC
export const paas_API_SVC_PORTS = "ports";
export const paas_API_SVC_TYPE = "type";
export const paas_API_SVC_INTERNAL_ADDRESS = "internalAddress";
export const paas_API_SVC_EXTERNAL_ADDRESS = "externalAddress";
export const paas_API_SVC_EXTERNAL_IP = "externalIP";
export const paas_API_SVC_PROTOCOL = "protocol";
export const paas_API_SVC_NODE_PORT = "nodePort";
export const paas_API_SVC_SERVICE_PORT = "servicePort";
export const paas_API_SVC_CONTAINER_PORT = "containerPort";
export const paas_API_SVC_NAME = "name";
export const paas_PLAN_CODE_NAME_DYNAMIC = "dynamic";
export const paas_PLAN_COST_TYPE_FIXED = "fixed";
export const paas_PLAN_COST_TYPE_DYNAMIC = "dynamic";
export const paas_API_BUILD_ID = "id";
export const paas_API_BUILD_LOG = "log";
export const paas_API_BUILD_STATUS = "status";
export const paas_API_BUILD_SHORT_COMMIT_HASH = "short_commit_hash";
export const paas_API_BUILD_COMMIT_MESSAGE = "commit_message";
export const paas_API_BUILD_CREATION_TIME = "creation_time";
export const paas_API_BUILD_END_TIME = "end_time";
export const paas_API_BUILD_GIT_BRANCH = "git_branch";
export const paas_API_BUILD_STATUS_INITIATED = "initiated";
export const paas_API_BUILD_STATUS_SUCCEEDED = "succeeded";
export const paas_API_BUILD_STATUS_FAILED = "failed";
export const paas_API_BUILD_STATUS_RUNNING = "running";
export const paas_API_BUILD_STATUS_CANCELED = "canceled";
// Security status in trivy scanner
export const paas_API_SECURITY_SCAN_STATUS_FINISHED = "finished";
export const paas_API_SECURITY_SCAN_STATUS_FAILED = "failed";
export const paas_API_SECURITY_SCAN_STATUS_RUNNING = "running";
export const paas_API_SECURITY_STATUS_INITIATED = "initiated";
export const paas_API_SECURITY_SCANS = "security_scans";
export const paas_API_TRIVY_SCAN_TOOL = "trivy";
export const paas_API_APP_STATUS = "state";
export const paas_API_APP_STATUS_INITIALIZING = "initializing";
export const paas_API_APP_STATUS_ERROR = "error";
export const paas_API_APP_STATUS_ERRORS = "errors";
export const paas_API_APP_STATUS_RUNNING = "running";
export const paas_API_APP_STATUS_DISABLED = "disabled";
export const paas_API_APP_STATUS_RESTRICTED = "restricted";
export const paas_API_APP_STATUS_NOT_READY = "not_ready";
export const paas_API_APP_STATUS_PENDING = "pending";
export const paas_API_APP_STATUS_EVICTED = "evicted";
export const paas_API_REGISTRY_ID = "id";
export const paas_API_REGISTRY_ADDRESS = "address";
export const paas_API_REGISTRY_NAME = "name";
export const paas_API_REGISTRY_USERNAME = "username";
export const paas_API_REGISTRY_PASSWORD = "password";
export const paas_API_REGISTRY_IS_DELETABLE = "is_deletable";
export const paas_API_VIPER_ALERT_RULE = "alert_rule";
export const paas_API_VIPER_ALERT_SOURCE_TYPE = "source_type";
export const paas_API_VIPER_SOURCE_APP = "source_app";
export const paas_API_VIPER_SOURCE_ORGANIZATION = "source_organization";
export const paas_API_VIPER_RECEIVER_USER = "receiver_user";
export const paas_API_VIPER_RECEIVER_TYPE = "receiver_type";
export const paas_API_VIPER_CHANNEL_TYPE = "channel_type";
export const paas_API_VIPER_RECEIVER_TYPE_ORGANIZATION_ADMINS = "organization_admins";
export const paas_API_VIPER_RECEIVER_TYPE_USER = "user";
export const paas_APP_STATUS = {
    [paas_API_APP_STATUS_INITIALIZING]: {
        text: "Initializing"
    },
    [paas_API_APP_STATUS_ERROR]: {
        text: "Error"
    },
    [paas_API_APP_STATUS_RUNNING]: {
        text: "Running"
    },
    [paas_API_APP_STATUS_DISABLED]: {
        text: "Disabled"
    },
    [paas_API_APP_STATUS_RESTRICTED]: {
        text: "Insufficient Credit"
    },
    [paas_API_APP_STATUS_RESTRICTED]: {
        text: tolgee.t({
            ns: "paas",
            key: "label.app_status_low_credit"
        })
    },
    [paas_API_APP_STATUS_NOT_READY]: {
        text: "Not Ready",
        metaData: "Health Check"
    },
    [paas_API_APP_STATUS_PENDING]: {
        text: "Pending"
    },
    [paas_API_APP_STATUS_EVICTED]: {
        text: "Evicted"
    }
};
export const paas_APP_PACKAGES_BUG_OS = "os-pkgs";
export const paas_APP_PACKAGES_BUG_LANG_PKG = "lang-pkgs";
export const paas_APP_PACKAGES_BUG_CONFIG = "config";
//APP File Upload Keys
//APP TYPE
export const APP_TYPE_GIT = "git_repo_url";
export const APP_TYPE_FILE_UPLOAD = "file_upload";
export const APP_TYPE_DOCKER = "docker_image";
export const APP_TYPE_DOCKER_COMPOSE = "docker_compose";
export const APP_TYPE_STATIC = "static_app";
export const APP_TYPE_MINIO = "minio";
export const APP_TYPE_MINIO_NEW = "minionew";
export const APP_TYPE_MONGODB = "mongodb";
export const APP_TYPE_REDIS = "redis";
export const APP_TYPE_REDIS_NEW = "redisnew";
export const APP_TYPE_REDIS_DBAAS = "redisdbaas";
export const APP_TYPE_POSTGRES = "postgresql";
export const APP_TYPE_POSTGRES_AIO = "postgresqlnew";
export const APP_TYPE_STOLON = "stolon";
export const APP_TYPE_MY_SQL = "mysql";
export const APP_TYPE_PYROSCOPE = "pyroscope";
export const APP_TYPE_NGINX = "nginx";
export const APP_TYPE_MS_SQL = "mssql";
export const APP_TYPE_MARIA_DB = "mariadb";
export const APP_TYPE_WORDPRESS = "wordpress";
export const APP_TYPE_GRAFANA = "grafana";
export const APP_TYPE_PROMETHEUS = "prometheus";
export const APP_TYPE_NEXT_CLOUD = "nextcloud";
export const APP_TYPE_KIBANA = "kibana";
export const APP_TYPE_ELASTIC_SEARCH = "elasticsearch";
export const APP_TYPE_ROCKETCHAT = "rocketchat";
export const APP_TYPE_JIRA = "jira";
export const APP_TYPE_JIRASM = "jirasm";
export const APP_TYPE_RABBIT_MQ = "rabbitmq";
export const APP_TYPE_METABASE = "metabase";
export const APP_TYPE_JUPYTER = "jupyter";
export const APP_TYPE_ARTIFACTORY = "artifactory";
export const APP_TYPE_GITLAB_RUNNER = "gitlab_runner";
export const APP_TYPE_CONFLUENCE = "confluence";
export const APP_TYPE_KEYCLOAK = "keycloak";
export const APP_TYPE_KAFKA = "kafka";
export const APP_TYPE_BUILDPACK_STATIC = "static";
export const APP_TYPE_BUILDPACK_NODEJS = "nodejs";
export const APP_TYPE_BUILDPACK_CLOJURE = "clojure";
export const APP_TYPE_BUILDPACK_PYTHON = "python";
export const APP_TYPE_BUILDPACK_DJANGO = "django";
export const APP_TYPE_BUILDPACK_REACT = "react";
export const APP_TYPE_BUILDPACK_JAVA = "java";
export const APP_TYPE_BUILDPACK_SCALA = "scala";
export const APP_TYPE_BUILDPACK_PHP = "php";
export const APP_TYPE_BUILDPACK_RUBY = "ruby";
export const APP_TYPE_BUILDPACK_GO = "go";
export const APP_TYPE_BUILDPACK_DOTNET_CORE = "dotnet-core";
export const APP_CATEGORY_DOCKER = "docker";
export const APP_CATEGORY_DOCKER_COMPOSE = "docker_compose"; //!Temporary, steps need refactor
export const APP_CATEGORY_GIT = "git";
export const APP_CATEGORY_TEMPLATE = "template";
export const APP_CATEGORY_FILE_UPLOAD = "fileUpload";
export const APP_CATEGORY_BUILDPACK = "buildpack";
//APP_GROUP
export const APP_GROUP_DOCKERIZED_APP = "create_dockerized_app";
export const APP_GROUP_WITHOUT_DOCKERFILE = "create_without_dockerfile";
export const APP_GROUP_DATABASE_APP = "create_database_app";
export const APP_GROUP_TEMPLATE_APP = "create_template_app";
export const GIT_SERVICE_HAMGIT = "Gitlab";
export const GIT_SERVICE_GITHUB = "Github";
export const FILE_UPLOAD_SERVICE = "FileUpload";
//GIT
export const GIT_INSTALLED = "installed";
export const GIT_REDIRECT_URL = "redirect_url";
export const GIT_FULL_NAME = "full_name";
export const GIT_URL = "url";
export const GIT_BRANCH_NAME = "name";
export const GIT_ID = "id";
export const BUILD_TYPE_WEBHOOK = "webhook";
export const BUILD_TYPE_GITLABCI = "gitlabci";
export const CREATE_APP_PAGE_SELECT_YAML = "create_app_select_yaml";
export const CREATE_APP_PAGE_TYPE = "create_app_app_type";
export const CREATE_APP_PAGE_GIT_SERVICE = "create_app_git_service";
export const CREATE_APP_PAGE_FILE_UPLOAD = "create_app_file_upload";
export const CREATE_APP_PAGE_GIT_INFO = "create_app_git_info";
export const CREATE_APP_PAGE_REPO = "create_app_select_repo";
export const CREATE_APP_PAGE_REPO_LIST = "create_app_select_repo_list";
export const CREATE_APP_PAGE_IMAGE = "create_app_select_image";
export const CREATE_APP_PAGE_PLAN = "create_app_plan";
export const CREATE_APP_PAGE_FINALIZE = "create_app_finalize";
export const CREATE_APP_PAGE_FINALIZE_DBAAS = "create_app_finalize_dbaas";
export const CREATE_APP_PAGE_ADVANCED = "create_app_advanced";
export const CREATE_APP_PAGE_SETTINGS = "create_app_settings";
//APP TABS
//TODO: Remove "CREATE" prefix. key names are using in edit app page
export const CREATE_APP_TAB_GENERAL = "general";
export const CREATE_APP_TAB_DOCKER_AND_SERVICE = "docker_and_service";
export const CREATE_APP_TAB_HOSTS = "hosts";
export const CREATE_APP_TAB_CUSTOM_CONFIG = "custom_config";
export const CREATE_APP_TAB_CONFIG = "config";
export const CREATE_APP_TAB_SECURITY = "security";
export const CREATE_APP_TAB_LOKI_LOGS = "slow_queries_log";
export const CREATE_APP_TAB_HPA = "hpa";
export const CREATE_APP_TAB_ENVS = "envs";
export const CREATE_APP_TAB_LOGS = "logs";
export const CREATE_APP_TAB_TERMINAL = "terminal";
export const CREATE_APP_TAB_DISKS = "disks";
export const CREATE_APP_TAB_BUILD = "build";
export const CREATE_APP_TAB_PERMISSIONS = "permissions";
export const CREATE_APP_TAB_APP_HISTORY = "history";
export const CREATE_APP_TAB_APP_MONITORING = "monitoring";
export const CREATE_APP_TAB_APP_LOGGING = "log_aggregation";
export const CREATE_APP_TAB_APP_BACKUPS = "backups";
export const CREATE_APP_TAB_SETTINGS = "settings";
export const CREATE_APP_TAB_PORTS = "ports";
export const CREATE_APP_TAB_PLANS = "plans";
export const CREATE_APP_TAB_RESOURCE_USAGE = "resource_usage";
export const BUILD_TAB_LIST = "build_list";
export const BUILD_TAB_ARGS = "build_args";
export const getGitServiceIcon = (service)=>{
    switch(service){
        case GIT_SERVICE_GITHUB:
            return icGithub;
        case GIT_SERVICE_HAMGIT:
            return icGitlab;
        default:
            return "";
    }
};
export const getAppTypeIcon = (appType)=>{
    switch(appType){
        case APP_TYPE_PYROSCOPE:
            return icPyroscope;
        case APP_TYPE_GIT:
            return icGit;
        case APP_TYPE_STATIC:
            return icStatic;
        case APP_TYPE_DOCKER:
            return icDocker;
        case APP_TYPE_DOCKER_COMPOSE:
            return icDockerCompose;
        case APP_TYPE_MINIO:
            return icMinio;
        case APP_TYPE_POSTGRES:
            return isPostgres;
        case APP_TYPE_REDIS:
            return icRedis;
        case APP_TYPE_MONGODB:
            return icMongodb;
        case APP_TYPE_NGINX:
            return icNginx;
        case APP_TYPE_BUILDPACK_CLOJURE:
            return icClojure;
        case APP_TYPE_BUILDPACK_JAVA:
            return icJava;
        case APP_TYPE_BUILDPACK_PYTHON:
            return icPython;
        case APP_TYPE_BUILDPACK_GO:
            return icGo;
        case APP_TYPE_BUILDPACK_SCALA:
            return icScala;
        case APP_TYPE_BUILDPACK_NODEJS:
            return icNodejs;
        case APP_TYPE_BUILDPACK_RUBY:
            return icRuby;
        case APP_TYPE_BUILDPACK_PHP:
            return icPHP;
        case APP_TYPE_BUILDPACK_STATIC:
            return icStatic;
        case APP_TYPE_WORDPRESS:
            return icWordpress;
        case APP_TYPE_GRAFANA:
            return icGrafana;
        case APP_TYPE_JUPYTER:
            return icJupyter;
        case APP_TYPE_PROMETHEUS:
            return icPrometheus;
        case APP_TYPE_NEXT_CLOUD:
            return icNextcloud;
        case APP_TYPE_KIBANA:
            return icKibana;
        case APP_TYPE_ELASTIC_SEARCH:
            return icElasticSearch;
        case APP_TYPE_ROCKETCHAT:
            return icRocketchat;
        case APP_TYPE_JIRA:
            return icJira;
        case APP_TYPE_JIRASM:
            return icJiraSM;
        case APP_TYPE_RABBIT_MQ:
            return icRabbitMq;
        case APP_TYPE_METABASE:
            return icMetabase;
        case APP_TYPE_ARTIFACTORY:
            return icArtifactory;
        case APP_TYPE_GITLAB_RUNNER:
            return icGitlabRunner;
        case APP_TYPE_BUILDPACK_DJANGO:
            return icDjango;
        case APP_TYPE_BUILDPACK_REACT:
            return icReact;
        case APP_TYPE_FILE_UPLOAD:
            return icFileUpload;
        case APP_TYPE_CONFLUENCE:
            return icConfluence;
        case APP_TYPE_MY_SQL:
            return icMysql;
        case APP_TYPE_MARIA_DB:
            return icMariadb;
        case APP_TYPE_MS_SQL:
            return icMssql;
        case APP_TYPE_BUILDPACK_DOTNET_CORE:
            return icDotnetCore;
        case APP_TYPE_KEYCLOAK:
            return icKeycloak;
        case APP_TYPE_KAFKA:
            return icKafka;
        default:
            return icDocker;
    }
};
export const makePortsObject = (ports)=>{
    const portsObject = {};
    R.forEach((port)=>{
        var _portsObject_portName_paas_API_SVC_NODE_PORT_toString_trim;
        const portName = port.name;
        portsObject[portName] = R.omit([
            "name"
        ], port);
        if (isValueHollow(portsObject[portName][paas_API_SVC_NODE_PORT]) || ((_portsObject_portName_paas_API_SVC_NODE_PORT_toString_trim = portsObject[portName][paas_API_SVC_NODE_PORT].toString().trim()) === null || _portsObject_portName_paas_API_SVC_NODE_PORT_toString_trim === void 0 ? void 0 : _portsObject_portName_paas_API_SVC_NODE_PORT_toString_trim.length) <= 0) {
            delete portsObject[portName][paas_API_SVC_NODE_PORT];
        }
        delete portsObject[portName].name;
    }, ports);
    return portsObject;
};
export const makePortsArray = (ports)=>{
    const portsArray = [];
    const portObj = R.clone(ports);
    if (!portObj || Object.keys(portObj) <= 0) {
        return [];
    }
    R.forEach((portName)=>{
        portObj[portName]["name"] = portName;
        portsArray.push(portObj[portName]);
    }, Object.keys(portObj));
    R.forEach((port)=>{
        if (!port[paas_API_SVC_NODE_PORT] || isValueHollow(port[paas_API_SVC_NODE_PORT])) {
            port[paas_API_SVC_NODE_PORT] = " ";
        }
    }, portsArray);
    return portsArray;
};
export const generateCharacters = (length)=>{
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for(var i = 0; i < length; i++){
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};
export const renderCleanRepoName = function(prefixAddress) {
    let config = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const { withGroupName = false, join = null, canSlugify = true } = config;
    const hostAddress = prefixAddress.replace(/http(s)?:\/\//, "");
    let finalAddress = hostAddress.split("/").slice(withGroupName ? -2 : -1);
    finalAddress = join ? finalAddress.join(join) : finalAddress[0].replace(/(\/|_|\.)/g, "-");
    finalAddress = canSlugify ? slugify(finalAddress, {
        replacement: "-",
        lower: true
    }) : finalAddress;
    return finalAddress;
};
export const renderCleanFileName = function(prefixAddress) {
    let config = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const { withGroupName = false, join = null, canSlugify = true } = config;
    const fileName = prefixAddress.replace(/\.zip?/, "");
    let finalName = fileName.split("/").slice(withGroupName ? -2 : -1);
    finalName = join ? finalName.join(join) : finalName[0].replace(/(\/|_|\.)|\(|\)/g, "-");
    finalName = canSlugify ? slugify(finalName, {
        replacement: "-",
        lower: true
    }) : finalName;
    return finalName;
};
