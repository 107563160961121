import { sendRequest } from "#http_requests/reqProxy/reqProxy";
import { userReqs } from "#http_requests/index.reqs";
import useNotificationsStore from "#root/stores/notifications";
import { useQuery } from "@tanstack/react-query";
export const useNotificationsQuery = (props)=>{
    const { setUnreadCount } = useNotificationsStore();
    const getNotifications = async ()=>{
        const res = await sendRequest({
            reqFunc: userReqs.getNotifications,
            payload: {}
        });
        return res === null || res === void 0 ? void 0 : res.data;
    };
    const qGetNotifications = useQuery({
        queryKey: [
            "getNotifications"
        ],
        queryFn: ()=>getNotifications(),
        onSuccess: (notifications)=>{
            var _notifications_results_filter, _notifications_results, _props_onSuccess;
            const unreadCount = notifications === null || notifications === void 0 ? void 0 : (_notifications_results = notifications.results) === null || _notifications_results === void 0 ? void 0 : (_notifications_results_filter = _notifications_results.filter((item)=>item === null || item === void 0 ? void 0 : item.unread)) === null || _notifications_results_filter === void 0 ? void 0 : _notifications_results_filter.length;
            setUnreadCount(unreadCount);
            props === null || props === void 0 ? void 0 : (_props_onSuccess = props.onSuccess) === null || _props_onSuccess === void 0 ? void 0 : _props_onSuccess.call(props, notifications);
        }
    });
    return qGetNotifications;
};
