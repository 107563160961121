import * as paasReqs_DeployContext from "./paas/deployContext.reqs";
import * as paasReqs_AppActions from "./paas/appActions.reqs";
import * as paasReqs_Build from "./paas/build.reqs";
import * as paasReqs_Connection from "./paas/connections.reqs";
import * as paasReqs_Financial from "./paas/financial.reqs";
import * as paasReqs_Hosts from "./paas/hosts.reqs";
import * as paasReqs_Namespace from "./paas/namespace.reqs";
import * as paasReqs_TemplateApps from "./paas/templateApps.reqs";
import * as paasReqs_Terminal from "./paas/terminal.reqs";
import * as paasReqs_Security from "./paas/security.reqs";
import * as paasReqs_LokiLogs from "./paas/logging.reqs";
import * as paasReqs_Permissions from "./paas/permissions.reqs";
import * as paasReqs_Plugins from "./paas/plugins.reqs";
import * as paasReqs_Suggestion from "./paas/suggestions.reqs";
import * as paasReqs_ResourceUsage from "./paas/resourceUsage.reqs";
import * as paasReqs_ImageUpgrade from "./paas/imageUpgrade.reqs";
import * as sentryReqs_SentryActions from "./sentry/sentryActions.reqs";
import * as sentryReqs_Financial from "./sentry/financial.reqs";
import * as viperReqs_Alerts from "./viper/index";
import * as ticketingReqs_TicketActions from "./ticketing/ticketActions.reqs";
import * as ticketingReqs_FeedbackActions from "./ticketing/feedbacks.reqs";
import * as userReqs_AuthActions from "./user/auth.reqs";
import * as userReqs_PermissionsActions from "./user/permissions.reqs";
import * as userReqs_OrganizationActions from "./user/organization.reqs";
import * as userReqs_NotificationActions from "./user/notifications.reqs";
import * as settingsReqs_APIKeyActions from "./settings/apiKey.reqs";
import * as financeReqs_FinanceActions from "./financial/financeActions.reqs";
import * as managedServicesActions from "./managedServices.reqs";
import * as backupActions from "./backup/index.reqs";
import * as monitoringReqs_index from "./monitoring/monitoring.reqs";
import * as containerRegistryReqs_Actions from "./containerRegistry/containerRegistryActions.reqs";
import * as registryGCReqs_Actions from "./containerRegistry/registryGC.reqs";
import * as loadBalancerReqs_Actions from "./loadBalancer/loadBalancer.reqs";
import * as podolicaReqs_Actions from "./podolica/podolica.reqs";
import * as kubernetesReqs_Actions from "./kubernetes/kubernetes.reqs";
import * as pvcReqs_Actions from "./kubernetes/pvc.reqs";
import * as workflowsReqs_Actions from "./kubernetes/workflows.reqs";
import * as storageReqs_Actions from "./storage/storage.reqs";
import * as dbaasReqs_Actions from "./dbaas/dbaas.reqs";
import * as vclusterReqs_Actions from "./vcluster/vcluster.reqs";
export const paasReqs = {
    ...paasReqs_DeployContext,
    ...paasReqs_AppActions,
    ...paasReqs_Build,
    ...paasReqs_Connection,
    ...paasReqs_Financial,
    ...paasReqs_Hosts,
    ...paasReqs_Namespace,
    ...paasReqs_TemplateApps,
    ...paasReqs_Terminal,
    ...paasReqs_Security,
    ...paasReqs_LokiLogs,
    ...paasReqs_Permissions,
    ...paasReqs_Plugins,
    ...paasReqs_Suggestion,
    ...paasReqs_ResourceUsage,
    ...paasReqs_ImageUpgrade
};
export const sentryReqs = {
    ...sentryReqs_SentryActions,
    ...sentryReqs_Financial
};
export const viperReqs = {
    ...viperReqs_Alerts
};
export const ticketingReqs = {
    ...ticketingReqs_TicketActions,
    ...ticketingReqs_FeedbackActions
};
export const userReqs = {
    ...userReqs_AuthActions,
    ...userReqs_PermissionsActions,
    ...userReqs_OrganizationActions,
    ...userReqs_NotificationActions
};
export const settingsReqs = {
    ...settingsReqs_APIKeyActions
};
export const financeReqs = {
    ...financeReqs_FinanceActions
};
export const managedServicesReqs = {
    ...managedServicesActions
};
export const backupReqs = {
    ...backupActions
};
export const monitoringReqs = {
    ...monitoringReqs_index
};
export const containerRegistryReqs = {
    ...containerRegistryReqs_Actions,
    ...registryGCReqs_Actions
};
export const loadBalancerReqs = {
    ...loadBalancerReqs_Actions
};
export const kubernetesReqs = {
    ...kubernetesReqs_Actions,
    ...pvcReqs_Actions,
    ...workflowsReqs_Actions
};
export const storageReqs = {
    ...storageReqs_Actions
};
export const dbaasReqs = {
    ...dbaasReqs_Actions
};
export const vclusterReqs = {
    ...vclusterReqs_Actions
};
export const podolicaReqs = {
    ...podolicaReqs_Actions
};
