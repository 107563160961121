import * as R from "ramda";
import { paas_API_APP_CPU_LIMIT, paas_API_APP_DEPLOY_CONTEXT, paas_API_APP_PLAN, paas_API_APP_RAM_LIMIT, paas_API_APP_STATUS, paas_API_APP_TRIGGER_GOAL, paas_API_APP_TRIGGER_GOAL_UPDATE, paas_API_PLAN_COST_TYPE, paas_API_SVC_INTERNAL_ADDRESS, paas_API_SVC_NODE_PORT, paas_API_SVC_PORTS, paas_API_SVC_SERVICE_PORT, paas_PLAN_COST_TYPE_DYNAMIC } from "../../../consts/paas";
export const formatFinalAppData = (appData, appConfig)=>{
    var _newAppData_paas_API_APP_PLAN, _appConfig_resourceProps;
    let newAppData = R.clone(appData);
    newAppData[paas_API_APP_DEPLOY_CONTEXT] = typeof appData[paas_API_APP_DEPLOY_CONTEXT] == "string" ? appData[paas_API_APP_DEPLOY_CONTEXT] : R.pathOr(null, [
        paas_API_APP_DEPLOY_CONTEXT,
        "id"
    ], appData);
    newAppData === null || newAppData === void 0 ? true : delete newAppData[paas_API_APP_STATUS];
    if ((newAppData === null || newAppData === void 0 ? void 0 : (_newAppData_paas_API_APP_PLAN = newAppData[paas_API_APP_PLAN]) === null || _newAppData_paas_API_APP_PLAN === void 0 ? void 0 : _newAppData_paas_API_APP_PLAN[paas_API_PLAN_COST_TYPE]) !== paas_PLAN_COST_TYPE_DYNAMIC || (appConfig === null || appConfig === void 0 ? void 0 : (_appConfig_resourceProps = appConfig.resourceProps) === null || _appConfig_resourceProps === void 0 ? void 0 : _appConfig_resourceProps.haveMultipleResource)) {
        delete newAppData[paas_API_APP_RAM_LIMIT];
        delete newAppData[paas_API_APP_CPU_LIMIT];
    } else {
        let cpu = newAppData[paas_API_APP_CPU_LIMIT].toString().includes("m") ? newAppData[paas_API_APP_CPU_LIMIT] : newAppData[paas_API_APP_CPU_LIMIT] + "m";
        let ram = newAppData[paas_API_APP_RAM_LIMIT].toString().includes("M") ? newAppData[paas_API_APP_RAM_LIMIT] : newAppData[paas_API_APP_RAM_LIMIT] + "M";
        newAppData = {
            ...newAppData,
            [paas_API_APP_CPU_LIMIT]: cpu,
            [paas_API_APP_RAM_LIMIT]: ram
        };
    }
    newAppData[paas_API_APP_TRIGGER_GOAL] = paas_API_APP_TRIGGER_GOAL_UPDATE;
    newAppData[paas_API_APP_PLAN] = R.pathOr(null, [
        paas_API_APP_PLAN,
        "id"
    ], appData);
    return newAppData;
};
export const getPortWithAddress = function(dataSVC, fieldType, appType) {
    let portSplitter = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : ":";
    const portKey = fieldType === paas_API_SVC_INTERNAL_ADDRESS ? paas_API_SVC_SERVICE_PORT : paas_API_SVC_NODE_PORT;
    const port = R.pathOr("", [
        paas_API_SVC_PORTS,
        appType,
        portKey
    ], dataSVC) || R.pathOr("", [
        paas_API_SVC_PORTS,
        "main",
        portKey
    ], dataSVC) || R.pathOr("", [
        paas_API_SVC_PORTS,
        "http",
        portKey
    ], dataSVC) || null;
    return port && dataSVC[fieldType] ? dataSVC[fieldType] + portSplitter + port : null;
};
