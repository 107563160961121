// TODO: This is a temp file, and soon will be replaced with new brand system.
import Brand from "./BrandAssets";
export const getFeature = (feature)=>{
    try {
        return Brand.features[feature] === undefined ? true : Brand.features[feature];
    } catch (error) {
        // This works when no rule is already defined for displaying the feature or not.
        return true;
    }
};
export const getAsset = (asset)=>{
    try {
        return Brand.assets[asset];
    } catch (error) {
        // This works when no rule is already defined for displaying the feature or not.
        return null;
    }
};
export const getDateFormat = ()=>{
    try {
        return Brand.dateFormat;
    } catch (err) {
        return null;
    }
};
export const getHourFormat = ()=>{
    try {
        return Brand.hourFormat;
    } catch (err) {
        return null;
    }
};
export const getCountryCode = ()=>{
    return (Brand === null || Brand === void 0 ? void 0 : Brand.countryCode) ?? "";
};
export const getOfficialCode = ()=>{
    try {
        return Brand.officialCode;
    } catch (err) {
        return null;
    }
};
