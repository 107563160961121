import { paas_API_APP_CREATION_METHOD, paas_API_APP_CUSTOM_SUBDOMAIN_ADDRESS, paas_API_APP_ENABLE_SSL, paas_API_APP_EXTERNAL_HOSTS } from "../../../consts/paas";
import * as R from "ramda";
import { getAppConfig } from "../appsConfig";
import { isValueHollow } from "../../../helpers/validator";
import merge from "deepmerge";
import { getTemplateAppField } from "./templateApps";
export const getInitValue = (data, fieldName, defValue)=>{
    return data && !isValueHollow(data === null || data === void 0 ? void 0 : data[fieldName]) ? data[fieldName] : defValue;
};
export const getAppHosts = (appData, config)=>{
    var _appConfig_hosts, _config_hostList;
    const defConfig = {
        showProtocol: true
    };
    config = merge(defConfig, config ?? {});
    //config: replaceTemplateField, hostList, showProtocol
    const appConfig = getAppConfig(appData === null || appData === void 0 ? void 0 : appData[paas_API_APP_CREATION_METHOD]);
    const finalHosts = [];
    const replaceTemplateField = !R.isNil(config === null || config === void 0 ? void 0 : config.replaceTemplateField) ? config === null || config === void 0 ? void 0 : config.replaceTemplateField : !!(appConfig === null || appConfig === void 0 ? void 0 : (_appConfig_hosts = appConfig.hosts) === null || _appConfig_hosts === void 0 ? void 0 : _appConfig_hosts.replaceTemplateField);
    const externalHosts = appData === null || appData === void 0 ? void 0 : appData[paas_API_APP_EXTERNAL_HOSTS];
    const customSubdomain = appData === null || appData === void 0 ? void 0 : appData[paas_API_APP_CUSTOM_SUBDOMAIN_ADDRESS];
    const sslEnabled = (appData === null || appData === void 0 ? void 0 : appData[paas_API_APP_ENABLE_SSL]) && (config === null || config === void 0 ? void 0 : config.showProtocol);
    if ((config === null || config === void 0 ? void 0 : config.hostList) && (config === null || config === void 0 ? void 0 : (_config_hostList = config.hostList) === null || _config_hostList === void 0 ? void 0 : _config_hostList.length) > 0) {
        var _appConfig_hosts1;
        const hostList = config === null || config === void 0 ? void 0 : config.hostList;
        const pattern = appConfig === null || appConfig === void 0 ? void 0 : (_appConfig_hosts1 = appConfig.hosts) === null || _appConfig_hosts1 === void 0 ? void 0 : _appConfig_hosts1.urlWithPattern;
        if (pattern) {
            var _hostList_, _hostList_1, _hostList_2;
            const firstHostDot = hostList[0].indexOf(".");
            const firstHost = `${hostList[0].substr(0, firstHostDot > 0 ? firstHostDot : (_hostList_ = hostList[0]) === null || _hostList_ === void 0 ? void 0 : _hostList_.length)}${pattern}${hostList[0].substr(firstHostDot > 0 ? firstHostDot : (_hostList_1 = hostList[0]) === null || _hostList_1 === void 0 ? void 0 : _hostList_1.length, (_hostList_2 = hostList[0]) === null || _hostList_2 === void 0 ? void 0 : _hostList_2.length)}`;
            finalHosts.push(`${sslEnabled ? "https:" : "http:"}//${firstHost}`);
        }
        finalHosts.push(...hostList);
    } else {
        var _appConfig_hosts2;
        if (appConfig === null || appConfig === void 0 ? void 0 : (_appConfig_hosts2 = appConfig.hosts) === null || _appConfig_hosts2 === void 0 ? void 0 : _appConfig_hosts2.templateField) {
            appConfig.hosts.templateField.forEach((templateField)=>{
                const field = getTemplateAppField(appData, templateField);
                if (field && (field === null || field === void 0 ? void 0 : field.value)) {
                    finalHosts.push(`${sslEnabled ? "https:" : "http:"}//${field.value}`);
                }
            });
        }
        if (!replaceTemplateField) {
            if (externalHosts && (externalHosts === null || externalHosts === void 0 ? void 0 : externalHosts.length) > 0) {
                externalHosts === null || externalHosts === void 0 ? void 0 : externalHosts.forEach((host)=>{
                    finalHosts.push(`${sslEnabled ? "https:" : "http:"}//${host}`);
                });
            }
            if (customSubdomain) {
                finalHosts.push(`${sslEnabled ? "https:" : "http:"}//${customSubdomain}`);
            }
        }
    }
    return finalHosts;
};
export const getCustomConfigImages = (appData)=>{
    var _appConfig_imageList;
    const appConfig = getAppConfig(appData[paas_API_APP_CREATION_METHOD]);
    const images = {};
    appConfig === null || appConfig === void 0 ? void 0 : (_appConfig_imageList = appConfig.imageList) === null || _appConfig_imageList === void 0 ? void 0 : _appConfig_imageList.forEach((imageName)=>{
        var _appData_custom_config_imageName;
        images[imageName] = (_appData_custom_config_imageName = appData.custom_config[imageName]) === null || _appData_custom_config_imageName === void 0 ? void 0 : _appData_custom_config_imageName.image;
    });
    return images;
};
