import { getBrand } from "#i18n/BrandMethods";
import tolgee from "#i18n/config";
import * as R from "ramda";
import Mailcheck from "mailcheck";
import jsYaml from "js-yaml";
export const REGEX_ORG_NAME = /^[a-z]([-a-z0-9]*[a-z0-9])?$/;
// eslint-disable-next-line
export const REGEX_EMAIL = // eslint-disable-next-line no-useless-escape
/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_USERNAME = /^(?=[a-z0-9.-]{4,25}$)(?!.*[.-]{2})[^.-].*[^.-]$/;
// eslint-disable-next-line no-misleading-character-class, regex/invalid
export const PERSIAN_LETTERS_REGEX = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤآإأءًٌٍَُِّ]/g;
export const isValueHollow = (value)=>{
    //!DONT CHECK FALSE VALUES
    return R.isEmpty(value) || R.isNil(value);
};
// validate array and check elements number minimum (default is 0)
export const hasArrayLength = function(array) {
    let minElements = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
    return !isValueHollow(array) && !!(array === null || array === void 0 ? void 0 : array.length) && array.length >= minElements;
};
export const checkEmailmMisspelling = (value)=>{
    const domains = [
        "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "yandex.com",
        "vandar.io"
    ];
    const secondLevelDomains = [
        "hotmail",
        "gmail",
        "yahoo",
        "yandex",
        `${getBrand()}`
    ];
    const topLevelDomains = [
        "app"
    ];
    const result = Mailcheck.run({
        email: value,
        domains,
        secondLevelDomains: secondLevelDomains,
        topLevelDomains: topLevelDomains
    });
    return result && result.full !== value ? {
        status: false,
        data: result === null || result === void 0 ? void 0 : result.full
    } : {
        status: true,
        data: null
    };
};
export const checkEmailRole = (value)=>{
    //Returns an object with to keys:
    // validateStatus: error | warning | undefined
    //help: error ? message : undefined
    let result;
    if (!REGEX_EMAIL.test(value)) {
        result = {
            validateStatus: "error",
            help: tolgee.t({
                ns: "common",
                key: "message.invalid_email"
            })
        };
    } else {
        const emailSpell = checkEmailmMisspelling(value);
        if (emailSpell.status == false) {
            result = {
                validateStatus: "warning",
                help: tolgee.t({
                    ns: "auth",
                    key: "description.email_missspelling"
                }) + emailSpell.data
            };
        } else {
            result = {
                validateStatus: undefined,
                help: undefined
            };
        }
    }
    return result;
};
export const checkOrgName = function() {
    let name = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", allowEmpty = arguments.length > 1 ? arguments[1] : void 0;
    let isValid = name.match(REGEX_ORG_NAME);
    if (!isValueHollow(name) && name[name.length - 1] === "-") {
        isValid = false;
        return {
            isValid,
            message: tolgee.t({
                ns: "common",
                key: "message.org_name_underline"
            })
        };
    }
    if (isValid && isValid[0] === name) {
        isValid = true;
    } else {
        isValid = false;
    }
    if (allowEmpty && isValueHollow(name)) {
        isValid = true;
    }
    return {
        isValid,
        message: tolgee.t({
            ns: "common",
            key: "message.org_name_characters"
        })
    };
};
export const isValidYaml = (yaml)=>{
    try {
        const validationStatus = jsYaml.load(yaml);
        if (!isValueHollow(yaml) && typeof validationStatus !== "object") {
            return {
                isValid: false,
                reason: "yaml error"
            };
        }
        return {
            isValid: true,
            reason: null
        };
    } catch (reason) {
        return {
            isValid: false,
            reason
        };
    }
};
export function isJSON(str) {
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
}
