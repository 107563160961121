import { hasRole, user_API_ORGANIZATION_ROLES_ADMIN, user_API_ORGANIZATION_ROLES_CRE, user_API_ORGANIZATION_ROLES_OWNER } from "#root/consts/auth";
export const isAclPowerUser = (roles, selectedOrganization)=>{
    return !(selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.is_acl_enabled) || isOrgOwner(roles, selectedOrganization) || isPoweredUser(roles, selectedOrganization);
};
export const isOrgOwner = (roles, selectedOrganization)=>{
    const orgName = selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.name;
    if (!roles || roles && Object.keys(roles).length <= 0) {
        return false;
    }
    const orgRoles = !roles[orgName] ? {
        [orgName]: roles
    } : roles;
    return hasRole([
        user_API_ORGANIZATION_ROLES_OWNER
    ], orgRoles, selectedOrganization);
};
export const isPoweredUser = (roles, selectedOrganization)=>{
    const orgName = selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.name;
    if (!roles || roles && Object.keys(roles).length <= 0) {
        return false;
    }
    const orgRoles = !roles[orgName] ? {
        [orgName]: roles
    } : roles;
    return hasRole([
        user_API_ORGANIZATION_ROLES_ADMIN,
        user_API_ORGANIZATION_ROLES_CRE
    ], orgRoles, selectedOrganization);
};
export const isCreUser = (roles, selectedOrganization)=>{
    const orgName = selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.name;
    const orgRoles = !(roles === null || roles === void 0 ? void 0 : roles[orgName]) ? {
        [orgName]: roles
    } : roles;
    return hasRole([
        user_API_ORGANIZATION_ROLES_CRE
    ], orgRoles, selectedOrganization);
};
export const isAdminUser = (roles, selectedOrganization)=>{
    const orgName = selectedOrganization.name;
    const orgRoles = !roles[orgName] ? {
        [orgName]: roles
    } : roles;
    return hasRole([
        user_API_ORGANIZATION_ROLES_CRE
    ], orgRoles, selectedOrganization);
};
