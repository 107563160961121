import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getBackups = (param)=>{
    let { type, sync, fields } = param;
    const keys = fields.toString();
    const url = new URL(`${hostServer}/api/v1/backups/backups?fields=${keys}${type ? `&kind=${type}` : ""}`);
    type && url.searchParams.set("kind", type);
    sync && url.searchParams.set("sync", sync.toString());
    return sendRequest(()=>axios.get(url === null || url === void 0 ? void 0 : url.href, config()), {
        setNotif: false
    });
};
export const getBackupHistory = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/backups/${id}/snapshots`, config()));
};
export const triggerBackup = (param)=>{
    let { id } = param;
    const data = {
        backup_id: id.toString()
    };
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/backups/backups/${data === null || data === void 0 ? void 0 : data.backup_id}/trigger/`, null, config()));
};
export const getStorageDestinationDetails = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/storage_destinations/`, config()));
};
export const getStorageDestinationMetrics = (storageID)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/storage_destinations/${storageID}/metrics/`, config()), {
        setNotif: false
    });
};
export const restoreSnapshots = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/backups/snapshots/${id}/restore/`, {}, config()));
};
export const updateBackup = (data)=>{
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/backups/backups/${data === null || data === void 0 ? void 0 : data.backup_id}`, data, config()));
};
export const getBackupSnapshots = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/backups/${id}/snapshots/`, config()));
};
export const getBackupRestoresHistory = (backupID)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/backups/${backupID}/restores/`, config()));
};
export const getBackupCloneHistory = (backupID)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/backups/${backupID}/clones/`, config()));
};
export const restoreSnapshot = (snapshotID)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/backups/snapshots/${snapshotID}/restore/`, {}, config()));
};
export const cloneAppSnapshot = (backupID, nsID)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/backups/snapshots/${backupID}/clone/`, {
            dst_namespace_id: nsID
        }, config()));
};
export const getSnapshotFileBrowser = (snapshotID)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/snapshots/${snapshotID}/url/`, config()));
};
export const createBackup = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/backups/backups`, data, config()));
};
export const updateStorageDestination = function() {
    let data = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        id: null,
        size: null,
        state: null
    };
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/backups/storage_destinations/${data === null || data === void 0 ? void 0 : data.id}/`, {
            size: data === null || data === void 0 ? void 0 : data.size,
            state: data === null || data === void 0 ? void 0 : data.state
        }, config()));
};
export const getAppBackups = (param)=>{
    let { appID } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/darkube/apps/${appID}/app_pvc_backups/`, config()));
};
export const getOrgPlans = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/org_plans`, config()));
};
export const getPrices = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/backups/prices`, config()));
};
export const changePassword = (destinationId, data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/backups/storage_destinations/${destinationId}/change_password/`, data, config()));
};
export const getVaultSecret = (param)=>{
    let { storageID } = param;
    return makeAxios({
        libConfig: {
            method: "POST",
            url: `${hostServer}/api/v1/backups/storage_destinations/${storageID}/read_vault_secret/`,
            data: {
                secret_fields: [
                    "vault_password"
                ]
            }
        }
    });
};
