import { backinghamPermissions } from "../permissions/backingham";
import { containerRegistryPermissions } from "../permissions/containerRegistry";
import { logAggregationPermissions } from "../permissions/logAggregation";
import { monitoringPermissions } from "../permissions/monitoring";
import { sentryPermissions } from "../permissions/sentry";
import { ticketingPermissions } from "../permissions/ticketing";
import { financialPermissions } from "../permissions/financial";
import { loadBalancerPermissions } from "../permissions/loadBalancer";
import { kubernetesPermissions } from "../permissions/kubernetes";
import { generalPermissions } from "#root/permissions/general";
import { paasPermissions } from "#root/permissions/paas";
import { vclusterPermissions } from "#root/permissions/vcluster";
import { storagePermissions } from "#root/permissions/storage";
import { DBaaSPermissions } from "#root/permissions/dbaas";
import { podolicaPermissions } from "#root/permissions/podolica";
import { gymPermissions } from "#root/permissions/gym";
import useAuthStore from "#root/stores/auth";
import { user_API_ORGANIZATION_ROLES } from "#consts/auth";
var PermissionsList;
(function(PermissionsList) {
    PermissionsList["general"] = "general";
    PermissionsList["containerRegistry"] = "containerRegistry";
    PermissionsList["sentry"] = "sentry";
    PermissionsList["logAggregation"] = "log_aggregation";
    PermissionsList["monitoring"] = "monitoring";
    PermissionsList["backup"] = "backingham";
    PermissionsList["ticketing"] = "ticketing";
    PermissionsList["financial"] = "financial";
    PermissionsList["loadBalancer"] = "load-balancer";
    PermissionsList["kubernetes"] = "kubernetes";
    PermissionsList["paas"] = "paas";
    PermissionsList["vcluster"] = "vcluster";
    PermissionsList["storage"] = "storage";
    PermissionsList["dbaas"] = "dbaas";
    PermissionsList["podolica"] = "podolica";
    PermissionsList["gym"] = "gym";
})(PermissionsList || (PermissionsList = {}));
const usePermissions = function() {
    let config = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : undefined;
    const props = {
        selectedOrganization: useAuthStore((store)=>store.selectedOrganization),
        userRoles: useAuthStore((store)=>store[user_API_ORGANIZATION_ROLES]),
        isVerified: useAuthStore((store)=>store.is_verified),
        organizations: useAuthStore((store)=>store.organizations),
        email: useAuthStore((store)=>store.email),
        userPermissions: useAuthStore((store)=>store === null || store === void 0 ? void 0 : store.userPermissions)
    };
    const permissionsCombiner = {
        ["general"]: generalPermissions(props),
        ["containerRegistry"]: containerRegistryPermissions(props),
        ["sentry"]: sentryPermissions(props),
        ["log_aggregation"]: logAggregationPermissions(props, config),
        ["monitoring"]: monitoringPermissions(props, config),
        ["backingham"]: backinghamPermissions(props),
        ["ticketing"]: ticketingPermissions(props),
        ["financial"]: financialPermissions(props),
        ["load-balancer"]: loadBalancerPermissions(props),
        ["kubernetes"]: kubernetesPermissions(props),
        ["paas"]: paasPermissions(props),
        ["vcluster"]: vclusterPermissions(props),
        ["storage"]: storagePermissions(props),
        ["dbaas"]: DBaaSPermissions(props),
        ["podolica"]: podolicaPermissions(props),
        ["gym"]: gymPermissions(props)
    };
    return permissionsCombiner;
};
export default usePermissions;
