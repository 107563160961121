import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { paas_API_APP_BUILD_OPTIONS, paas_API_APP_CLUSTER, paas_API_APP_CPU_LIMIT, paas_API_APP_CREATION_METHOD, paas_API_APP_CUSTOM_SUBDOMAIN_ADDRESS, paas_API_APP_ENABLE_SSL, paas_API_APP_EXTERNAL_HOSTS, paas_API_APP_ID, paas_API_APP_IS_DEPLOYABLE, paas_API_APP_NAME, paas_API_APP_NAMESPACE, paas_API_APP_RAM_LIMIT, paas_API_APP_STATUS, paas_API_APP_IS_ENABLED, paas_API_APP_IS_HPA_ENABLED, paas_API_APP_REPLICAS, paas_API_APP_CUSTOM_SUBDOMAIN_ADDR, paas_API_APP_LATEST_BUILD, paas_API_APP_CUSTOM_CONFIG, paas_API_APP_PLAN, paas_API_APP_TEMPLATE_FIELDS, paas_API_APP_DISKS, paas_API_APP_DATABASE_PROPERTIES } from "../../consts/paas";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const statelessApps = ()=>{
    const fields = `${paas_API_APP_ID},` + `${paas_API_APP_NAMESPACE},` + `${paas_API_APP_CLUSTER},` + `${paas_API_APP_STATUS},` + `${paas_API_APP_NAME},` + `${paas_API_APP_CREATION_METHOD},` + `${paas_API_APP_BUILD_OPTIONS},` + `${paas_API_APP_CUSTOM_SUBDOMAIN_ADDRESS},` + `${paas_API_APP_EXTERNAL_HOSTS},` + `${paas_API_APP_RAM_LIMIT},` + `${paas_API_APP_CPU_LIMIT},` + `${paas_API_APP_IS_DEPLOYABLE},` + `${paas_API_APP_IS_HPA_ENABLED},` + `${paas_API_APP_IS_ENABLED},` + `${paas_API_APP_REPLICAS},` + `${paas_API_APP_CUSTOM_SUBDOMAIN_ADDR},` + `${paas_API_APP_PLAN},` + `${paas_API_APP_TEMPLATE_FIELDS},` + `${paas_API_APP_CUSTOM_CONFIG},` + `${paas_API_APP_CUSTOM_SUBDOMAIN_ADDR},` + `${paas_API_APP_DISKS},` + `${paas_API_APP_ENABLE_SSL},` + `${paas_API_APP_DATABASE_PROPERTIES},` + `${paas_API_APP_LATEST_BUILD}`;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/apps/?limit=400&offset=0&fields=${fields}`, config()), {
        setNotif: false
    });
};
export const getStatelessApp = (param)=>{
    let { appID } = param;
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${hostServer}/api/v1/${paasUrl}/apps/${appID}/`
        },
        proxyConfig: {
            showNotification: false
        }
    });
};
export const getAppManifests = (param)=>{
    let { appId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/manifests/`, {
            ...config(),
            responseType: "blob"
        }));
};
export const createStatelessApps = (param)=>{
    let { appConfig } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/`, appConfig, config()));
};
export const updateStatelessApps = (param)=>{
    let { appId, appConfig } = param;
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/`, appConfig, config()));
};
export const deleteStatelessApps = (param)=>{
    let { appId } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/`, config()));
};
export const UploadAppFiles = async (param)=>{
    let { uploadedFile, appId, fileUploadStatusHandler } = param;
    return await axios.post(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/app_files/`, uploadedFile, {
        headers: {
            "Content-Disposition": "attachment; filename=app.zip;",
            "Content-Type": "application/zip",
            ...config().headers
        },
        onUploadProgress: fileUploadStatusHandler
    });
};
export const getAppHistory = (param)=>{
    let { appId, start_time = null, end_time = null } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/stateless_apps/${appId}/history/?start_time=${start_time}&end_time=${end_time}`, config()));
};
export const getGitlabCIContent = (param)=>{
    let { appId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/stateless_apps/${appId}/generate_gitlabci_jobs/`, config()));
};
export const getKubeCtlConfig = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/kubeconfig/generate/`, {
            ...config(),
            responseType: "arraybuffer"
        }));
};
export const getContainers = (param)=>{
    let { appId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/app_containers/`, config()));
};
export const getContainerPodLog = function(param) {
    let { appId, containerName, podName, from = 20000000, to = 20000100, reference = 0, previous } = param, clientConfig = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/apps/${appId}/app_log/?from_index=${from}&to_index=${to}&reference_index=${reference}&pod_name=${podName}&container_name=${containerName}&previous=${previous}`, config()), clientConfig);
};
export const getDockerComposeApps = (yaml)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/docker_compose/`, yaml, {
            headers: {
                "Content-Type": "application/yaml",
                ...config().headers
            }
        }));
};
export const getAppScheduleInfo = (data)=>{
    if (!(data === null || data === void 0 ? void 0 : data.custom_config)) {
        data === null || data === void 0 ? true : delete data.custom_config;
    }
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/schedule_info/`, data, config()), {
        setNotif: false
    });
};
export const migrateApp = (appID, data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/${appID}/migrate/`, data, config()));
};
export const reinstallApp = (appID)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/${appID}/reinstall/`, {}, config()));
};
export const restartApp = (appID)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/apps/${appID}/restart/`, {}, config()));
};
export const syncDiskData = (data)=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/stateless_apps/sync_apps_data/`, {
            ...data
        }, config()));
};
export const getAppSecret = (data)=>{
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${hostServer}/api/v1/${paasUrl}/stateless_apps/${data.appID}}/secret/?secret_fields=${data.secretType}`
        }
    });
};
export const getAppOperations = (param)=>{
    let { appID } = param;
    return makeAxios({
        libConfig: {
            url: `${hostServer}/api/v1/${paasUrl}/apps/${appID}/operations/?type=version_upgrade&type=version_rollback`,
            method: "GET"
        }
    });
};
export const toggleAppPower = (param)=>{
    let { appID, enabled } = param;
    return makeAxios({
        libConfig: {
            method: "PATCH",
            url: `${hostServer}/api/v1/${paasUrl}/apps/${appID}/`,
            data: {
                fields: [
                    "is_enabled"
                ],
                is_enabled: enabled
            }
        }
    });
};
