import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getDataSource = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/dazzle/metric-datasources/`, config()));
};
export const getDataSourcePlans = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/dazzle/metric-datasource-plans/`, config()));
};
export const activeMonitoring = ()=>{
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/dazzle/metric-datasources/`, {}, config()));
};
export const deleteMonitoring = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/dazzle/metric-datasources/${id}/`, config()));
};
export const getVaultSecret = (param)=>{
    let { datasourceID } = param;
    return makeAxios({
        libConfig: {
            method: "POST",
            url: `${hostServer}/api/v1/dazzle/metric-datasources/${datasourceID}/read_vault_secret/`,
            data: {
                secret_fields: [
                    "vault_password"
                ]
            }
        }
    });
};
