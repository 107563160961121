import { saveAs } from "file-saver";
import { vclusterServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getClusters = ()=>{
    return sendRequest(()=>axios.get(`${vclusterServer}/cluster/all-clusters`, config()));
};
export const getClusterData = (clusterName)=>{
    return sendRequest(()=>axios.get(`${vclusterServer}/cluster?cluster_name=${clusterName}`, config()));
};
export const getClusterAddOns = (clusterName)=>{
    return sendRequest(()=>axios.get(`${vclusterServer}/cluster/add-on?cluster_name=${clusterName}`, config()));
};
export const deleteCluster = (clusterName)=>{
    return sendRequest(()=>axios.delete(`${vclusterServer}/cluster/delete?cluster_name=${clusterName}`, config()));
};
export const downloadKubeconfig = async (clusterName)=>{
    const res = (await sendRequest(()=>axios.get(`${vclusterServer}/cluster/config?cluster_name=${clusterName}`, config()))).data;
    if (res) {
        let blob = new Blob([
            res
        ], {
            type: "text/plain"
        });
        let fileName = `${clusterName}.yaml`;
        saveAs(blob, fileName);
    }
};
export const addNodePoolToCluster = (clusterName, nodePool)=>{
    const body = {
        cluster_name: clusterName,
        node_pool: nodePool
    };
    return sendRequest(()=>axios.post(`${vclusterServer}/cluster/add-node-pool`, body, config()));
};
export const resizeVCluster = (cluster_name, cpu, memory, disk)=>{
    const body = {
        cluster_name,
        cpu,
        memory,
        disk
    };
    return sendRequest(()=>axios.post(`${vclusterServer}/cluster/resize-vcluster`, body, config()));
};
export const resizeNodePool = (clusterName, nodePool)=>{
    const body = {
        cluster_name: clusterName,
        node_pool: nodePool
    };
    return sendRequest(()=>axios.post(`${vclusterServer}/cluster/resize-node-pool`, body, config()));
};
export const deleteNodePool = (clusterName, nodePoolId)=>{
    return sendRequest(()=>axios.delete(`${vclusterServer}/cluster/delete-node-pool?cluster_name=${clusterName}&node_pool_id=${nodePoolId}`, config()));
};
export const deleteNode = (clusterName, nodeId)=>{
    return sendRequest(()=>axios.delete(`${vclusterServer}/cluster/delete-node?cluster_name=${clusterName}&node_id=${nodeId}`, config()));
};
export const createCluster = (values)=>{
    const body = {
        cluster_name: values.clusterName,
        node_pools: values.nodePools,
        region: values.region,
        cluster_type: values.clusterType,
        memory: values.memory,
        cpu: values.cpu,
        disk: values.disk,
        version: values.version
    };
    return sendRequest(()=>axios.post(`${vclusterServer}/cluster/init`, body, config()));
};
export const getNodeTypes = ()=>{
    return sendRequest(()=>axios.get(`${vclusterServer}/cluster/node-types`, config()));
};
export const getRegions = ()=>{
    return sendRequest(()=>axios.get(`${vclusterServer}/cluster/regions`, config()));
};
export const getClusterPrice = (data)=>{
    const price = sendRequest(()=>axios.post(`${vclusterServer}/cluster/price`, data, config()));
    return price;
};
export const installAddOn = (clusterName, addOnName)=>{
    const body = {
        cluster_name: clusterName,
        add_on_name: addOnName
    };
    return sendRequest(()=>axios.post(`${vclusterServer}/cluster/add-on/install`, body, config()));
};
export const auth = ()=>{
    return sendRequest(()=>axios.get(`${vclusterServer}/user`, config()));
};
