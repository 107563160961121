import { DevTools, Tolgee } from "@tolgee/react";
import { FormatIcu } from "@tolgee/format-icu";
import Cookies from "js-cookie";
import getStatics from "./getStatics";
// import * as Sentry from "@sentry/react";
const isStaging = process.env.NEXT_PUBLIC_APP_ENV === "staging";
const isClient = typeof window !== "undefined";
const tolgee = Tolgee().use(DevTools()).use(FormatIcu()).init({
    apiKey: process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
    apiUrl: process.env.NEXT_PUBLIC_TOLGEE_API_URL,
    availableLanguages: process.env.NEXT_PUBLIC_LANGS.split(","),
    language: Cookies.get("lang") || process.env.NEXT_PUBLIC_DEFAULT_LANG,
    staticData: getStatics(),
    ns: [
        `brand-${process.env.NEXT_PUBLIC_BRAND_NAME}`,
        "common",
        "auth",
        "paas",
        "backingham",
        "financial",
        "monitoring",
        "log",
        "sentry",
        "ticket",
        "registry",
        "load-balancer",
        "managed-services",
        "storage",
        "vcluster",
        "dbaas",
        "hints",
        "gym"
    ],
    onFormatError: (error, info)=>{
        if (isClient) {
            const lang = Cookies.get("lang");
            const namespaceKey = `${(info === null || info === void 0 ? void 0 : info.ns) ? (info === null || info === void 0 ? void 0 : info.ns) + ":" : null}${info === null || info === void 0 ? void 0 : info.key}`;
            const errorTitle = `[i18n][${lang || "unknown language"}] Format error: ${namespaceKey}`;
            isStaging && console.log(errorTitle, {
                error,
                ...info
            });
        }
        return info === null || info === void 0 ? void 0 : info.key;
    },
    onTranslationMissing: (info)=>{
        if (isClient) {
            const lang = Cookies.get("lang");
            const namespaceKey = `${(info === null || info === void 0 ? void 0 : info.ns) ? (info === null || info === void 0 ? void 0 : info.ns) + ":" : null}${info === null || info === void 0 ? void 0 : info.key}`;
            const errorTitle = `[i18n][${lang || "unknown language"}] Missing translation: ${namespaceKey}`;
            isStaging && console.log(errorTitle, info);
        }
        return info === null || info === void 0 ? void 0 : info.key;
    }
});
export default tolgee;
