import axios from "axios";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, paasUrl, sendRequest } from "../axios";
export const getChartData = (props)=>{
    const url = new URL(`${hostServer}/api/v1/${paasUrl}/insights/proxy_new/api/v1/query_range`);
    Object.keys(props).map((key)=>{
        url.searchParams.set(key, props[key]);
    });
    return sendRequest(()=>axios.get(url.href, config()));
};
