import { paas_API_APP_SERVICE_TYPE_CLUSTER_IP, paas_API_APP_SERVICE_TYPE_LOAD_BALANCER, paas_API_APP_TEMPLATE_FIELDS } from "#consts/paas";
import * as R from "ramda";
export const getter_field_templateServiceType = (data)=>{
    return data === paas_API_APP_SERVICE_TYPE_LOAD_BALANCER;
};
export const getTemplateAppField = (appData, fieldName)=>{
    const dataTemplateFields = R.pathOr(null, [
        paas_API_APP_TEMPLATE_FIELDS
    ], appData);
    let foundField;
    if (dataTemplateFields && dataTemplateFields.length > 0) {
        foundField = R.find(R.propEq("name", fieldName))(dataTemplateFields);
    }
    return foundField;
};
export const setter_field_templateServiceType = (data)=>{
    return data ? paas_API_APP_SERVICE_TYPE_LOAD_BALANCER : paas_API_APP_SERVICE_TYPE_CLUSTER_IP;
};
