/* eslint-disable import/no-unresolved */ import brandLogo from "../assets/images/brand/logo.svg";
import localGitBG from "../assets/images/banner-local-git-bg.png";
import paasBG from "../assets/images/banner-paas-bg.png";
import icBackup from "../assets/images/ic-backup.svg";
import icMonitoring from "../assets/images/ic-monitoring.svg";
import icMonitoringColored from "../assets/images/ic-monitoring-colored.svg";
import icMinio from "../assets/images/ic-minio-lblue.svg";
import icPostgres from "../assets/images/ic-postgres.svg";
import icMongo from "../assets/images/ic-mongo.svg";
import icRedis from "../assets/images/ic-redis-lblue.svg";
import icMysql from "../assets/images/ic-mysql.svg";
import paasIcon from "../assets/images/brand/paas_logo.svg";
// import // getApiURL,
// // getPanelURL,
// // getWSURL,
// "./BrandSelector";
import { APP_TYPE_BUILDPACK_CLOJURE, APP_TYPE_BUILDPACK_DJANGO, APP_TYPE_BUILDPACK_DOTNET_CORE, APP_TYPE_BUILDPACK_GO, APP_TYPE_BUILDPACK_JAVA, APP_TYPE_BUILDPACK_NODEJS, APP_TYPE_BUILDPACK_PHP, APP_TYPE_BUILDPACK_PYTHON, APP_TYPE_BUILDPACK_REACT, APP_TYPE_BUILDPACK_RUBY, APP_TYPE_BUILDPACK_SCALA, APP_TYPE_DOCKER, APP_TYPE_DOCKER_COMPOSE, APP_TYPE_ELASTIC_SEARCH, APP_TYPE_GIT, APP_TYPE_GRAFANA, APP_TYPE_JUPYTER, APP_TYPE_KIBANA, APP_TYPE_MARIA_DB, APP_TYPE_METABASE, APP_TYPE_MINIO, APP_TYPE_MONGODB, APP_TYPE_MS_SQL, APP_TYPE_MY_SQL, APP_TYPE_NEXT_CLOUD, APP_TYPE_NGINX, APP_TYPE_POSTGRES, APP_TYPE_POSTGRES_AIO, APP_TYPE_PROMETHEUS, APP_TYPE_RABBIT_MQ, APP_TYPE_REDIS, APP_TYPE_ROCKETCHAT, APP_TYPE_WORDPRESS, APP_TYPE_GITLAB_RUNNER, APP_TYPE_MINIO_NEW } from "../consts/paas";
const bannerUpdamus = "";
const paasDark = "";
const addedTaxCertificate = "";
const outlinedLogo = "";
const coloredOutlinedLogo = "";
const assets = {
    name: "Hectora",
    tagManagerCode: "GTM-K7DKBD4",
    locales: [
        "en"
    ],
    features: {
        //User
        showCrispAfterLogin: true,
        profilePhoneNumber: false,
        profileNationalCode: false,
        canSignup: true,
        ssoLogin: false,
        isCaptchaEnabled: false && process.env.NEXT_PUBLIC_APP_ENV === "prod",
        errorTracker: false,
        changeLanguage: false,
        phoneNumberPrettier: false,
        //Big features
        viperDisplay: true,
        containerRegistryDisplay: false,
        containerRegistryGC: process.env.NEXT_PUBLIC_APP_ENV === "staging",
        AIOsDisplay: false,
        plansDisplay: true,
        manageOrganization: true,
        canCreateTemplatePackApps: true,
        canCreateDatabaseApps: true,
        canCreateBuildPackApps: true,
        canUploadFile: false,
        canUsePaasGitlab: false,
        sentryDisplay: false,
        backupServiceDisplay: true,
        externalServicesDisplay: false,
        hasAnalyticsTracker: false,
        hasTagManager: true,
        ticketingDisplay: false,
        forgetPassDisplay: true,
        logAggregation: false,
        docs: false,
        //Organization
        orgLegalInformation: false,
        manageMembers: true,
        residual_debt: false,
        //Pricing stuff
        negativeCreditAlertDisplay: false,
        diskPriceDisplay: true,
        gitlabReposTabDisplay: false,
        signupGift: true,
        brandFinancialAccounts: false,
        brandFinancialInformation: false,
        brandFinancialInvoiceSettings: false,
        increaseCredit: true,
        increaseCreditLimit: false,
        financialSelfReport: false,
        giftCreditAmount: "10",
        notEnoughCreditMessage: false,
        creditAmountOptions: false,
        backupPricing: false,
        surgeAndReserveResourceHint: false,
        tax: false,
        //Tabs
        resourceUsageTabDisplay: true,
        securityTabDisplay: true,
        lokiLogsTabDisplay: false,
        historyTabDisplay: false,
        aclTabDisplay: true,
        //Little things
        deployTokenDisplay: true,
        referToBackinghamDisplay: false,
        adjustRepoSettingsButtonDisplay: true,
        exportManifestsDisplay: false,
        canDownloadTransaction: false,
        canDownloadInvoices: false,
        //paas
        dockerRegistery: true,
        createAppCustomConfig: false,
        plugin_docs: false,
        gitlabci_content: false,
        suggestions: true,
        loadingTips: false,
        securitySummaryAppHeader: true,
        //loadBalancer
        deleteLoadBalancer: false,
        appList: [
            APP_TYPE_GIT,
            APP_TYPE_DOCKER,
            APP_TYPE_DOCKER_COMPOSE,
            APP_TYPE_MY_SQL,
            APP_TYPE_MONGODB,
            APP_TYPE_RABBIT_MQ,
            APP_TYPE_ELASTIC_SEARCH,
            APP_TYPE_KIBANA,
            APP_TYPE_PROMETHEUS,
            APP_TYPE_MARIA_DB,
            APP_TYPE_GRAFANA,
            APP_TYPE_MS_SQL,
            APP_TYPE_WORDPRESS,
            APP_TYPE_ROCKETCHAT,
            APP_TYPE_NEXT_CLOUD,
            APP_TYPE_METABASE,
            APP_TYPE_POSTGRES,
            APP_TYPE_POSTGRES_AIO,
            APP_TYPE_MINIO,
            APP_TYPE_REDIS,
            APP_TYPE_BUILDPACK_CLOJURE,
            APP_TYPE_BUILDPACK_DJANGO,
            APP_TYPE_BUILDPACK_GO,
            APP_TYPE_BUILDPACK_JAVA,
            APP_TYPE_BUILDPACK_NODEJS,
            APP_TYPE_BUILDPACK_PHP,
            APP_TYPE_BUILDPACK_PYTHON,
            APP_TYPE_BUILDPACK_REACT,
            APP_TYPE_BUILDPACK_RUBY,
            APP_TYPE_BUILDPACK_SCALA,
            APP_TYPE_BUILDPACK_DOTNET_CORE,
            APP_TYPE_NGINX,
            APP_TYPE_GITLAB_RUNNER,
            APP_TYPE_JUPYTER,
            APP_TYPE_MINIO_NEW
        ],
        orIngestPoint: process.env.NEXT_PUBLIC_APP_ENV === "staging" ? "https://openreplay-nginx.hectora.app/ingest" : "https://openreplay-nginx.hectora.app/ingest",
        orProjectKey: process.env.NEXT_PUBLIC_APP_ENV === "staging" ? "k7StotUrQAiSm5cbTDuF" : "A4t4uO8v1o649FibwYla"
    },
    dateFormat: "MMM DD YYYY",
    countryCode: "AM",
    assets: {
        paas_icon: paasIcon,
        brand_logo: brandLogo,
        pass_dark_icon: paasDark,
        updamus_bg: bannerUpdamus,
        paas_bg: paasBG,
        local_git_bg: localGitBG,
        added_tax_certificate: addedTaxCertificate,
        outlined_logo: outlinedLogo,
        colored_outlined_logo: coloredOutlinedLogo,
        ic_backup: icBackup,
        ic_minio: icMinio,
        ic_postgres: icPostgres,
        ic_mongo: icMongo,
        ic_redis: icRedis,
        ic_mysql: icMysql,
        ic_monitoring: icMonitoring,
        ic_monitoring_colored: icMonitoringColored
    },
    outer_links: {
        dc: "https://dc.hectora.cloud",
        updamus: "https://my.updamus.com",
        local_git: "https://gitlab.com",
        about_us: "https://hectora.cloud/about-us",
        tos: "https://hectora.cloud/tos"
    },
    docs: {
        root: "https://docs.hectora.cloud",
        kybectl: "https://docs.hectora.cloud/darkube/kubectl",
        django_app: "https://docs.hectora.cloud/darkube/popular-apps/django-app/",
        react_app: "https://docs.hectora.cloud/darkube/popular-apps/react-app/",
        kong_api_gateway: "https://docs.hectora.cloud/darkube/popular-apps/kong-api-gateway/",
        mongo_db: "https://docs.hectora.cloud/darkube/popular-apps/mongodb/",
        mysql: "https://docs.hectora.cloud/darkube/popular-apps/mySQL/",
        mssql: "https://docs.hectora.cloud/darkube/popular-apps/mssql/",
        faq: "https://docs.hectora.cloud/faq/",
        ticketing: "https://docs.hectora.cloud/ticketing/",
        troubleshooting: "https://docs.hectora.cloud/darkube/troubleshooting/",
        static_app: "https://docs.hectora.cloud/darkube/apps/static-app/",
        git_repo_app: "https://docs.hectora.cloud/darkube/apps/git-repo/",
        file_upload: "https://docs.hectora.cloud/darkube/apps/file-upload/",
        docker_image_app: "https://docs.hectora.cloud/darkube/apps/docker-image/",
        minio_app: "https://docs.hectora.cloud/darkube/apps/minio/",
        redis_app: "https://docs.hectora.cloud/darkube/apps/redis/",
        postgres_app: "https://docs.hectora.cloud/darkube/apps/postgreSQL/",
        health_check_path: "https://docs.hectora.cloud/darkube/apps/git-repo/#health-check-path",
        git_health_check_path_field: "https://docs.hectora.cloud/darkube/apps/git-repo/#health-check-path",
        git_dockerfile_field: "https://docs.hectora.cloud/darkube/apps/git-repo/#%D8%A2%D8%AF%D8%B1%D8%B3-%D8%AF%D8%A7%DA%A9%D8%B1-%D9%81%D8%A7%DB%8C%D9%84",
        git_resources_field: "https://docs.hectora.cloud/darkube/apps/git-repo/#%D9%85%D9%86%D8%A7%D8%A8%D8%B9",
        git_port_field: "https://docs.hectora.cloud/darkube/apps/git-repo/#%D9%BE%D9%88%D8%B1%D8%AA-%D8%B3%D8%B1%D9%88%DB%8C%D8%B3",
        git_replica_field: "https://docs.hectora.cloud/darkube/apps/git-repo/#replicas",
        git_namespace_field: "https://docs.hectora.cloud/darkube/apps/git-repo/#%D9%81%D8%B6%D8%A7%DB%8C-%D9%86%D8%A7%D9%85%DB%8C-namespace",
        git_zone_field: "https://docs.hectora.cloud/darkube/apps/git-repo/#%D9%85%D9%86%D8%B7%D9%82%D9%87-zone",
        docker_image_resources_field: "https://docs.hectora.cloud/darkube/apps/docker-image/#%D9%85%D9%86%D8%A7%D8%A8%D8%B9",
        docker_image_port_field: "https://docs.hectora.cloud/darkube/apps/docker-image/#%D9%BE%D9%88%D8%B1%D8%AA-%D8%B3%D8%B1%D9%88%DB%8C%D8%B3",
        docker_image_replica_field: "https://docs.hectora.cloud/darkube/apps/docker-image/#replicas",
        docker_image_namespace_field: "https://docs.hectora.cloud/darkube/apps/docker-image/#%D9%81%D8%B6%D8%A7%DB%8C-%D9%86%D8%A7%D9%85%DB%8C-namespace",
        docker_image_health_check_path_field: "https://docs.hectora.cloud/darkube/apps/docker-image/#health-check-path",
        backingham_intro: "https://docs.hectora.cloud/backup/intro/",
        docker_image_custom_domains: "https://docs.hectora.cloud/hcp/create/docker-image/settings/domain-address/#connecting-to-a-custom-domain",
        deploy_with_curl_github: "https://docs.hectora.cloud/hcp/ci-cd/github-actions/#deploy-using-curl",
        github_actions: "https://docs.hectora.cloud/hcp/ci-cd/github-actions",
        port_forward: "https://docs.hectora.cloud/hcp/general-features/kubectl/#port-forward",
        disk_and_backup: "https://docs.hectora.cloud/hcp/manage/disk/"
    },
    api_url: process.env.NEXT_PUBLIC_API_URL,
    ws_url: process.env.NEXT_PUBLIC_WS_URL,
    api_staging_openid: "https://api-staging.console.hectora.cloud/openid",
    client_uri: process.env.NEXT_PUBLIC_PANEL_URL,
    production_sentry: "https://63f63e2a4ec845c28af2f7f185a9aaac@sentry.hectora.cloud/82",
    staging_sentry: "https://b9a62c659f954243b5f155bfe527773e@sentry.hectora.cloud/95"
};
export default assets;
