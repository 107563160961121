import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const getPermissions = ()=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/permissions/`, config()));
};
export const getUserPermissions = (param)=>{
    let { userId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/permissions/user/${userId}/`, config()));
};
export const updateAppPermissions = (param)=>{
    let { activeId, json } = param;
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/${paasUrl}/permissions/user/${activeId}/`, json, config()));
};
export const getUserRoles = (param)=>{
    let { organizationId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/organizations/${organizationId}/members`, config()));
};
export const updateUserRoles = (param)=>{
    let { organizationId, json } = param;
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/organizations/${organizationId}/members`, json, config()));
};
