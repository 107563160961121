import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios, paasUrl } from "../axios";
export const getBuildScripts = (appId)=>{
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/stateless_apps/${appId}/generate_cicd_scripts`, config()));
};
export const getAppBuilds = (param)=>{
    let { appId, limit = 20, offset = 0 } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/build/app/${appId}/?limit=${limit}&offset=${offset}`, config()));
};
export const getBuildLogs = (param)=>{
    let { buildId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/build/${buildId}/`, config()), {
        setNotif: false
    });
};
export const retryBuild = (param)=>{
    let { buildId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/build/${buildId}/retry/`, config()));
};
export const stopBuild = (param)=>{
    let { buildId } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/${paasUrl}/build/${buildId}/stop/`, {}, config()));
};
export const retryLastBuild = (param)=>{
    let { appId } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/${paasUrl}/build/build_last_commit/?app_id=${appId}&deploy=true`, config()));
};
