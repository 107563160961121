import { storageServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const auth = ()=>{
    return sendRequest(()=>axios.get(`${storageServer}/v1/user/`, config()));
};
export const getStorages = ()=>{
    return sendRequest(()=>axios.get(`${storageServer}/v1/storage/`, config()));
};
export const getStorage = (storageName)=>{
    return sendRequest(()=>axios.get(`${storageServer}/v1/storage/${storageName}`, config()));
};
export const createStorage = (data)=>{
    const body = {
        name: data.name,
        size: data.size,
        disk: data.disk,
        domains: data.domains
    };
    return sendRequest(()=>axios.post(`${storageServer}/v1/storage/`, body, config()));
};
export const deleteStorage = (storageName)=>{
    return sendRequest(()=>axios.delete(`${storageServer}/v1/storage/?name=${storageName}`, config()));
};
export const getBuckets = (storage)=>{
    return sendRequest(()=>axios.get(`${storageServer}/v1/bucket/?storage=${storage}`, config()));
};
export const createBucket = (data)=>{
    const body = {
        name: data.name,
        storage: data.storage,
        public: data.public
    };
    console.log(body);
    return sendRequest(()=>axios.post(`${storageServer}/v1/bucket/`, body, config()));
};
export const changeBucketAccess = (data)=>{
    const body = {
        name: data.name,
        storage: data.storage,
        public: data.public
    };
    console.log(body);
    return sendRequest(()=>axios.post(`${storageServer}/v1/bucket/public`, body, config()));
};
export const deleteBucket = (bucketName, storage)=>{
    return sendRequest(()=>axios.delete(`${storageServer}/v1/bucket/?storage=${storage}&bucket=${bucketName}`, config()));
};
export const getKeys = (storageName)=>{
    return sendRequest(()=>axios.get(`${storageServer}/v1/key/?storage=${storageName}`, config()));
};
export const createKey = (data)=>{
    const body = {
        name: data.name,
        storage: data.storage,
        actions: data.actions
    };
    return sendRequest(()=>axios.post(`${storageServer}/v1/key/`, body, config()));
};
export const editKey = (data)=>{
    const body = {
        name: data.name,
        storage: data.storage,
        actions: data.actions
    };
    return sendRequest(()=>axios.put(`${storageServer}/v1/key/`, body, config()));
};
export const deleteKey = (keyName, storageName)=>{
    return sendRequest(()=>axios.delete(`${storageServer}/v1/key/?name=${keyName}&storage=${storageName}`, config()));
};
export const createBrowser = (storageName)=>{
    const body = {
        name: storageName
    };
    return sendRequest(()=>axios.post(`${storageServer}/v1/storage/create-browser`, body, config()));
};
export const deleteBrowser = (storageName)=>{
    return sendRequest(()=>axios.delete(`${storageServer}/v1/storage/delete-browser?storage_name=${storageName}`, config()));
};
