import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getLoadBalancers = ()=>{
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${hostServer}/api/v1/darkube/loadbalancers/`
        }
    });
};
export const getLoadBalancer = (param)=>{
    let { id } = param;
    return makeAxios({
        libConfig: {
            method: "GET",
            url: `${hostServer}/api/v1/darkube/loadbalancers/${id}`
        }
    });
};
export const createLoadBalancer = (param)=>{
    let { data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/darkube/loadbalancers/`, data, config()));
};
export const updateLoadBalancer = (param)=>{
    let { id, data } = param;
    return sendRequest(()=>axios.put(`${hostServer}/api/v1/darkube/loadbalancers/${id}/`, data, config()));
};
export const deleteLoadBalancer = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/darkube/loadbalancers/${id}/`, config()));
};
export const updateLoadBalancerApps = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/darkube/loadbalancers/${id}/redeploy_apps/`, {}, config()));
};
