import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const sendDeleteAppFeedback = (param)=>{
    let { text, metadata } = param;
    const json = {
        kind: "darkube_delete_app",
        text,
        metadata
    };
    return sendRequest(()=>axios.post(`${hostServer}/helpdesk/feedbacks/`, json, config()));
};
export const sendSimpleFeedback = (param)=>{
    let { text, metadata = {}, kind, ...rest } = param;
    return sendRequest(()=>axios.post(`${hostServer}/helpdesk/feedbacks/`, {
            text,
            metadata,
            kind,
            ...rest
        }, config()));
};
export const getFeedback = (ticketID)=>{
    return sendRequest(()=>axios.get(`${hostServer}/helpdesk/feedbacks/?ticket_id=${ticketID}`, config()));
};
