import { getToken } from "#helpers/getTokenCredentials";
import { isValueHollow } from "#helpers/validator";
import cookie from "js-cookie";
export const selectLanguage = (profile)=>{
    var _getToken;
    //?Compare selected lang of cookie and user settings data. cookie should equal to api.
    const isImpersonate = ((_getToken = getToken(true, null, false)) === null || _getToken === void 0 ? void 0 : _getToken.type) === "impersonate";
    if (location && !isImpersonate) {
        var _profile_profile_settings;
        const cookieLang = cookie.get("lang");
        const profileLang = profile === null || profile === void 0 ? void 0 : (_profile_profile_settings = profile.profile_settings) === null || _profile_profile_settings === void 0 ? void 0 : _profile_profile_settings.language;
        console.log(cookieLang, profileLang);
        if (!isValueHollow(profileLang) && cookieLang !== profileLang) {
            cookie.set("lang", profileLang);
            location.reload();
        }
    }
};
