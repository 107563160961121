import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getWorkflows = function() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : null;
    const url = new URL(`${hostServer}/api/v1/workflows/`);
    if (options === null || options === void 0 ? void 0 : options.types) {
        url.searchParams.set("types", options === null || options === void 0 ? void 0 : options.types);
    }
    url.searchParams.set("offset", (options === null || options === void 0 ? void 0 : options.offset) ?? 0);
    url.searchParams.set("limit", (options === null || options === void 0 ? void 0 : options.limit) ?? 20);
    return sendRequest(()=>axios.get(url === null || url === void 0 ? void 0 : url.href, config()));
};
export const getWorkflow = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/workflows/${id}/`, config()));
};
export const terminateWorkflow = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.delete(`${hostServer}/api/v1/workflows/${id}/terminate/`, config()));
};
export const resetWorkflow = (param)=>{
    let { id } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/workflows/${id}/reset/`, {}, config()));
};
export const submitSignalWorkflow = (param)=>{
    let { id, data = {
        message_id: "",
        message_value: ""
    } } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/workflows/${id}/submit_signal_message/`, data, config()));
};
