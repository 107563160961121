import { makeAxios } from "#http_requests/reqProxy/libFactory";
import { hostServer } from "#root/i18n/BrandMethods";
import { config, sendRequest, axoisReq as axios } from "../axios";
export const getPVCs = (param)=>{
    let { namespaceName, clusterID } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/darkube/pvcs/?namespace_name=${namespaceName}&cluster_id=${clusterID}`, config()));
};
export const migratePVC = (param)=>{
    let { namespaceName, pvcName, dstSize, data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/darkube/pvcs/migrate/?pvc_name=${pvcName}&dst_size=${dstSize}&namespace_name=${namespaceName}`, data, config()));
};
export const syncPVC = (param)=>{
    let { data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/darkube/pvcs/sync/`, data, config()));
};
export const restorePVC = (param)=>{
    let { data } = param;
    return sendRequest(()=>axios.post(`${hostServer}/api/v1/darkube/pvcs/restore_pvc_backup/`, data, config()));
};
export const getNSes = (param)=>{
    let { clusterID } = param;
    return sendRequest(()=>axios.get(`${hostServer}/api/v1/darkube/nses/?cluster_id=${clusterID} `, config()));
};
export const defragPVC = (data)=>makeAxios({
        libConfig: {
            method: "POST",
            url: `${hostServer}/api/v1/darkube/pvcs/defrag/`,
            data: data
        }
    });
