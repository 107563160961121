import cookie from "js-cookie";
const cookieName = "seenAnnouncement";
export const addAnnouncementCookie = (announcementID)=>{
    const d1 = new Date();
    const d2 = new Date(d1);
    d2.setHours(d1.getHours() + 24);
    if (!cookie.get(cookieName)) {
        cookie.set(cookieName, JSON.stringify([
            announcementID
        ]), {
            expires: d2
        });
        return [
            announcementID
        ];
    }
    const announcementsCookie = cookie.get(cookieName);
    let announcements = [];
    try {
        var _JSON_parse;
        if (announcementsCookie && typeof announcementsCookie === "string" && ((_JSON_parse = JSON.parse(announcementsCookie)) === null || _JSON_parse === void 0 ? void 0 : _JSON_parse.length)) {
            announcements = JSON.parse(announcementsCookie) ?? [];
            announcements.push(announcementID);
            cookie.set(cookieName, JSON.stringify(announcements), {
                expires: d2
            });
        }
    } catch (error) {
        console.error(error);
    }
    return announcements;
};
export const getAnnouncementsCookie = ()=>{
    const announcementCookie = cookie.get(cookieName);
    let announcements = [];
    try {
        if (announcementCookie && typeof JSON.parse(announcementCookie) === "object") {
            announcements = JSON.parse(announcementCookie);
        }
    } catch (error) {
        console.error(error);
    }
    return announcements;
};
